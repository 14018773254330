import networkManager from '../NetworkManager/index'
import ApiNames from '../ApiNames/NewExam'

class Exam {
  constructor (context) {
    this.context = context
  }

  async createExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async createExamFromCourseFile (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createExamFromCourseFile, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async createOrUpdateExamAndSubjectExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.postRequest(ApiNames.createOrUpdateExamAndSubjectExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async getExams (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getExams, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }

  async getAllTypeExamsBySubjectIds (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getAllTypeExamsBySubjectIds, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.exams
  }
  
  async getExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }
  async getExamsForCourseFile (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.getRequest(ApiNames.getExamsForCourseFile, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response
  }

  async deleteExam (data) {
    this.context.$store.commit('liveData/set_loading', true)
    const response = await networkManager.deleteRequest(ApiNames.deleteExam, data)
    this.context.$store.commit('liveData/set_loading', false)
    return response.status
  }
}

export default Exam
