<template src="./activityExamSettings.html"></template>
<script>
import ExamSettingsRepository from "../../Repository/ExamSettings";
import InstituteRepository from "../../Repository/Institute";
import SubjectRepository from "../../Repository/Subject";
import NbaRepository from "../../Repository/NBA";
import inputContainer from "../../Components/inputContainer";
import uploadToDigitalOcean from "../../Repository/uploadToDigitalOcean";
import html2pdf from "html2pdf.js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import StudentMarksheet from "../../Components/exams/StudentMarksheet/StudentMarksheet.vue"
import { certificateTypes } from "../../Constants/Utils/Statics"

import showStatus from "../../NetworkManager/showStatus";

export default {
  name: "activityExamSettings",
  components: {
    inputContainer,
    StudentMarksheet
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "|",
            "undo",
            "redo",
          ],
          shouldNotGroupWhenFull: true,
        },
      },
      e1: 1,
      e2: 1,
      finalSelectedField: [],
      useManuallyTable: false,
      previewHallTicket: false,
      loadingForHall: false,
      userData: {},
      allHallDetails: [],
      dummySignPhoto: [],
      dummyStampPhoto: [],
      showInstituteImage: false,
      instituteLogoUrl: "",
      instituteName: "",
      instituteAddress: "",
      instituteLogo: "",
      isStampNeeded: "",
      collageStampPhoto: [],
      instituteAdd: "",
      dummyArrayForColumn: [],
      signNature: "",
      selectedFieldForStudentTable: [],
      signaturePhoto: [],
      signatureNameArr: ["Principal", "Director", "HOD", "COE", "Student Sign", "Exam Co-ordinator", "Associate Director", "None"],
      signatureNamesForCertificates: ["Principal", "Director", "HOD", "Student Sign", "Controller Of Examination", "Office Superintendent", "Mentor", "Date", "Account Department", "Exam Co-ordinator", "Associate Director"],
      subjectDetailFields: ["Subject Name", "Subject Code", "CIE (Internal)", "SEE (External)"],
      marksheetTableFields: ['Course Code', 'Course Name', 'Course Type', 'Credits', 'Internal', 'External', 'Grade Point', 'Grade', 'Grade Point Average', 'Total'],
      selectedMarksheetTableFields: [],
      selectedSubjectDetailFields: [],
      instructionForStudent: "",
      selectedSignature: [],
      studentTableField: [
        "Department",
        "Course Year",
        "Subject Code",
        "Subject Name",
        "Exam Day",
        "Exam Date",
        "Exam Time",
        "Semester",
        "Type of Exam",
      ],
      numberOfColumnNeeded: null,
      ourTableData: [
        "Semester",
        "Subject Code",
        "Subject Name",
        "Exam Day",
        "Exam Date",
        "Exam Time",
        "Type of Exam",
      ],
      isOurTableUsed: true,
      allFieldToAdd: ["Student photo",
         "Full Name",
         "PRN number",
         "Exam seat number",
        "Roll Number", 
        "PUN code", 
        "College code", 
        "Center code",
        "ABC ID",
        "Gr. No.",
        "Semester"
      ],
      selectedField: [],
      percentageAndCriteriaSystemPreference: [
        {
          text: "Overall on Institute Level",
          value: "instituteLevel",
        },
        {
          text: "Batch Level",
          value: "batchLevel",
        },
      ],
      cieSeePreference: [
        {
          text: "Yes",
          value: "yes",
        },
        {
          text: "No",
          value: "no",
        },
      ],
      systemPreference: [
        {
          text: "Percentage (%)",
          value: "percentage",
        },
        {
          text: "Credit Based System",
          value: "cbs",
        },
      ],
      passingPercentageTypes: [
        {
          text: "Overall",
          value: "overall",
        },
        {
          text: "Individual Course Wise",
          value: "individual",
        },
      ],
      gradingSystemOptions: [
        {
          text: "Absolute",
          value: "absolute",
        },
        {
          text: "Relative",
          value: "relative",
        },
      ],
      // promotionCriteriaPreference: [
      //     {
      //         text: 'Based on Course Year Passing Percentage',
      //         value: 'cyPassingPercentage'
      //     },
      //     {
      //         text: 'Based on Number of Backlogs',
      //         value: 'noOfBacklogs'
      //     },
      //     {
      //         text: 'Both',
      //         value: 'passingPercentageAndBacklogs'
      //     }
      // ],
      selectedPromotionCriteriaPreference: [],
      numberOfBacklogsAllowed: 0,
      selectedGradingSystem: "",
      selectedPercentageAndCriteriaSystemPreference: "",
      selectedPassingPercentageType: "",
      selectedSystemPreference: "",
      selectedCieSeePreference: "",
      overallPassingPercentage: 0,
      // minCreditPercentageForNextSem: 0,
      lowestPercentageOfPassing: 0,
      gradeDetails: [],
      selectedInstituteId: "",
      saveExamSettingsLoader: false,
      getExamSettingsLoader: false,
      inputRules: {
        compulsoryField: (value) => !!value || "This field cannot be empty.",
      },
      compulsoryField: false,
      gradeDetailsArray: [
        {
          gradeName: "O",
          gradeRange: {
            from: 100,
            to: 90,
          },
          gradePoints: 10,
          gradeRemark: "",
        },
        {
          gradeName: "A+",
          gradeRange: {
            from: 90,
            to: 80,
          },
          gradePoints: 9,
          gradeRemark: "",
        },
        {
          gradeName: "A",
          gradeRange: {
            from: 80,
            to: 70,
          },
          gradePoints: 8,
          gradeRemark: "",
        },
        {
          gradeName: "B+",
          gradeRange: {
            from: 70,
            to: 60,
          },
          gradePoints: 7,
          gradeRemark: "",
        },
        {
          gradeName: "B",
          gradeRange: {
            from: 60,
            to: 50,
          },
          gradePoints: 6,
          gradeRemark: "",
        },
        {
          gradeName: "C+",
          gradeRange: {
            from: 50,
            to: 40,
          },
          gradePoints: 5,
          gradeRemark: "",
        },
        {
          gradeName: "C",
          gradeRange: {
            from: 40,
            to: 30,
          },
          gradePoints: 4,
          gradeRemark: "",
        },
        {
          gradeName: "D+",
          gradeRange: {
            from: 30,
            to: 20,
          },
          gradePoints: 3,
          gradeRemark: "",
        },
        {
          gradeName: "D",
          gradeRange: {
            from: 20,
            to: 10,
          },
          gradePoints: 2,
          gradeRemark: "",
        },
        {
          gradeName: "E+",
          gradeRange: {
            from: 10,
            to: 0,
          },
          gradePoints: 1,
          gradeRemark: "",
        },
      ],
      passingPercentageTableData: [],
      passingPercentageTableHeader: [],
      selectedSemester: "",
      subjectDetails: [],
      subjectDetailsDialog: false,
      selectedSubjects: [],
      allSubjectsSelected: false,
      subjectToDelete: {},
      deleteSubjectDialog: false,
      subjectIndexToDelete: 0,
      displaySubjectDetailsTable: false,
      batchNameArray: [],
      courseYearNameArray: [],
      selectedBatch: "",
      selectedDepartment: "",
      selectedCourseYearObject: {},
      selectedCourseYear: "",
      selectedSemesterIds: [],
      batchData: [],
      batchWiseSubjects: [],
      subjectsToDisplay: [],
      totalExamPassingCredits: 0,
      ciePassingCredits: 0,
      seePassingCredits: 0,
      promotionCreditsRequiredForNextYear: 0,
      valueNotMatchingError: false,
      ciePassingPercentage: 0,
      seePassingPercentage: 0,
      maxNumberOfAttempts: 0,
      instituteCieSee: "",
      courseYearPassingPercentage: 0,
      minimumCiePercentage: 0,
      minimumSeePercentage: 0,
      examFormInstructions: "",
      examFormSignatureIndividual: [],
      examFormLoader: false,
      examFormFields: ["PUNCODE", "Program Name", "Exam Name"],
      formFieldsNameArray: ["PUNCODE", "Program Name", "Exam Name", "Date"],
      marksheetPersonalDetails: ["Student Name", "Roll No", "PRN", "Mother's Name"],
      // marksheetPersonalDetails: [],
      // selectedMarksheetPersonalDetails: [],
      marksheetAdditionalInfo: "",
      gradeDistributionInformation: "",
      marksheetSignatureIndividual: [],
      certificateTitle: "",
      showMarksheetTemplate: false,
      includeInstituteNameInTheMarksheet: false,
      marksheetPreviewDialog: false,
      certificateTypes: certificateTypes,
      selectedCertificateType: {},
      includeSgpaTable: false,
      includeCgpaTable: false,
      sessionTitle: "",
      basicDetailFields: ["Name", "Email-Id", "Contact Number", "Category", "Gender", "Roll Number", "PRN", "Semester", "Exam Seat Number"],
      selectedBasicDetailsFields: []
    };
  },
  created() {
    // this.$store.commit(
    //   "liveData/set_selectedActivityName",
    //   "Exam Details" // Previously 'Exam Settings'
    // );
    this.selectedInstituteId =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.instituteLogoUrl = this.$store.getters["liveData/get_brandingLogo"];
    this.instituteName = this.$store.getters["liveData/get_brandingName"];
    this.instituteAddress =
      this.$store.getters["liveData/get_instituteAddress"].instituteAddress || "";
    this.instituteCieSee = this.$store.getters["liveData/get_instituteCieSee"];
    this.userData = this.$store.getters["user/get_userData"];

    if (this.instituteCieSee === "Yes") {
      this.selectedCieSeePreference = "yes";
    } else if (this.instituteCieSee === "No") {
      this.selectedCieSeePreference = "no";
    }

    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.instituteRepositoryInstance = new InstituteRepository(this);
    this.uploadToDigitalOceanInstance = new uploadToDigitalOcean(this);

    this.getExamSettings();
    this.getInstituteInformation();
    this.getSubjectDetails();
    this.getBatches();
  },
  methods: {
    async saveExamForm() {
      // total: 776 passed: 733 failed@@@@: 43
      this.examFormLoader = true
      const objToPush = {
        formInstructions: this.examFormInstructions,
        signatureIndividual: this.examFormSignatureIndividual,
        formFields: this.examFormFields,
        subjectDetailFields: this.selectedSubjectDetailFields,
        basicDetailsFields: this.selectedBasicDetailsFields
      };
      try {
        await this.instituteRepositoryInstance.updateInstituteDetails({
          instituteId: this.selectedInstituteId,
          examFormFormat: objToPush,
        });
        showStatus("Exam Form Settings saved successfully.", 5000, "success", this);
        this.getInstituteInformation();
      } catch (error) {
        showStatus("Error saving Exam Form Settings. Please try again.", 5000, "error", this);
        console.error(error);
      }
      this.examFormLoader = false
    },
    downloadPDF() {
      html2pdf(this.$refs.hallTicketSample, {
        margin: [0, -1],
        filename: "document.pdf",
        enableLinks: true,
        image: { type: "jpg", quality: 0.98 },
        html2canvas: { useCORS: true, windowWidth: 1200, windowHeight: 600 },
        jsPDF: { unit: "in", orientation: "portrait", format: "a4" },
      });
    },
    removeLink(index, array) {
      array.splice(index, 1);
    },
    signatureChanged() {
      this.signaturePhoto = [];
      this.removeLink(0, this.dummySignPhoto);
    },
    async saveMarksheetTemplate() {
      let objToPush = {
        signatureIndividual: this.marksheetSignatureIndividual,
        personalDetails: this.marksheetPersonalDetails,
        tableFields: this.selectedMarksheetTableFields,
        additionalInfo: this.marksheetAdditionalInfo,
        certificateType: this.selectedCertificateType,
        includeInstituteNameInTheMarksheet: this.includeInstituteNameInTheMarksheet,
        includeSgpaTable: this.includeSgpaTable,
        sessionTitle: this.sessionTitle,
        includeCgpaTable: this.includeCgpaTable,
      }
      try {
        await this.instituteRepositoryInstance.updateInstituteDetails({
          instituteId: this.selectedInstituteId,
          marksheetTemplate: objToPush,
        });
        this.getInstituteInformation()
        showStatus("Exam Marksheet template updated successfully.", 2000, "success", this);
      } catch (error) {
        showStatus("Error updating exam marksheet template.", 2000, "error", this);
        console.error('error', error);
      }
      // console.log('objToPush'  objToPush);
    },
    async uploadFile(fileArr) {
      try {
        if (fileArr.length > 0) {
          let data = await this.uploadToDigitalOceanInstance.upload(
            fileArr,
            this.userData.uId
          );
          return data;
        }
      } catch (error) {
        console.error("error", error);
        let arr = [];
        return arr;
      }
    },
    async getInstituteInformation() {
      try {
        this.allHallDetails =
          await this.instituteRepositoryInstance.getInstituteInformation({
            instituteId: this.selectedInstituteId,
          });
        // console.log('this.allHallDetails', this.allHallDetails);
        this.finalSelectedField = this.selectedField.filter((data) => {
          if (
            data !== "Full Name" &&
            data !== "Mother’s Name" &&
            data !== "Student photo"
          ) {
            return data;
          }
        });
        if (this.allHallDetails.hallTicketFormat) {
        this.selectedField = this.allHallDetails.hallTicketFormat.studentDetails
          this.useManuallyTable = this.allHallDetails.hallTicketFormat.isManually;
          if (this.useManuallyTable === true) {
            this.selectedFieldForStudentTable =
              this.allHallDetails.hallTicketFormat.examDetails;
            this.isOurTableUsed = false;
          } else {
            this.isOurTableUsed = true;
          }

          this.instructionForStudent =
            this.allHallDetails.hallTicketFormat.instructions;

          this.selectedSignature =
            Array.isArray(this.allHallDetails.hallTicketFormat.signatureName) ? this.allHallDetails.hallTicketFormat.signatureName : [this.allHallDetails.hallTicketFormat.signatureName];
          this.dummySignPhoto =
            this.allHallDetails.hallTicketFormat.signaturePhoto;

          this.isStampNeeded =
            this.allHallDetails.hallTicketFormat.isCollageStampNeeded === true
              ? "true"
              : "false";
          if (this.isStampNeeded === "true") {
            this.dummyStampPhoto =
              this.allHallDetails.hallTicketFormat.collageStampPhoto;
          }
        }

        // Exam Form Details
        if (this.allHallDetails.examFormFormat) {
          this.examFormInstructions = this.allHallDetails.examFormFormat.formInstructions
          this.examFormSignatureIndividual = this.allHallDetails.examFormFormat.signatureIndividual
          this.examFormFields = this.allHallDetails.examFormFormat.formFields
          this.selectedSubjectDetailFields = this.allHallDetails.examFormFormat.subjectDetailFields
          this.selectedBasicDetailsFields = this.allHallDetails.examFormFormat.basicDetailsFields
        }
        if (this.allHallDetails.marksheetTemplate) {
          this.marksheetSignatureIndividual = this.allHallDetails.marksheetTemplate.signatureIndividual
          this.marksheetPersonalDetails = this.allHallDetails.marksheetTemplate.personalDetails
          this.selectedMarksheetTableFields = this.allHallDetails.marksheetTemplate.tableFields
          this.marksheetAdditionalInfo = this.allHallDetails.marksheetTemplate.additionalInfo
          this.selectedCertificateType = this.allHallDetails.marksheetTemplate.certificateType
          this.includeInstituteNameInTheMarksheet = this.allHallDetails.marksheetTemplate.includeInstituteNameInTheMarksheet
          this.includeSgpaTable = this.allHallDetails.marksheetTemplate.includeSgpaTable
          this.sessionTitle = this.allHallDetails.marksheetTemplate.sessionTitle
          this.includeCgpaTable = this.allHallDetails.marksheetTemplate.includeCgpaTable

          this.allHallDetails.marksheetTemplate.tableData = []
          this.allHallDetails.marksheetTemplate.studentPersonalDetails = []
          this.allHallDetails.marksheetTemplate.personalDetails.map((item) => {
            let objToPush = {}
            if (item === "Student Name") {
              objToPush = {
                text: "Student Name",
                value: "name"
              }
            }
            if (item === "Roll No") {
              objToPush = {
                text: "Roll No",
                value: "rollNo"
              }
            }
            if (item === "PRN") {
              objToPush = {
                text: "PRN",
                value: "studentPrn"
              }
            }
            if (item === "Mother's Name") {
              objToPush = {
                text: "Mother's Name",
                value: "mothersName"
              }
            }
            this.allHallDetails.marksheetTemplate.studentPersonalDetails.push(objToPush)
          })
          this.allHallDetails.marksheetTemplate.tableFields.map((item) => {
            let objToPush = {}
            if (item === "Course Code") {
              objToPush = {
                text: "Course Code",
                value: "subjectCode"
              }
            }
            if (item === "Course Name") {
              objToPush = {
                text: "Course Name",
                value: "subjectName"
              }
            }
            if (item === "Course Type") {
              objToPush = {
                text: "Course Type",
                value: "subjectType"
              }
            }
            if (item === "Credits") {
              objToPush = {
                text: "Credits",
                value: "obtainedCredits"
              }
            }
            if (item === "Grade Point") {
              objToPush = {
                text: "Grade Point",
                value: "obtainedGradePoints"
              }
            }
            if (item === "Grade") {
              objToPush = {
                text: "Grade",
                value: "obtainedGrade"
              }
            }
            if (item === "Internal") {
              objToPush = {
                text: "Internal",
                value: "totalCieObtainedMarks"
              }
            }
            if (item === "External") {
              objToPush = {
                text: "External",
                value: "totalSeeObtainedMarks"
              }
            }
            if (item === "Total") {
              objToPush = {
                text: "Total",
                value: "obtainedMarks"
              }
            }
            if (item === "Grade Point Average") {
              objToPush = {
                text: "Grade Point Average",
                value: "obtainedGradePoints"
              }
            }
            this.allHallDetails.marksheetTemplate.tableData.push(objToPush)
          })
        }
      } catch (error) {
        console.error(error);
      }
    },
    handleMarksheetPreview() {

      this.marksheetDetailsToPush = {
        instituteName: this.allHallDetails.instituteName,
        instituteAddress: this.allHallDetails.instituteAddress,
        instituteLogo: this.allHallDetails.instituteLogoLink,
        departmentName: "Department Name",
        courseYear: "Course Year Name",
        semesterName: "Semester Name",
        marksheetTemplate: this.allHallDetails.marksheetTemplate
      }
      this.showMarksheetTemplate = true
      this.marksheetPreviewDialog = true
    },
    async saveHallTicketData() {
      if (
        this.instructionForStudent === "" ||
        this.selectedSignature.length === 0 ||
        this.isStampNeeded === ""
      ) {
        if (this.instructionForStudent === "") {
          alert("Please fill Instruction for student");
          return;
        }
        if (this.selectedSignature.length === 0) {
          alert("Please fill signature name");
          return;
        }
        if (this.selectedSignature.length > 0) {
          if (
            this.signaturePhoto.length === 0 &&
            this.dummySignPhoto.length === 0
          ) {
            alert("Please add signature photo");
            return;
          }
        }
        if (this.isStampNeeded === "") {
          alert("Stamp needed is empty");
          return;
        }
      } else {
        if (
          this.isStampNeeded === "true" &&
          this.collageStampPhoto.length === 0 &&
          this.dummyStampPhoto.length === 0
        ) {
          alert("Collage Stamp Photo not Present");
        } else {
          this.getExamSettingsLoader = true;
          let signaturePhoto;
          let collageStampPhoto;
          if (this.signaturePhoto.length > 0) {
            signaturePhoto = await this.uploadFile(this.signaturePhoto);
          } else {
            signaturePhoto = this.dummySignPhoto;
          }
          if (this.collageStampPhoto.length > 0) {
            collageStampPhoto = await this.uploadFile(this.collageStampPhoto);
          } else {
            collageStampPhoto = this.dummyStampPhoto;
          }

          let objToPush = {
            studentDetails: this.selectedField,
            isManually: this.useManuallyTable,
            examDetails:
              this.isOurTableUsed === true
                ? this.ourTableData
                : this.selectedFieldForStudentTable,
            instructions: this.instructionForStudent,
            signatureName: this.selectedSignature,
            signaturePhoto: signaturePhoto,
            isCollageStampNeeded: this.isStampNeeded === "true" ? true : false,
            collageStampPhoto: collageStampPhoto,
          };
          // console.log('objToPush', objToPush);
          try {
              await this.instituteRepositoryInstance.updateInstituteDetails({
                instituteId: this.selectedInstituteId,
                hallTicketFormat: objToPush,
              });
            await this.getInstituteInformation();
          } catch (error) {
            console.error(error);
          }
          this.getExamSettingsLoader = false;
        }
      }
    },

    signatureChange() {
      this.signNature = "";
      if(this.selectedSignature.length === 1) {
        this.signNature = `Add ${this.selectedSignature[0]} signature photo`;
      }
    },

    createManuallyTable() {
      this.isOurTableUsed = false;
      this.useManuallyTable = true;
    },
    useOurTable() {
      this.useManuallyTable = false;
      this.isOurTableUsed = true;
      this.selectedFieldForStudentTable = [];
    },
    fieldChange() { },
    async getSubjectsOfCourseYear() {
      this.selectedCourseYear = this.selectedCourseYearObject.courseYear;
      const response =
        await this.subjectRepositoryInstance.getSubjectsOfSemesters({
          instituteId: this.selectedInstituteId,
          semIds: this.selectedCourseYearObject.semesters,
          courseYear: this.selectedCourseYearObject.courseYear,
          department: this.selectedDepartment,
        });
      response.subjects.forEach((subject) => {
        this.batchWiseSubjects.push({
          subjectId: subject.subjectId,
          subjectName: subject.subjectName,
        });
      });
    },
    handleTotalCredits() {
      this.promotionCreditsRequiredForNextYear = Number(
        this.totalExamPassingCredits
      );
    },
    handleCourseYearPassingPercentage() {
      this.courseYearPassingPercentage = Number(this.overallPassingPercentage);
      // this.overallPassingPercentage = Number(this.courseYearPassingPercentage)
    },
    // handlePromotionCriteriaPreference() {
    //     if() {

    //     }
    // },
    handleSubjects() {
      this.subjectsToDisplay = [];
      if (
        this.selectedPercentageAndCriteriaSystemPreference === "instituteLevel"
      ) {
        this.subjectsToDisplay = [...this.subjectDetails];
      } else {
        this.subjectsToDisplay = [...this.batchWiseSubjects];
      }
      this.subjectDetailsDialog = true;
    },
    handleCieSeeCreditInput() {
      const sum =
        Number(this.ciePassingCredits) + Number(this.seePassingCredits);
      if (sum > this.totalExamPassingCredits) {
        this.valueNotMatchingError = true;
      } else {
        this.valueNotMatchingError = false;
      }
    },
    handleBatchData() {
      const data = this.batchData.filter(
        (item) => this.selectedBatch === item.batchName
      )[0];
      this.selectedDepartment = data.department;
      this.courseYearNameArray = data.batch;
    },
    async getBatches() {
      this.batchData = await this.nbaRepositoryInstance.getBatchesOfInstitute({
        lmsInstituteId: this.selectedInstituteId,
      });
      this.batchData.forEach((item) => {
        this.batchNameArray.push(item.batchName);
      });
    },
    handleAllSubjects() {
      if (this.allSubjectsSelected) {
        this.selectedSubjects = [...this.subjectDetails];
      } else {
        this.selectedSubjects = [];
      }
    },
    async generateSubjectDetails() {
      if (this.selectedCieSeePreference === "yes") {
        this.selectedSubjects.forEach((subject) => {
          subject["ciePercentage"] = 0;
          subject["seePercentage"] = 0;
          subject["action"] = "action";
        });
      } else {
        this.selectedSubjects.forEach((subject) => {
          subject["overallPercentage"] = 0;
          subject["action"] = "action";
        });
      }
      this.displaySubjectDetailsTable = true;
      this.subjectDetailsDialog = false;
    },
    async getSubjectDetails() {
      this.subjectDetails = [];
      try {
        const response =
          await this.subjectRepositoryInstance.getSubjectsOfAInstituteByInstituteId(
            {
              instituteId: this.selectedInstituteId,
            }
          );
        response.subjects.forEach((subject) => {
          this.subjectDetails.push({
            subjectId: subject.subjectId,
            subjectName: subject.subjectName,
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    generatePassingPercentageTableHeader(value) {
      value === "yes"
        ? (this.passingPercentageTableHeader = [
          {
            text: "Course/Subject",
            value: "subjectName",
          },
          {
            text: "CIE Passing Percentage",
            value: "ciePercentage",
          },
          {
            text: "SEE Passing Percentage",
            value: "seePercentage",
          },
          {
            text: "Action",
            value: "action",
          },
        ])
        : (this.passingPercentageTableHeader = [
          {
            text: "Course/Subject",
            value: "subjectName",
          },
          {
            text: "Overall Percentage",
            value: "overallPercentage",
          },
          {
            text: "Action",
            value: "action",
          },
        ]);
    },
    confirmDeleteSubject(item, index) {
      this.subjectIndexToDelete = index;
      this.subjectToDelete = item;
      this.deleteSubjectDialog = true;
    },
    deleteSubject() {
      this.selectedSubjects.splice(this.subjectIndexToDelete, 1);
      this.deleteSubjectDialog = false;
    },
    gotoInstituteManagement() {
      this.$router.push("/activityInstitutesManagment");
    },
    addGrade() {
      if (this.selectedGradingSystem === "absolute") {
        this.gradeDetails.push({
          gradeName: "",
          gradePoints: 0,
          gradeRange: {
            from: 0,
            to: 0,
          },
          gradeRemark: "",
        });
      } else {
        this.gradeDetails.push({
          gradeName: "",
          gradePoints: 0,
          gradeRemark: "",
        });
      }
    },
    handleGradingSystem() {
      if (this.lowestPercentageOfPassing) {
        this.calculateGradeRange();
      }
    },
    calculateGradeRange() {

      const num = Math.floor(Number(this.lowestPercentageOfPassing) / 10);

      this.gradeDetails = this.gradeDetailsArray.slice(0, 10 - num);
      this.gradeDetails[this.gradeDetails.length - 1].gradeRange.to =
        this.lowestPercentageOfPassing;
    },

    deleteGrade(i) {
      this.gradeDetails.splice(i, 1);
    },
    async saveExamSettings() {
      // if (!this.selectedGradingSystem || !this.selectedPassingPercentageType || !this.selectedSystemPreference || this.minCreditPercentageForNextSem === 0 || this.lowestPercentageOfPassing === 0 || this.gradeDetails.length === 0) {
      //     this.compulsoryField = true
      // this.inputFileTypeError = true
      // } else {
      // this.e1 = 2
      // this.compulsoryField = false
      // }
      // if (this.selectedCieSeePreference === 'yes') {
      //     this.selectedSubjects.forEach((stud) => {
      //         delete stud.action
      //         delete stud.overallPercentage
      //     })
      // } else {
      //     this.selectedSubjects.forEach((stud) => {
      //         delete stud.action
      //         delete stud.seePercentage
      //         delete stud.ciePercentage
      //     })
      // }

      this.saveExamSettingsLoader = true;
      let objToPush = {
        instituteId: this.selectedInstituteId,
        systemPreference: this.selectedSystemPreference,
        selectedPercentageAndCriteriaSystemPreference:
          this.selectedPercentageAndCriteriaSystemPreference,
        selectedCieSeePreference: this.selectedCieSeePreference,
        maxNumberOfAttempts: this.maxNumberOfAttempts,
      };
      if (this.selectedSystemPreference === "percentage") {
        objToPush["passingPercentageType"] = this.selectedPassingPercentageType;
        objToPush["selectedPromotionCriteriaPreference"] =
          this.selectedPromotionCriteriaPreference;
      } else if (this.selectedSystemPreference === "cbs") {
        objToPush["promotionCreditsRequiredForNextYear"] = Number(
          this.promotionCreditsRequiredForNextYear
        );
        objToPush["gradingSystem"] = this.selectedGradingSystem;
        objToPush["lowestPercentageOfPassing"] = this.lowestPercentageOfPassing;
        objToPush["totalExamPassingCredits"] = Number(
          this.totalExamPassingCredits
        );
        objToPush["gradeDetails"] = this.gradeDetails;
        if (this.selectedGradingSystem === "absolute") {
          objToPush["gradeDetails"] = this.gradeDetails;
        } else {
          for (let index = 0; index < this.gradeDetails.length; index++) {
            const element = this.gradeDetails[index];
            delete element.gradeRange;
          }
          objToPush["gradeDetails"] = this.gradeDetails;
        }
      }
      if (this.selectedPromotionCriteriaPreference.includes("noOfBacklogs")) {
        objToPush["numberOfBacklogsAllowed"] = Number(
          this.numberOfBacklogsAllowed
        );
      }
      if (this.selectedPercentageAndCriteriaSystemPreference === "batchLevel") {
        objToPush["departmentName"] = this.selectedDepartment;
        objToPush["batchName"] = this.selectedBatch;
        objToPush["courseYearName"] = this.selectedCourseYear;
      }
      if (
        this.selectedCieSeePreference === "yes" &&
        this.selectedSystemPreference === "cbs"
      ) {
        objToPush["ciePassingCredits"] = Number(this.ciePassingCredits);
        objToPush["seePassingCredits"] = Number(this.seePassingCredits);
        objToPush["minimumCiePercentage"] = Number(this.minimumCiePercentage);
        objToPush["minimumSeePercentage"] = Number(this.minimumSeePercentage);
      }
      if (this.selectedPassingPercentageType === "overall") {
        objToPush["overallPercentage"] = Number(this.overallPassingPercentage);
      }
      if (
        this.selectedPassingPercentageType === "overall" &&
        this.selectedCieSeePreference === "yes"
      ) {
        objToPush["ciePassingPercentage"] = Number(this.ciePassingPercentage);
        objToPush["seePassingPercentage"] = Number(this.seePassingPercentage);
      }
      if (
        this.selectedPassingPercentageType === "individual" &&
        this.selectedSystemPreference === "percentage"
      ) {
        objToPush["subjectGradePercentages"] = this.selectedSubjects;
      }
      try {
        await this.examSettingsRepositoryInstance.createExamSettings(objToPush);
        await this.getExamSettings();
        showStatus("Exam Settings saved successfully.", 2000, "success", this);
        this.saveExamSettingsLoader = false;
      } catch (error) {
        console.error(error);
        this.saveExamSettingsLoader = false;
        showStatus("Error saving Exam Settings.", 9000, "error", this);
      }
    },
    async getExamSettings() {
      this.getExamSettingsLoader = true;
      try {
        let data =
          await this.examSettingsRepositoryInstance.getInstituteExamSetting({
            instituteId: this.selectedInstituteId,
          });
        this.selectedPassingPercentageType =
          data.result[0].passingPercentageType;
        this.gradeDetails = data.result[0].gradeDetails;
        this.selectedSystemPreference = data.result[0].systemPreference;
        this.numberOfBacklogsAllowed = data.result[0].numberOfBacklogsAllowed;
        this.selectedCieSeePreference = data.result[0].selectedCieSeePreference;
        this.selectedPercentageAndCriteriaSystemPreference =
          data.result[0].selectedPercentageAndCriteriaSystemPreference;
        this.promotionCreditsRequiredForNextYear =
          data.result[0].promotionCreditsRequiredForNextYear;
        this.maxNumberOfAttempts = data.result[0].maxNumberOfAttempts;

        this.selectedSubjects = data.result[0].subjectGradePercentages;

        if (data.result[0].selectedCieSeePreference === "yes") {
          this.ciePassingCredits = data.result[0].ciePassingCredits;
          this.seePassingCredits = data.result[0].seePassingCredits;
          this.minimumCiePercentage = data.result[0].minimumCiePercentage;
          this.minimumSeePercentage = data.result[0].minimumSeePercentage;
        }
        if (data.result[0].systemPreference === "cbs") {
          this.totalExamPassingCredits = data.result[0].totalExamPassingCredits;
        }
        if (
          data.result[0].selectedPercentageAndCriteriaSystemPreference ===
          "batchLevel"
        ) {
          this.selectedDepartment = data.result[0].departmentName;
          this.selectedBatch = data.result[0].batchName;
          this.selectedCourseYear = data.result[0].courseYearName;
        }
        if (data.result[0].selectedCieSeePreference !== "") {
          await this.generatePassingPercentageTableHeader(
            data.result[0].selectedCieSeePreference
          );
        }
        if (data.result[0].subjectGradePercentages.length > 0) {
          this.displaySubjectDetailsTable = true;
        }

        this.selectedPromotionCriteriaPreference =
          data.result[0].selectedPromotionCriteriaPreference;

        if (data.result[0].overallPercentage) {
          this.overallPassingPercentage = data.result[0].overallPercentage;
          this.courseYearPassingPercentage = this.overallPassingPercentage;
        }
        if (data.result[0].systemPreference !== "percentage") {
          this.gradeDetails = data.result[0].gradeDetails;
          this.selectedGradingSystem = data.result[0].gradingSystem;
          this.lowestPercentageOfPassing =
            data.result[0].lowestPercentageOfPassing;
        } else if (
          data.result[0].systemPreference === "percentage" &&
          data.result[0].selectedCieSeePreference === "yes"
        ) {
          this.ciePassingPercentage = data.result[0].ciePassingPercentage;
          this.seePassingPercentage = data.result[0].seePassingPercentage;
        }
        this.getExamSettingsLoader = false;
      } catch (error) {
        console.error(error);
        this.getExamSettingsLoader = false;
      }
    },
  },
};
</script>
<style scoped src="./activityExamSettings.css"></style>
