<template src="./activityResults.html"></template>

<script>
import NewExamRepository from "../../../Repository/NewExam";
import SubjectsRepository from "../../../Repository/Subject";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import ExamSettingsRepository from "../../../Repository/ExamSettings";
import ExamSubjectAndQuestionRepo from "../../../Repository/ExamSubjectAndQuestion";
import numOps from "../../../Services/Utils/numOps";
import arrOps from "../../../Services/Utils/arrayOps";
import showStatus from "../../../NetworkManager/showStatus";
import SemesterRepository from "../../../Repository/Semester";
import UserRepository from "../../../Repository/User";
import { createExcelThroughJson } from "../../../utils/excel";
import ExcelJS from "exceljs";
import axios from "axios";
import NbaRepository from "../../../Repository/NBA";


export default {
  name: "activityResults",
  data() {
    return {

      flagForCurrentSemResult: false,
      selectedCourseYear: "",
      selectedDepartment: "",
      selectedInstitute: "",
      selectedSemester: {},
      lastSemUserObj: {},
      passedStudentCount: 0,
      failedStudentCount: 0,
      totalStudentCount: 0,
      openPanel: 0,
      allSubjects: [],
      allSubjectsLastSem: [],
      lastSemDetails: [],
      allStudentData: [],
      allExamType: [],
      allSubjectData: [],
      selectedSubjectForTable: [],
      selectedStudent: '',
      selectedTypeExam: '',
      allExams: [],
      selectedSubjects: [],
      finalExamsToConsiderForResult: [],
      examSettings: {},
      allSemesterDetails: [],
      confirmResultPublishDialog: false,
      resultTableHeaders: [
        {
          text: "Roll No.",
          value: "rollNumber"
        },
        {
          text: "Student Name",
          value: "studentName"
        },
        {
          text: "SGPA",
          value: "sgpa"
        },
        {
          text: "CGPA",
          value: "cgpa"
        },
      ],
      resultTableData: [],
      lastSemUser: [],
      studentPersonalDetails: [],
      allExamsOfSubjects: [],
      studentIdArray: [],
      resultPublishLoader: false,
      applyOrdinanceDialog: false,
      filteredSubjects: [],
      headerForGradeTable: [],
      gradeArr: [],
      gradeTableData: [],
      allDataRelatedToSubjectTable: [],
      allStudentDataFinalTable: [],
      allStudentDataFinalTable2: [],
      batchData: [],
      lastSemResultUpdateStudent: [],
      searchFaculty: '',
      gradePointTableRow: ['SGPA'],
      subjectTableRow: ['Internal', 'External', 'Ordinance', 'Total', 'Percentage', 'Credits Earned', 'Grade Point', 'Subject Grade'],
      headerForAllSubjectTable: [
        { text: 'Sr.No', value: 'srNo' },
        { text: 'Name of Program', value: 'subjectName' },
        { text: 'Number of students allocated', value: 'allocatedStudent' },
        { text: 'Number of students appeared', value: 'appearedStudent' },
        { text: 'Passing Count', value: 'passCount' },
        { text: '(%) of pass', value: 'passPercent' },
        { text: 'Fail Count', value: 'failCount' },
        { text: '(%) of fail', value: 'failPercent' },
      ],
      generateResultLoader: false,
      dataLoader: false,
      allStudentPercentageWithSubjectId: {},
      relativeGradeRangeSubjectWise: {},
      ordinanceLoader: false,
      isStmiras: false,
    }
  },
  async created() {
    this.dataLoader = true
    this.nbaRepositoryInstance = new NbaRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.examSubjectAndQuestionRepoInstance = new ExamSubjectAndQuestionRepo(this);
    this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
    this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
    this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
    this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];

    await this.fetchData()
    this.dataLoader = false

  },
  methods: {
    async fetchData() {
      if (this.selectedInstitute === "6604982c-cc08-4e00-81fb-a375a10b72e4") {
        this.isStmiras = true
      }
      await this.getSemesterDetails()
      await this.getSubjects(this.selectedSemester.semId, 1)
      await this.getExams()
      await this.getSemesterUsers(this.selectedSemester.semId, 1)
      await this.getExamSettings()
      await this.getUserDetails()
      this.showStudentDetails()
    },
    searchFacultyAdd(i) {
      if (i !== "") {
        const searchTerm = i;
        const regex = new RegExp(searchTerm, "i"); // "i" for case-insensitive search
        let newArrAfterSearch = [];
        this.allStudentDataFinalTable2.map((stu) => {
          if (regex.test(stu.fullName) || regex.test(stu.prnNumber)) {
            newArrAfterSearch.push(stu);
          }
        });
        this.allStudentDataFinalTable = [];

        this.allStudentDataFinalTable = newArrAfterSearch;
      } else {
        this.allStudentDataFinalTable = [...this.allStudentDataFinalTable2];
      }
      // return this.labels.filter(label => regex.test(label.name));
    },
    async downloadAllSubjectDataTable() {
      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        worksheet.mergeCells("A1:C1");

        worksheet.getCell(1, 1).value = "Subject Wise Exam Result";
        let headerName = [
          "Sr.No",
          "Name of Program",
          "Number of students allocated",
          "Number of students appeared",
          "Total Marks",
          "Passing Marks",
          "Passing Count",
          "(%) of pass",
          "Fail Count",
          "(%) of fail",
          "Highest Mark",
          "Lowest Mark",
          "No of Students in grade range",
        ];
        headerName.map((header, i) => {
          if (i === headerName.length - 1) {
            worksheet.mergeCells(2, i + 1, 2, i + 1 + this.gradeArr.length);
            this.gradeArr.map((grade, j) => {
              worksheet.getCell(3, i + j + 1).value = grade;
            });
          } else {
            worksheet.mergeCells(2, i + 1, 3, i + 1);
            worksheet.getCell(2, i + 1).value = header;
          }
        });
        this.allDataRelatedToSubjectTable.map((data, i) => {
          worksheet.getCell(4 + i, 1).value = i + 1;
          worksheet.getCell(4 + i, 2).value = data.subjectName;
          worksheet.getCell(4 + i, 3).value = data.allocatedStudent;
          worksheet.getCell(4 + i, 4).value = data.appearedStudent;
          worksheet.getCell(4 + i, 5).value = data.totalMarks;
          worksheet.getCell(4 + i, 6).value = data.passingMark;
          worksheet.getCell(4 + i, 7).value = data.passingCount;
          worksheet.getCell(4 + i, 8).value = data.passingPercentage;
          worksheet.getCell(4 + i, 9).value = data.failCount;
          worksheet.getCell(4 + i, 10).value = data.failPercentage;
          worksheet.getCell(4 + i, 11).value = data.highestMark;
          worksheet.getCell(4 + i, 12).value = data.lowestMark;
          this.gradeArr.map((grade, j) => {
            worksheet.getCell(4 + i, 13 + j).value = data[grade];
          });
        });
        worksheet.getCell(1, 1).font = {
          name: "Calibri",
          family: 4,
          size: 16,
          bold: true,
        };
        const columnArray = [];
        for (let i = 0; i < worksheet.columnCount; i++) {
          columnArray.push({ width: 20 });
        }
        worksheet.columns = columnArray;

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `SubjectWiseExamResult.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },
    async downloadAllStudentDataTable() {
      try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Sheet 1");
        worksheet.mergeCells("A1:C1");
        worksheet.getCell(1, 1).value = "Student Wise Exam Result";
        let headerName = ["Sr.No", "Roll Number", "PRN", "Student Name"];
        headerName.map((header, i) => {
          worksheet.mergeCells(2, i + 1, 3, i + 1);
          worksheet.getCell(2, i + 1).value = header;
        });
        this.selectedSubjects.map((sub, i) => {
          if (i === 0) {
            worksheet.mergeCells(2, 5, 2, 12);
            worksheet.getCell(2, 5).value = sub.subjectName;
            this.subjectTableRow.map((val, j) => {
              worksheet.getCell(3, j + 5).value = val;
            });
          } else {
            worksheet.mergeCells(2, 5 + i * 8, 2, 12 + i * 8);
            worksheet.getCell(2, 5 + 8 * i).value = sub.subjectName;
            this.subjectTableRow.map((val, j) => {
              worksheet.getCell(3, j + 5 + i * 8).value = val;
            });
          }
        });
        worksheet.getCell(2, this.selectedSubjects.length * 8 + 5).value =
          "SGPA";

        this.allStudentDataFinalTable.map((data, i) => {
          worksheet.getCell(4 + i, 1).value = i + 1;
          worksheet.getCell(4 + i, 2).value = data.rollNumber;
          worksheet.getCell(4 + i, 3).value = data.prnNumber;
          worksheet.getCell(4 + i, 4).value = data.fullName;
          data.result.map((result, j) => {
            worksheet.getCell(4 + i, 5 + 8 * j).value = result.internalMarks;
            worksheet.getCell(4 + i, 6 + 8 * j).value = result.externalMarks;
            worksheet.getCell(4 + i, 7 + 8 * j).value = result.ordinance;
            worksheet.getCell(4 + i, 8 + 8 * j).value = result.totalMarks;
            worksheet.getCell(4 + i, 9 + 8 * j).value = result.percentage;
            worksheet.getCell(4 + i, 10 + 8 * j).value = result.creditEarned;
            worksheet.getCell(4 + i, 11 + 8 * j).value = result.creditPoint;
            worksheet.getCell(4 + i, 12 + 8 * j).value = result.subjectGrade;
          });
          worksheet.getCell(4 + i, this.selectedSubjects.length * 8 + 5).value =
            data.sgpa;
        });
        worksheet.getCell(1, 1).font = {
          name: "Calibri",
          family: 4,
          size: 14,
          bold: true,
        };
        const columnArray = [];
        for (let i = 0; i < worksheet.columnCount; i++) {
          columnArray.push({ width: 20 });
        }
        worksheet.columns = columnArray;

        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `SubjectWiseExamResult.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (err) {
        console.error(err);
        window.alert("Something went wrong while downloading the excel.");
      }
    },
    getStudentMarksData(uId, subId) {
      let arr = ["NP", "P", "X", "NA", "F", "ABS", "W", "NE", "X", "SNG"];

      let sgpa = "-";
      let cgpa = "-";
      let grade = "-";
      let rollNumber = "-";
      let subjectResult = {
        internalMarks: "-",
        externalMarks: "-",
        totalMarks: "-",
        percentage: "-",
        creditEarned: "-",
        creditPoint: "-",
        subjectGrade: "-",
        gradePoint: "-",
        ordinance: "-",
      };
      if (this.semesterStudents.length > 0) {
        this.semesterStudents.map((semUser) => {
          if (semUser.uId === uId) {
            sgpa = semUser.finalSgpa;
            cgpa = semUser.finaCgpa;
            grade = "-";
            rollNumber = semUser.rollNumber ? semUser.rollNumber : "-";
            if (
              Array.isArray(semUser.semesterSubjectMarks) &&
              semUser.semesterSubjectMarks.length > 0
            ) {
              semUser.semesterSubjectMarks.map((marks) => {
                if (marks.subjectId === subId) {
                  if (arr.includes(marks.totalObtainedMarks)) {
                    subjectResult = {
                      internalMarks: "-",
                      externalMarks: "-",
                      totalMarks: marks.totalObtainedMarks,
                      percentage: 0,
                      creditEarned: marks.obtainedCredits,
                      creditPoint: marks.creditPoints,
                      subjectGrade: marks.obtainedGrade,
                    };
                  } else {
                    subjectResult = {
                      internalMarks: marks.totalObtainedInternalMarks,
                      externalMarks: marks.totalObtainedExternalMarks,
                      totalMarks: marks.totalObtainedMarks,
                      percentage: 0,
                      creditEarned: marks.obtainedCredits,
                      creditPoint: marks.creditPoints,
                      subjectGrade: marks.obtainedGrade,
                      ordinance: marks.graceMarks ? marks.graceMarks : "-",
                    };
                    if (marks.totalObtainedMarks !== 0) {
                      subjectResult.percentage = (
                        (Number(marks.totalObtainedMarks) * 100) /
                        Number(marks.totalMarks)
                      ).toFixed(2);
                    }
                  }
                  if (
                    subjectResult.creditPoint !== 0 &&
                    subjectResult.creditPoint !== undefined &&
                    subjectResult.creditPoint !== "-" &&
                    marks.obtainedCredits !== 0 &&
                    marks.obtainedCredits !== "-" &&
                    marks.obtainedCredits !== undefined
                  ) {
                    subjectResult.gradePoint = (
                      marks.obtainedCredits / subjectResult.creditPoint
                    ).toFixed(0);
                  } else {
                    subjectResult.gradePoint = "-";
                  }
                }
              });
            }
          }
        });
      }
      return { rollNumber, sgpa, cgpa, grade, subjectResult };
    },
    createAllStudentDataTable() {
      this.allStudentDataFinalTable = [];

      if (this.studentPersonalDetails.length > 0) {
        this.studentPersonalDetails.map((info) => {
          let objForStudent = {};
          objForStudent["uId"] = info.uId;
          objForStudent["prnNumber"] = info.collegePRNNo;
          objForStudent["sgpa"] = 0;
          objForStudent["cgpa"] = 0;
          objForStudent["grade"] = 0;
          objForStudent["result"] = [];
          objForStudent["fullName"] =
            info.firstName + " " + info.middleName + " " + info.lastName;
          if (this.selectedSubjects.length > 0) {
            this.selectedSubjects.map((sub) => {
              const { rollNumber, sgpa, cgpa, grade, subjectResult } =
                this.getStudentMarksData(info.uId, sub.subjectId);
              objForStudent["sgpa"] = sgpa;
              objForStudent["cgpa"] = cgpa;
              objForStudent["grade"] = grade;
              objForStudent["rollNumber"] = rollNumber;
              objForStudent["result"].push(subjectResult);
            });
          }
          if (this.selectedStudent?.fullName !== "All") {
            if (this.selectedStudent?.uId === objForStudent.uId) {
              this.allStudentDataFinalTable.push(objForStudent);
            }
          } else {
            this.allStudentDataFinalTable.push(objForStudent);
          }
        });
      }
      this.allStudentDataFinalTable2 = [...this.allStudentDataFinalTable];
    },
    semUserAllCalculation(id, arr) {
      let appeared = 0;
      let failCount = 0;
      let highestMark = 0;
      let lowestMark = 0;
      let totalMarks = 0;
      let isGradedSub = false;
      let objForGrade = {};
      if (this.examSettings.gradingSystem === "absolute") {
        if (this.gradeArr.length > 0) {
          this.gradeArr.map((grade) => {
            if (!objForGrade[grade]) {
              objForGrade[grade] = 0;
            }
          });
        }
      } else {
        this.allSubjects.map((sub) => {
          if (sub.subjectId === id) {
            if (sub.gradeRange && sub.gradeRange.length > 0) {
              sub.gradeRange.map((grade) => {
                if (!objForGrade[grade]) {
                  objForGrade[grade] = 0;
                }
              });
              // if( !sub.gradeRange.includes('NP')){

              // }
              // if (!objForGrade['P']) {
              //     objForGrade['P'] = 0
              // }
              // if (!objForGrade['NP']) {
              //     objForGrade['NP'] = 0
              // }
            }
          }
        })
      }
      let arr1 = ['NP', 'P', 'X', 'NA', 'F', 'ABS', 'W', 'NE', 'X', 'SNG']
      if (this.semesterStudents.length > 0) {
        this.semesterStudents.map((stu, i) => {
          let flag = false
          if (arr.includes(stu.uId)) {
            if (stu.semesterSubjectMarks.length > 0) {
              stu.semesterSubjectMarks.map((exam) => {
                if (exam.subjectId === id) {
                  if (Number(exam.totalMarks) > totalMarks) {

                    totalMarks = exam.totalMarks
                  }
                  if (!objForGrade[exam.obtainedGrade]) {
                    objForGrade[exam.obtainedGrade] = 1;

                  } else {
                    objForGrade[exam.obtainedGrade]++;
                  }
                  if (exam.obtainedGrade === 'F') {
                    failCount++;
                  }
                  if (!arr1.includes(exam.totalObtainedMarks)) {

                    if (highestMark < exam.totalObtainedMarks) {
                      highestMark = exam.totalObtainedMarks
                    }
                    if (i === 0) {
                      lowestMark = exam.totalObtainedMarks
                    }
                    if (lowestMark > exam.totalObtainedMarks) {
                      lowestMark = exam.totalObtainedMarks
                    }
                  } else {
                    if (isGradedSub !== true) {
                      isGradedSub = true
                    }
                    highestMark = '-'
                    lowestMark = '-'
                  }
                  flag = true
                }
              })
            }
          }
          if (flag === true) {
            appeared++;
          }
        })
      }
      let passingMark
      if (totalMarks && totalMarks > 0 && this.examSettings.lowestPercentageOfPassing && this.examSettings.lowestPercentageOfPassing > 0) {

        passingMark = ((totalMarks * Number(this.examSettings.lowestPercentageOfPassing)) / 100).toFixed(2)
      } else {
        passingMark = 40
      }

      return {
        appeared,
        failCount,
        highestMark,
        lowestMark,
        objForGrade,
        totalMarks,
        passingMark,
        isGradedSub,
      };
    },
    findAllocatedStudent(id) {
      const arr = [];
      let studentIds = [];
      if (this.allExamsOfSubjects.length > 0) {
        this.allExamsOfSubjects.map((info) => {
          if (info.subjectId === id) {
            arr.push(info);
          }
        });
      }
      if (arr.length > 0) {
        arr.map((info) => {
          if (Array.isArray(info.allocatedStudents)) {
            studentIds = [...info.allocatedStudents, ...studentIds];
          }
        });
      }
      studentIds = [...new Set(studentIds)];
      return { studentIds };
    },
    async getSubjectExams(arr) {
      try {
        const data =
          await this.examSubjectAndQuestionRepoInstance.getMultipleExam({
            instituteId: this.selectedInstitute,
            examIds: arr,
          });
        this.allExamsOfSubjects = data?.result;
      } catch (err) {
        console.error("err", err);
      }
    },
    async createAllSubjectTable() {
      this.allDataRelatedToSubjectTable = [];
      let groupedExamIds = [];
      if (this.selectedSubjects.length > 0) {
        this.selectedSubjects.map((info) => {
          if (info.groupedExams) {
            groupedExamIds = [...groupedExamIds, ...info.groupedExams];
          }
        });
        if (groupedExamIds.length > 0) {
          await this.getSubjectExams(groupedExamIds);
          this.selectedSubjects.map((info) => {
            const { studentIds } = this.findAllocatedStudent(info.subjectId);
            const {
              appeared,
              failCount,
              highestMark,
              lowestMark,
              objForGrade,
              totalMarks,
              passingMark,
              isGradedSub,
            } = this.semUserAllCalculation(info.subjectId, studentIds);
            let obj = {
              subjectName: info.subjectName,
              allocatedStudent: studentIds.length,
              appearedStudent: appeared,
              passingCount: !isGradedSub ? studentIds.length - failCount : "-",
              passingPercentage: !isGradedSub
                ? Number(
                  (100 * (studentIds.length - failCount)) / studentIds.length
                ).toFixed(2)
                : "-",
              failCount: failCount,
              failPercentage: !isGradedSub
                ? (
                  100 -
                  Number(
                    (100 * (studentIds.length - failCount)) /
                    studentIds.length
                  )
                ).toFixed(2)
                : "-",
              totalMarks: totalMarks,
              highestMark: highestMark,
              lowestMark: lowestMark,
              passingMark: passingMark,
            };
            obj = { ...obj, ...objForGrade };
            this.allDataRelatedToSubjectTable.push(obj);
          });
        }
      }
    },
    downloadGradeRange() {
      createExcelThroughJson(this.gradeTableData);
    },
    createGradeTable() {
      let objForGrade = {};
      this.headerForGradeTable = [{ text: "Name", value: "name" }];
      this.gradeTableData = [];
      this.gradeArr = [];
      if (this.examSettings.gradingSystem === "absolute") {
        if (
          this.examSettings.gradeDetails &&
          this.examSettings.gradeDetails.length > 0
        ) {
          this.examSettings.gradeDetails.map((grade) => {
            this.headerForGradeTable.push({
              text: grade.gradeName,
              value: grade.gradeName,
            });
            this.gradeArr.push(grade.gradeName);
            objForGrade[grade.gradeName] =
              grade.gradeRange.from + " - " + grade.gradeRange.to;
          });
        }
        if (this.selectedSubjects.length > 0) {
          this.selectedSubjects.map((info) => {
            let objToPush = { ...objForGrade };
            objToPush["name"] = info.subjectName;
            this.gradeTableData.push(objToPush);
          });
        }
      } else {
        if (
          this.examSettings.gradeDetails &&
          this.examSettings.gradeDetails.length > 0
        ) {
          this.examSettings.gradeDetails.map((grade) => {
            this.headerForGradeTable.push({
              text: grade.gradeName,
              value: grade.gradeName,
            });
            this.gradeArr.push(grade.gradeName);
            // objForGrade[grade.gradeName] = grade.gradeRange.from + '-' + grade.gradeRange.to
          });
        }
        if (this.selectedSubjects.length > 0) {
          this.selectedSubjects.map((info) => {
            let objToPush = { ...objForGrade };
            objToPush["name"] = info.subjectName;
            if (info.gradeRange.length > 0) {
              info.gradeRange.map((grade) => {
                objToPush[grade.gradeName] =
                  grade.gradeRange?.from + " - " + grade.gradeRange?.to;
              });
            }
            this.gradeTableData.push(objToPush);
          });
        }
      }
      if (!this.gradeArr.includes("F")) {
        this.gradeArr.push("F");
      }
    },
    subjectChanged() {
      this.createGradeTable();
      this.createAllSubjectTable();
      this.createAllStudentDataTable();
    },
    studentChange() {
      this.selectedTypeExam = "";
      this.selectedSubjects = [];
    },
    fillDropDownValue() {
      this.allExamType = ["All"];
      this.allStudentData = this.studentPersonalDetails.map((stu) => {
        let name = stu.firstName + " " + stu.middleName + " " + stu.lastName;
        return {
          fullName: name,
          uId: stu.uId,
        };
      });
      this.allStudentData.unshift({
        fullName: "All",
        uId: "",
      });
    },
    async getUserDetails() {
      try {
        this.studentPersonalDetails =
          await this.userRepositoryInstance.getUsers({
            uIds: this.studentIdArray,
          });
      } catch (err) {
        console.error("err", err);
      }
      this.fillDropDownValue();
    },
    async getExams() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        };

        this.allExams = await this.newExamRepositoryInstance.getExams(objToPush)
      } catch (error) {
        console.error('error', error);
      }
    },
    async getSubjects(semId, type) {
      try {
        let objForSubject = {
          instituteId: this.selectedInstitute,
          semId: semId,
        };
        let arr1 = []
        arr1 =
          await this.subjectsRepositoryInstance.getSubjectsOfAInstituteAndSem(
            objForSubject
          );
        if (type === 1) {
          this.allSubjects = [...arr1]
        }
        if (type === 2) {
          this.allSubjectsLastSem = [...arr1]
        }
        let arr = []
        this.allSubjects.map((item) => {
          if (item.department === this.selectedDepartment && item.courseYear === this.selectedCourseYear) {
            arr.push(item)
          }
        })
        this.allSubjects = [...arr]
      } catch (err) {
        console.error('err', err);
      }
    },
    async getExamSettings() {
      try {

        const result =
          await this.examSettingsRepositoryInstance.getInstituteExamSetting({
            instituteId: this.selectedInstitute,
          });
        this.examSettings = result.result[0];
      } catch (error) {
        console.error('error', error);
      }
    },
    cancelOrdinanceDialog() {
      this.ordinanceLoader = false
      this.applyOrdinanceDialog = false
    },
    async getSemesterUsers(semId, type) {
      let arr = []
      try {
        arr =
          await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
            instituteId: this.selectedInstitute,
            semId: semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
          });

        if (type === 1) {
          this.semesterStudents = arr
          this.studentIdArray = this.semesterStudents.map(item => item.uId)
        }
        if (type === 2) {
          this.lastSemUser = arr
        }
      } catch (error) {
        console.error('error', error);
      }

    },
    async getSemesterDetails() {
      try {
        this.allSemesterDetails =
          await this.semesterRepositoryInstance.getSemestersBySemId({
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
          });
      } catch (error) {
        console.error('error', error);
      }
    },
    async filterExams() {
      this.filteredSubjects = this.allSubjects.filter(item => item.department === this.selectedDepartment && item.courseYear === this.selectedCourseYear)
      let uniqueIdsSet = new Set();
      // Iterate over each object in the array
      this.filteredSubjects.forEach(item => {
        // Iterate over each groupedExams id in the current object
        item.groupedExams.forEach(id => {
          // Add the id to the uniqueIdsSet
          uniqueIdsSet.add(id);
        });
      });

      // Convert the set to an array (if needed)
      let uniqueIdsArray = Array.from(uniqueIdsSet);
      this.finalExamsToConsiderForResult = this.allExams.filter((exam) =>
        uniqueIdsArray.includes(exam.examId)
      );
      this.calculateStudentsResult(this.filteredSubjects);

      await this.saveResult();
      await this.fetchData();
    },
    async calculateSubjectWiseRange() {
      this.relativeGradeRangeSubjectWise = {};
      // let keysForSubjectId = Object.keys(this.allStudentPercentageWithSubjectId)
      // if (keysForSubjectId.length > 0) {
      //     keysForSubjectId.map((key) => {
      //         this.relativeGradeRangeSubjectWise[key] = []
      //         let count = this.examSettings?.gradeDetails?.length
      //         let arrForMArks = this.allStudentPercentageWithSubjectId[key]['allStudentPercentage']
      //         let maxPercentage = Math.max(...arrForMArks)
      //         let minPercentage = this.examSettings.lowestPercentageOfPassing ? this.examSettings.lowestPercentageOfPassing : 40
      //         let numberToReduce = ((Number(maxPercentage) - Number(minPercentage)) / count).toFixed(2)
      //         let arr = []
      //         let newValue;
      //         let value;
      //         for (let i = 0; i < count; i++) {
      //             let objForGrade = {}
      //             let min;
      //             if (i === 0) {
      //                 value = maxPercentage
      //             } else {
      //                 value = newValue
      //             }
      //             let tempObj = {
      //                 from: null,
      //                 to: null
      //             }
      //             min = value - numberToReduce
      //             newValue = (min - 0.01).toFixed(2)
      //             tempObj.from = numOps.roundOffUptoTwoDecimals(value)
      //             tempObj.to = numOps.roundOffUptoTwoDecimals(min)
      //             // objForGrade.maxRange = numOps.roundOffUptoTwoDecimals(value)
      //             // objForGrade.minRange = numOps.roundOffUptoTwoDecimals(min)
      //             objForGrade.gradeRange = tempObj
      //             objForGrade.gradeName = this.examSettings?.gradeDetails[i].gradeName
      //             objForGrade.gradePoints = Number(this.examSettings?.gradeDetails[i].gradePoints)
      //             objForGrade.gradeRemark = this.examSettings?.gradeDetails[i].gradeRemark
      //             arr.push(objForGrade)
      //         }
      //         this.relativeGradeRangeSubjectWise[key] = arr

      //     })
      // }
      let totalNumberOfGrades = this.examSettings.gradeDetails.length;
      this.relativeGradeRangeSubjectWise = {};
      for (const [key, value] of Object.entries(
        this.allStudentPercentageWithSubjectId
      )) {
        let upperLimit = Math.max(...value.allStudentPercentage);
        let rangeDiff = numOps.roundOffUptoTwoDecimals(
          (upperLimit - Number(this.examSettings.lowestPercentageOfPassing)) /
          totalNumberOfGrades
        );
        let tempGradeDetails = [];
        // calculate grade range based on max obtained marks by a student for each subject
        for (let index = 0; index < totalNumberOfGrades; index++) {
          let lowerLimit = numOps.roundOffUptoTwoDecimals(
            upperLimit - rangeDiff
          );
          let tempGradeRange = {
            from: lowerLimit,
            to: upperLimit,
          };
          let tempGradeName = this.examSettings.gradeDetails[index].gradeName;
          let tempGradePoints =
            this.examSettings.gradeDetails[index].gradePoints;
          let tempGradeRemark =
            this.examSettings.gradeDetails[index].gradeRemark;
          tempGradeDetails.push({
            gradeName: tempGradeName,
            gradePoints: tempGradePoints,
            gradeRemark: tempGradeRemark,
            gradeRange: tempGradeRange,
          });
          upperLimit = numOps.roundOffUptoTwoDecimals(lowerLimit - 0.01);
        }
        this.relativeGradeRangeSubjectWise[key] = tempGradeDetails; // subjectId: subjectGradeRanges
        this.relativeGradeRangeSubjectWise[key][
          totalNumberOfGrades - 1
        ].gradeRange.from = Number(this.examSettings.lowestPercentageOfPassing);
      }

      await this.updateSubjectDetails()
    },
    async updateSubjectDetails() {
      let subjectsToUpdate = [];
      for (const [key, value] of Object.entries(this.relativeGradeRangeSubjectWise)) {
        let objToPush = {};
        objToPush.subjectId = key;
        objToPush.instituteId = this.selectedInstitute;
        objToPush.semId = this.selectedSemester.semId;
        objToPush.department = this.selectedDepartment;
        objToPush.courseYear = this.selectedCourseYear;
        objToPush.gradeRange = value;
        // value.reverse()
        subjectsToUpdate.push(objToPush);
      }
      try {
        await this.subjectsRepositoryInstance.updateMultipleSubjectDetails(
          {
            subjectsToUpdate,
          }
        );
      } catch (error) {
        console.error("error", error);
      }
    },
    assignGradeForRelative(subjectId, percentage) {
      let obj = {}
      this.relativeGradeRangeSubjectWise[subjectId].map((info) => {
        if (percentage >= info.gradeRange.from &&
          percentage <= info.gradeRange.to) {
          obj['gradeLetter'] = info.gradeName
          obj['gradePoint'] = info.gradePoints
        }
      })
      return obj
    },
    calculateStudentsResult(subjects) {
      this.allStudentPercentageWithSubjectId = {}
      let length = this.semesterStudents.length

      for (let si = 0; si < length; si++) {
        const currentStudent = this.semesterStudents[si];
          let subjectMarks = []
          for (let subIn = 0; subIn < this.filteredSubjects.length; subIn++) {
            const currentSubject = this.filteredSubjects[subIn];
            if (currentStudent.subjects.includes(currentSubject.subjectId)) {
              // calculate if the subject has grouped exams
              if (currentSubject.groupedExams.length > 0) {
                const result = this.calculateMarksOfASubject(currentStudent.uId, currentSubject.subjectId, currentStudent.subjects)
                if (result) {
                  if (Object.keys(this.allStudentPercentageWithSubjectId).includes(result.subjectId)) {
  
                    this.allStudentPercentageWithSubjectId[result.subjectId]['allStudentPercentage'].push(Number(result.percentage))
                  } else {
                    this.allStudentPercentageWithSubjectId[result.subjectId] = {
                      allStudentPercentage: [Number(result.percentage)],
                      totalMarks: result.totalMarks,
                    }
                  }
                  // if (result.totalMarks > 0) {
                  subjectMarks.push(result)
                  // }
                }
              }
            }
          }
          currentStudent['subjectMarks'] = subjectMarks
      }

      if (this.examSettings.gradingSystem !== "absolute") {
        this.calculateSubjectWiseRange();
      }
      for (let si = 0; si < length; si++) {
        const currentStudent = this.semesterStudents[si];
        let subjectDetailsArray = [];
        let totalCreditPoints = 0;
        let totalCredits = 0;
        let passedSubjectCount = 0;
        let obtainedMarksSum = 0;
        let totalMarksSum = 0;
        if (currentStudent?.subjectMarks.length > 0) {
          if (this.examSettings.systemPreference === "cbs") {
            if (this.examSettings.gradingSystem === "absolute") {
              // let subjectDetailsArray = []
              currentStudent.subjectMarks.map((subject) => {
                if (subject.isValidMarks === true) {
                  obtainedMarksSum += subject.totalObtainedMarks;
                  totalMarksSum += subject.totalMarks;
                  let percentage = numOps.calculatePercentage(
                    subject.totalObtainedMarks,
                    subject.totalMarks
                  );
                  let internalPercentage = numOps.calculatePercentage(
                    subject.totalObtainedInternalMarks,
                    subject.totalInternalMarks
                  );
                  let externalPercentage = numOps.calculatePercentage(
                    subject.totalObtainedExternalMarks,
                    subject.totalExternalMarks
                  );

                  // let subjectCredits = subjects.filter(sub => sub.subjectId === subject.subjectId)[0].credits
                  let subj = subjects.filter(
                    (sub) => sub.subjectId === subject.subjectId
                  )[0];
                  if (this.examSettings.selectedCieSeePreference === "yes") {
                    if (subj.isSeparatePassing) {
                      if (
                        internalPercentage >=
                        this.examSettings.minimumCiePercentage &&
                        externalPercentage >=
                        this.examSettings.minimumSeePercentage
                      ) {
                        // if (percentage >=
                        //     this.examSettings.lowestPercentageOfPassing) {
                        const { gradeLetter, gradePoint } =
                          this.assignGrade(percentage);
                        subject["obtainedCredits"] = subj.credits;
                        subject["obtainedGrade"] = gradeLetter;
                        subject["gradePoints"] = gradePoint;
                        subject["creditPoints"] = subj.credits * gradePoint;
                        // } else {
                        //     subject['obtainedCredits'] = 0
                        //     subject['obtainedGrade'] = "F"
                        //     subject['gradePoints'] = 0
                        //     subject['creditPoints'] = 0
                        // }
                      } else {
                        subject["obtainedCredits"] = 0;
                        subject["obtainedGrade"] = "F";
                        subject["gradePoints"] = 0;
                        subject["creditPoints"] = 0;
                      }
                    } else {
                      if (
                        percentage >=
                        this.examSettings.lowestPercentageOfPassing
                      ) {
                        const { gradeLetter, gradePoint } =
                          this.assignGrade(percentage);
                        subject["obtainedGrade"] = gradeLetter;
                        subject["obtainedCredits"] = subj.credits;
                        subject["gradePoints"] = gradePoint;
                        subject["creditPoints"] = subj.credits * gradePoint;
                      } else {
                        subject["obtainedCredits"] = 0;
                        subject["obtainedGrade"] = "F";
                        subject["gradePoints"] = 0;
                        subject["creditPoints"] = 0;
                      }
                    }
                  } else {
                    if (
                      percentage >= this.examSettings.lowestPercentageOfPassing
                    ) {
                      const { gradeLetter, gradePoint } =
                        this.assignGrade(percentage);
                      subject["obtainedGrade"] = gradeLetter;
                      subject["obtainedCredits"] = subj.credits;
                      subject["gradePoints"] = gradePoint;
                      subject["creditPoints"] = subj.credits * gradePoint;
                    }
                  }
                  totalCreditPoints += subject.creditPoints;
                  totalCredits += subject.obtainedCredits;
                  subjectDetailsArray.push(subject);
                } else {
                  subjectDetailsArray.push(subject);
                }
                if (subject.obtainedGrade !== ("" || "F")) {
                  passedSubjectCount++;
                }
              });
            } else {
              currentStudent.subjectMarks.map((subject) => {
                if (subject.isValidMarks === true) {
                  obtainedMarksSum += subject.totalObtainedMarks;
                  totalMarksSum += subject.totalMarks;

                  let percentage = numOps.calculatePercentage(
                    subject.totalObtainedMarks,
                    subject.totalMarks
                  );
                  let internalPercentage = numOps.calculatePercentage(
                    subject.totalObtainedInternalMarks,
                    subject.totalInternalMarks
                  );
                  let externalPercentage = numOps.calculatePercentage(
                    subject.totalObtainedExternalMarks,
                    subject.totalExternalMarks
                  );

                  let subj = subjects.filter(
                    (sub) => sub.subjectId === subject.subjectId
                  )[0];
                  if (this.examSettings.selectedCieSeePreference === "yes") {
                    if (subj.isSeparatePassing) {
                      if (
                        internalPercentage >=
                        this.examSettings.minimumCiePercentage &&
                        externalPercentage >=
                        this.examSettings.minimumSeePercentage
                      ) {
                        const { gradeLetter, gradePoint } =
                          this.assignGradeForRelative(
                            subject.subjectId,
                            percentage
                          );

                        subject["obtainedCredits"] = subj.credits;
                        subject["obtainedGrade"] = gradeLetter;
                        subject["gradePoints"] = gradePoint;
                        subject["creditPoints"] = subj.credits * gradePoint;
                      } else {
                        subject["obtainedCredits"] = 0;
                        subject["obtainedGrade"] = "F";
                        subject["gradePoints"] = 0;
                        subject["creditPoints"] = 0;
                      }
                    } else {
                      if (
                        percentage >=
                        this.examSettings.lowestPercentageOfPassing
                      ) {
                        const { gradeLetter, gradePoint } =
                          this.assignGradeForRelative(
                            subject.subjectId,
                            percentage
                          );

                        subject["obtainedGrade"] = gradeLetter;
                        subject["obtainedCredits"] = subj.credits;
                        subject["gradePoints"] = gradePoint;
                        subject["creditPoints"] = subj.credits * gradePoint;
                      } else {
                        subject["obtainedCredits"] = 0;
                        subject["obtainedGrade"] = "F";
                        subject["gradePoints"] = 0;
                        subject["creditPoints"] = 0;
                      }
                    }
                  } else {
                    if (
                      percentage >= this.examSettings.lowestPercentageOfPassing
                    ) {
                      const { gradeLetter, gradePoint } =
                        this.assignGradeForRelative(
                          subject.subjectId,
                          percentage
                        );

                      subject["obtainedGrade"] = gradeLetter;
                      subject["obtainedCredits"] = subj.credits;
                      subject["gradePoints"] = gradePoint;
                      subject["creditPoints"] = subj.credits * gradePoint;
                    } else {
                      subject["obtainedCredits"] = 0;
                      subject["obtainedGrade"] = "F";
                      subject["gradePoints"] = 0;
                      subject["creditPoints"] = 0;
                    }
                  }

                  totalCreditPoints += subject.creditPoints;
                  totalCredits += subject.obtainedCredits;
                  subjectDetailsArray.push(subject);
                } else {
                  subjectDetailsArray.push(subject);
                }
                if (subject.obtainedGrade !== ("F" || "NP")) {
                  passedSubjectCount++;
                }
              });
            }
            let percentage = numOps.calculatePercentage(
              obtainedMarksSum,
              totalMarksSum
            );
            currentStudent.semesterSubjectMarks = subjectDetailsArray;
            let sgpa = this.calculateSGPA(
              passedSubjectCount,
              currentStudent.semesterSubjectMarks.length,
              totalCreditPoints,
              totalCredits
            );
            currentStudent["totalObtainedMarks"] = Number(obtainedMarksSum)
            currentStudent["totalMarks"] = Number(totalMarksSum)
            currentStudent["finalSgpa"] = sgpa;
            currentStudent["finalPercentage"] = percentage;
          }
        }
        if (currentStudent.finalSgpa > 0) {
          currentStudent.passStatus = true
        }
      }
      // console.log("this.semesterStudents", this.semesterStudents);
      this.removeUnnecessaryFields();
    },
    removeUnnecessaryFields() {
      let temp = this.semesterStudents;
      this.semesterStudents = temp.map((obj) => ({
        ...obj,
        semesterSubjectMarks: obj.semesterSubjectMarks.map((subj) => {
          let {
            isValidMarks,
            externalPercentage,
            internalPercentage,
            ...rest
          } = subj; // Destructure the object, excluding isValidMarks
          return rest;
        }),
      }));
      // return newArray
    },
    assignGrade(marks) {
      let gradeLetter = "";
      let gradePoint = 0;
      let tempGrade = this.examSettings.gradeDetails.filter(
        (e) =>
          Number(marks) <= Number(e.gradeRange.from) &&
          Number(marks) >= Number(e.gradeRange.to)
      )[0];
      if (tempGrade) {
        gradeLetter = tempGrade.gradeName;
        gradePoint = Number(tempGrade.gradePoints);
      } else {
        gradeLetter = "F";
        gradePoint = 0;
      }
      return { gradeLetter, gradePoint };
    },
    giveCreditsBySubId(id) {
      let credits = 0;
      this.allSubjects.map((sub) => {
        if (sub.subjectId === id) {
          credits = Number(sub.credits);
        }
      });
      return credits;
    },
    handleCustomMarks(student, sub, subjectId, studentId, exam) {
      let obj = {
        totalInternalMarks: 0,
        percentage: 0,
        internalPercentage: 0,
        externalPercentage: 0,
        totalObtainedInternalMarks: 0,
        totalExternalMarks: 0,
        totalObtainedExternalMarks: 0,
        totalObtainedMarks: 0,
        totalMarks: 0,
        subjectId: sub.subjectId,
        examMarks: [],
        isValidMarks: false,
        obtainedGrade: "F",
        obtainedCredits: 0,
        gradePoints: 0,
        creditPoints: 0,
      };
      let regex = /^(P|NP)$/;
      if (sub.subjectId === subjectId) {
        let currentStudent = sub.examMarks.filter(
          (stud) => stud.uId === studentId
        )[0];
        if (currentStudent) {
          let tempMarks = ""; //here, marks are string becuase custom marks are always in the form of String. For example 'ABS', 'P', 'NP' etc.
          if (currentStudent.totalMarksObtained !== undefined) {
            tempMarks = currentStudent.totalMarksObtained;
          } else {
            tempMarks = currentStudent.obtainedMarks;
          }
          let objToPush = {
            examId: exam.examId,
            marks: tempMarks,
          };

          obj.examMarks.push(objToPush);
        }
      }
      let allNicmarIns = [
        "d26eac93-150f-446d-8708-8ce794361957",
        "25738fb8-0205-4b7e-ace2-96f8f940bb8d",
        "2e1691e6-f0bb-446d-b080-e0173d44af4c",
        "860153bb-3a42-4101-b3fb-8f08f684db90",
        "8e38d185-7cb0-42ba-bb19-04fdfacf819d",
        "f32c10d6-f25d-44b5-9fcb-e35e33e2aec2",
        "f977ee47-4b0f-4af5-b68c-b2685236e4d9",
      ];
      if (allNicmarIns.includes(sub.instituteId)) {
        // if (student.totalMarksObtained === 'P') {
        //     obj.obtainedGrade = student.totalMarksObtained
        //     obj.obtainedCredits = this.giveCreditsBySubId(sub.subjectId)
        // }
        // if (student.totalMarksObtained === 'NP') {
        //     obj.obtainedGrade = student.totalMarksObtained
        // }
        let tempMarks = "";
        if (student.totalMarksObtained !== undefined) {
          tempMarks = student.totalMarksObtained;
        } else {
          tempMarks = student.obtainedMarks;
        }
        if (regex.test(tempMarks)) {
          // if(subjectId === "607a0f4d-a0f8-4f98-81a5-d3db41febfda" && studentId === "4615d97e-9425-4433-b4cc-f0b9c894bc91") {
          //   console.log('>>>>>>>>>', tempMarks);
          // }
          obj.obtainedGrade = tempMarks;
          // if(subjectId === "607a0f4d-a0f8-4f98-81a5-d3db41febfda" && studentId === "4615d97e-9425-4433-b4cc-f0b9c894bc91") {
          //   // console.log('>>>>>>>>>', tempMarks);
          //   console.log('obj.obtainedGrade', obj.obtainedGrade);
          // }
        } else {
          obj.obtainedGrade = "F";
        }
        if (tempMarks === "P") {
          obj.obtainedCredits = this.giveCreditsBySubId(sub.subjectId);
        }
        if (exam.examFormat === "Summative") {
          obj.totalExternalMarks = Number(sub.totalMarks);
        } else {
          obj.totalInternalMarks = Number(sub.totalMarks);
        }
      }
      // if(subjectId === "607a0f4d-a0f8-4f98-81a5-d3db41febfda" && studentId === "f4197aa0-1524-41e4-a6b3-5ffde96322c6") {
      //   // console.log('>>>>>>>>>', tempMarks);
      //   console.log('obj.obtainedGrade', obj.obtainedGrade);
      // }
      // if(obj.obtainedGrade === "F") {
      //   console.log('student.obtainedMarks', student.obtainedMarks, subjectId);
      // }
      return obj;
    },
    calculateMarksOfASubject(studentId, subjectId, studentSubjects) {
      let flag = false;
      let totalMarks = 0;
      let totalObtainedMarks = 0;
      let totalInternalMarks = 0;
      let totalExternalMarks = 0;
      let totalObtainedInternalMarks = 0;
      let totalObtainedExternalMarks = 0;
      let examMarks = [];
      let isValidMarks = true;

      let objToReturn = {};
      let abc = {}; //to avoid extra subject in the marksheet check if the student is present
      for (
        let index = 0;
        index < this.finalExamsToConsiderForResult.length;
        index++
      ) {
        const currentExam = this.finalExamsToConsiderForResult[index];

        currentExam.questionDetails.map((subject) => {
          let regex = /^(\d+(\.\d+)?|\.\d+)$/;

          if (
            subject.subjectId === subjectId &&
            studentSubjects.includes(subject.subjectId)
          ) {
            let currentStudent = subject.examMarks.filter(
              (stud) => stud.uId === studentId
            )[0];
            if (currentStudent) {
              let tempMarks = 0;
              if (currentStudent.totalMarksObtained !== undefined) {
                tempMarks = currentStudent.totalMarksObtained;
              } else {
                tempMarks = currentStudent.obtainedMarks;
              }
              flag = true;
              if (!regex.test(tempMarks)) {
                abc = this.handleCustomMarks(
                  currentStudent,
                  subject,
                  subjectId,
                  studentId,
                  currentExam
                );
                isValidMarks = false
              }
              let objToPush = {
                examId: currentExam.examId,
                marks: tempMarks,
              };

              totalMarks += Number(subject.totalMarks);
              totalObtainedMarks += !isNaN(tempMarks) ? Number(tempMarks) : 0;

              if (currentExam.examFormat === "Formative") {
                totalInternalMarks += Number(subject.totalMarks);
                totalObtainedInternalMarks += !isNaN(tempMarks)
                  ? Number(tempMarks)
                  : 0;
              } else {
                totalExternalMarks += Number(subject.totalMarks);
                totalObtainedExternalMarks += !isNaN(tempMarks)
                  ? Number(tempMarks)
                  : 0;
              }
              examMarks.push(objToPush);
            }
          }
        });
      }
      let percentage = numOps.calculatePercentage(
        totalObtainedMarks,
        totalMarks
      );
      let internalPercentage = numOps.calculatePercentage(
        totalObtainedInternalMarks,
        totalInternalMarks
      );
      let externalPercentage = numOps.calculatePercentage(
        totalObtainedExternalMarks,
        totalExternalMarks
      );
      objToReturn = {
        totalInternalMarks,
        percentage,
        internalPercentage,
        externalPercentage,
        totalObtainedInternalMarks,
        totalExternalMarks,
        totalObtainedExternalMarks,
        totalObtainedMarks,
        totalMarks,
        subjectId,
        examMarks,
        isValidMarks,
      };
      if (Object.keys(abc).length !== 0) {
        // objToReturn. =
        if (abc) {
          objToReturn.obtainedGrade = abc.obtainedGrade
          objToReturn.gradePoints = abc.gradePoints
          objToReturn.obtainedCredits = abc.obtainedCredits
          objToReturn.creditPoints = abc.creditPoints
        }
      }
      //   else {
      //     objToReturn = {
      //       totalInternalMarks,
      //       percentage,
      //       internalPercentage,
      //       externalPercentage,
      //       totalObtainedInternalMarks,
      //       totalExternalMarks,
      //       totalObtainedExternalMarks,
      //       totalObtainedMarks,
      //       totalMarks,
      //       subjectId,
      //       examMarks,
      //       isValidMarks,
      //     };
      //   }
      if (flag) {
        return objToReturn;
      }
    },
    calculateSGPA(
      passedSubjectCount,
      totalSubjectCount,
      totalCreditPoints,
      totalCredits
    ) {
      let finalSgpa = 0;
      if (passedSubjectCount === totalSubjectCount) {
        let tempSgpa = totalCreditPoints / totalCredits;
        if (isNaN(tempSgpa)) {
          finalSgpa = 0;
        } else {
          finalSgpa = numOps.roundOffUptoTwoDecimals(tempSgpa);
        }
      } else {
        finalSgpa = 0;
      }
      return finalSgpa;
    },
    handleGenerateResult() {
      this.confirmResultPublishDialog = true;
    },
    async saveResult() {
      this.selectedStudent = ''
      this.selectedTypeExam = ''
      this.selectedSubjects = []
      if (this.semesterStudents.length > 0) {
        this.semesterStudents.map((item) => {
          let arr = [];
          if (
            Array.isArray(item.semesterSubjectMarks) &&
            item.semesterSubjectMarks.length > 0
          ) {
            item.semesterSubjectMarks.map((sub) => {
              if (item.subjects.includes(sub.subjectId)) {
                arr.push(sub);
              }
            });
          }
          item.semesterSubjectMarks = [...arr];
        });
      }
      this.resultPublishLoader = true;
      let filteredSemesters = this.allSemesterDetails.filter(
        (sem) =>
          sem.instituteId === this.selectedInstitute &&
          sem.semId === this.selectedSemester.semId &&
          sem.department === this.selectedDepartment &&
          sem.courseYear === this.selectedCourseYear
      );
      try {
        await this.semesterUserRepositoryInstance.updateSemesterUserResultDetails(
          {
            userDetailsArray: this.semesterStudents,
            systemPreference: this.examSettings.systemPreference,
          }
        );
        await this.semesterRepositoryInstance.updateSemesterCourseYearResultDetails(
          {
            semesters: filteredSemesters,
          }
        );
        this.publishResultDialog = false;
        showStatus("Result published successfully.", 5000, "success", this);
      } catch (error) {
        console.error("error", error);

        showStatus(
          "Error publishing Result. Please try again later.",
          5000,
          "success",
          this
        );
      }
      this.confirmResultPublishDialog = false
      this.resultPublishLoader = false
    },
    calculateGrades(subjectMarks, type) {
      let regex = /^(NA|ABS|W|NE|X|SNG|P|NP)$/;
      let passedSubjectCount = 0
      let arr = this.allSubjects
      if (type === 2) {
        arr = this.allSubjectsLastSem
      }
      subjectMarks.map((subject) => {
        if (!regex.test(subject.obtainedGrade)) {
          let percentage = numOps.calculatePercentage(subject.totalObtainedMarks, subject.totalMarks)
          let internalPercentage = numOps.calculatePercentage(subject.totalObtainedInternalMarks, subject.totalInternalMarks)
          let externalPercentage = numOps.calculatePercentage(subject.totalObtainedExternalMarks, subject.totalExternalMarks)
          let subj = arr.filter(sub => sub.subjectId === subject.subjectId)[0]

          if (this.examSettings.systemPreference === "cbs") {
            if (this.examSettings.gradingSystem === "relative") {
              if (this.examSettings.selectedCieSeePreference === "yes") {
                if (subj.isSeparatePassing) {
                  if (internalPercentage >= this.examSettings.minimumCiePercentage &&
                    externalPercentage >= this.examSettings.minimumSeePercentage) {

                    const { gradeLetter, gradePoint } = this.assignGradeForRelative(subject.subjectId, percentage)
                    // if (gradeLetter !== ("" || "F")) {
                    //     passedSubjectCount++
                    // }
                    subject['obtainedCredits'] = subj.credits
                    subject['obtainedGrade'] = gradeLetter
                    subject['gradePoints'] = gradePoint
                    subject['creditPoints'] = subj.credits * gradePoint
                  } else {
                    subject['obtainedCredits'] = 0
                    subject['obtainedGrade'] = "F"
                    subject['gradePoints'] = 0
                    subject['creditPoints'] = 0
                  }
                } else {
                  if (percentage >=
                    this.examSettings.lowestPercentageOfPassing) {
                    const { gradeLetter, gradePoint } = this.assignGradeForRelative(subject.subjectId, percentage)
                    // if (gradeLetter !== ("" || "F")) {
                    //     passedSubjectCount++
                    // }
                    subject['obtainedGrade'] = gradeLetter
                    subject['obtainedCredits'] = subj.credits
                    subject['gradePoints'] = gradePoint
                    subject['creditPoints'] = subj.credits * gradePoint
                  } else {
                    subject['obtainedCredits'] = 0
                    subject['obtainedGrade'] = "F"
                    subject['gradePoints'] = 0
                    subject['creditPoints'] = 0
                  }
                }
              } else {
                if (percentage >=
                  this.examSettings.lowestPercentageOfPassing) {
                  const { gradeLetter, gradePoint } = this.assignGradeForRelative(subject.subjectId, percentage)
                  // if (gradeLetter !== ("" || "F")) {
                  //     passedSubjectCount++
                  // }
                  subject['obtainedGrade'] = gradeLetter
                  subject['obtainedCredits'] = subj.credits
                  subject['gradePoints'] = gradePoint
                  subject['creditPoints'] = subj.credits * gradePoint
                } else {
                  subject['obtainedCredits'] = 0
                  subject['obtainedGrade'] = "F"
                  subject['gradePoints'] = 0
                  subject['creditPoints'] = 0
                }
              }
            } else {
              if (this.examSettings.selectedCieSeePreference === "yes") {
                if (subj.isSeparatePassing) {
                  if (internalPercentage >= this.examSettings.minimumCiePercentage &&
                    externalPercentage >= this.examSettings.minimumSeePercentage) {

                    const { gradeLetter, gradePoint } = this.assignGrade(percentage)
                    // if (gradeLetter !== ("" || "F")) {
                    //     passedSubjectCount++
                    // }
                    subject['obtainedCredits'] = subj.credits
                    subject['obtainedGrade'] = gradeLetter
                    subject['gradePoints'] = gradePoint
                    subject['creditPoints'] = subj.credits * gradePoint
                  } else {
                    subject['obtainedCredits'] = 0
                    subject['obtainedGrade'] = "F"
                    subject['gradePoints'] = 0
                    subject['creditPoints'] = 0
                  }
                } else {
                  if (percentage >=
                    this.examSettings.lowestPercentageOfPassing) {
                    const { gradeLetter, gradePoint } = this.assignGrade(percentage)
                    // if (gradeLetter !== ("" || "F")) {
                    //     passedSubjectCount++
                    // }
                    subject['obtainedGrade'] = gradeLetter
                    subject['obtainedCredits'] = subj.credits
                    subject['gradePoints'] = gradePoint
                    subject['creditPoints'] = subj.credits * gradePoint
                  } else {
                    subject['obtainedCredits'] = 0
                    subject['obtainedGrade'] = "F"
                    subject['gradePoints'] = 0
                    subject['creditPoints'] = 0
                  }
                }
              } else {
                if (percentage >=
                  this.examSettings.lowestPercentageOfPassing) {
                  const { gradeLetter, gradePoint } = this.assignGrade(percentage)
                  // if (gradeLetter !== ("" || "F")) {
                  //     passedSubjectCount++
                  // }
                  subject['obtainedGrade'] = gradeLetter
                  subject['obtainedCredits'] = subj.credits
                  subject['gradePoints'] = gradePoint
                  subject['creditPoints'] = subj.credits * gradePoint
                } else {
                  subject['obtainedCredits'] = 0
                  subject['obtainedGrade'] = "F"
                  subject['gradePoints'] = 0
                  subject['creditPoints'] = 0
                }
              }
            }
          }
        }
        if (subject.obtainedGrade !== ("NP" || "F")) {
          passedSubjectCount++
        }
      })
      return { subjectMarks, passedSubjectCount }
    },
    showStudentDetails() {
      this.failedStudentCount = 0
      this.totalStudentCount = 0
      this.passedStudentCount = 0
      this.resultTableData = []
      this.totalStudentCount = this.semesterStudents.length
      let array = []
      this.semesterStudents.map((student) => {
        let objToPush = {}
        let personalDetails = this.studentPersonalDetails.filter(item => item.uId === student.uId)[0]
        objToPush['studentName'] = personalDetails?.firstName + ' ' + personalDetails?.lastName
        objToPush['rollNumber'] = student.rollNumber
        objToPush['sgpa'] = student.finalSgpa
        objToPush['cgpa'] = student.finalCgpa
        this.resultTableData.push(objToPush)

        if (student.finalSgpa > 0) {
          this.passedStudentCount++
        } else {
          array.push(objToPush.studentName)
        }
      })
      this.failedStudentCount = this.totalStudentCount - this.passedStudentCount
    },
    confirmApplyOrdinance() {
      this.applyOrdinanceDialog = true
    },
    async applyOrdinance() {
      this.lastSemResultUpdateStudent = []
      let isSemOdd = false
      this.flagForCurrentSemResult = false
      if (!this.examSettings.ordinance) {
        showStatus(
          "Ordinance settings are not present. Please add the settings and try again.",
          9000,
          "error",
          this
        );
        this.applyOrdinanceDialog = false
        return
      }
      if (this.isStmiras === true || this.selectedInstitute === 'f977ee47-4b0f-4af5-b68c-b2685236e4d9') {
        await this.getBatches()
        isSemOdd = this.checkSemIsOdd(this.selectedSemester.semId)
        if (Array.isArray(this.lastSemDetails) && this.lastSemDetails.length > 0) {
          await this.getSubjects(this.lastSemDetails[0].semId, 2)
          await this.getSemesterUsers(this.lastSemDetails[0].semId, 2)

        }
        if (this.lastSemUser.length > 0) {
          this.lastSemUserObj = {}
          this.lastSemUser.map((semUser) => {
            if (!this.lastSemUserObj[semUser.uId]) {
              this.lastSemUserObj[semUser.uId] = semUser.semesterSubjectMarks ? semUser.semesterSubjectMarks : []
            }
          })
          this.semesterStudents.map((item) => {
            item.lastSemResult = []
            item.lastSemResult = this.lastSemUserObj[item.uId] ? this.lastSemUserObj[item.uId] : []
          })
        }
      }
      if(this.isStmiras === false && this.selectedInstitute !== 'f977ee47-4b0f-4af5-b68c-b2685236e4d9') {
        
        this.ordinanceLoader = true
        this.allStudentPercentageWithSubjectId = {}
        let maxGraceMarksToBeGiven = 0
        let temp = this.examSettings.ordinance.maxGraceMarks.percentage;
        // console.log('temp', temp);
        if (temp > 0) {
          maxGraceMarksToBeGiven = this.calculateMarksFromPercentage(
            totalSemesterMarks,
            temp
          );
        } else {
          maxGraceMarksToBeGiven = this.examSettings.ordinance.maxGraceMarks.marks;
        }
      }
      //done
      // console.log('this.semesterStudents1', this.semesterStudents);
      for (let i = 0; i < this.semesterStudents.length; i++) {
        const student = this.semesterStudents[i];
        let failedSubjectsCount = 0
        let remainingMarksToPass = 0
        let totalGraceMarksRequired = 0

        student.semesterSubjectMarks.map((sub) => {
          if (sub.obtainedGrade === "F") {
            failedSubjectsCount++
            let passingMarks = this.calculateMarksFromPercentage(sub.totalExternalMarks, Number(this.examSettings.lowestPercentageOfPassing))
            remainingMarksToPass = passingMarks - sub.totalObtainedExternalMarks
            totalGraceMarksRequired += remainingMarksToPass
          }
        })

        let maxNoOfSubjectsToDivideGraceMarks = this.examSettings.ordinance.totalGraceMarksDividedIn.courseCount
        let modifiedSubjects = []
        if (this.isStmiras === true || this.selectedInstitute === 'f977ee47-4b0f-4af5-b68c-b2685236e4d9') {

          if (isSemOdd === true) {
            this.flagForCurrentSemResult = true
            modifiedSubjects = this.allocateGraceMarksOddSem(student.semesterSubjectMarks)
          } else {
            modifiedSubjects = this.allocateGraceMarksEvenSem(student.semesterSubjectMarks, this.lastSemUserObj[student.uId], student)
          }

        } else {
        //  console.log('failedSubjectsCount',failedSubjectsCount, maxNoOfSubjectsToDivideGraceMarks, student.uId);
        //  console.log('failedSubjectsCount > 0',  failedSubjectsCount > 0, failedSubjectsCount <= maxNoOfSubjectsToDivideGraceMarks, student.uId);
          if (failedSubjectsCount > 0 && failedSubjectsCount <= maxNoOfSubjectsToDivideGraceMarks) {
            modifiedSubjects = this.allocateGraceMarks(student.semesterSubjectMarks, this.examSettings, failedSubjectsCount, totalGraceMarksRequired, remainingMarksToPass)
            // console.log('modifiedSubjects', modifiedSubjects, student.uId);
            student['semesterSubjectMarks'] = modifiedSubjects
          }
        }
        student.semesterSubjectMarks.map((sub) => {
          let percentage = numOps.calculatePercentage(sub.totalObtainedMarks, sub.totalMarks)
          if (Object.keys(this.allStudentPercentageWithSubjectId).includes(sub.subjectId)) {

            this.allStudentPercentageWithSubjectId[sub.subjectId]['allStudentPercentage'].push(Number(percentage))
          } else {
            this.allStudentPercentageWithSubjectId[sub.subjectId] = {
              allStudentPercentage: [Number(percentage)],
              totalMarks: sub.totalMarks,
            }
          }
        })
      }
      if (this.lastSemResultUpdateStudent.length > 0) {
        await this.updateLastSemUser()
      }
      if (isSemOdd === false && this.flagForCurrentSemResult === false && this.isStmiras === true) {
      } else {
        this.calculateSubjectWiseRange()
        // console.log('this.semesterStudents2', this.semesterStudents);
        for (let i = 0; i < this.semesterStudents.length; i++) {
          const student = this.semesterStudents[i];
          let totalCreditPoints = 0
          let totalCredits = 0
          const recalculatedGrades = this.calculateGrades(student.semesterSubjectMarks, 1)
          let passedSubjectCount = 0
          recalculatedGrades.subjectMarks.map((subject, i) => {
            if (subject.obtainedGrade !== ('F' || 'NP')) {
              passedSubjectCount++
            }
            totalCreditPoints += subject.creditPoints
            totalCredits += subject.obtainedCredits
          })
          // console.log('passedSubjectCount', passedSubjectCount, recalculatedGrades.subjectMarks.length, student.uId);
          let sgpa = this.calculateSGPA(passedSubjectCount, recalculatedGrades.subjectMarks.length, totalCreditPoints, totalCredits)
          // console.log('sgpa', sgpa, student.uId, student.semesterSubjectMarks, i);
          student['finalSgpa'] = sgpa
          if (sgpa > 0) {
            student.passStatus = true
          }
        }
        await this.saveResult()
        await this.fetchData()
        // console.log('herbhb');
      }

      this.ordinanceLoader = false
      this.applyOrdinanceDialog = false
    },
    allocateGraceMarks(subjects, examSettings, failedSubjectsCount, totalGraceMarksRequired) {
      let maxGraceMarksToBeGiven = 0
      let totalSemesterMarks = 800
      let temp = examSettings.ordinance.maxGraceMarks.percentage
      if (temp > 0) {
        maxGraceMarksToBeGiven = this.calculateMarksFromPercentage(totalSemesterMarks, temp)
      } else {
        maxGraceMarksToBeGiven = examSettings.ordinance.maxGraceMarks.marks
      }
      let totalTempGraceMarks = maxGraceMarksToBeGiven
      const studentSubjects = arrOps.sortByProperty(subjects, "totalObtainedExternalMarks", 'desc')
      studentSubjects.map((sub) => {
        if (sub.obtainedGrade === "F" && maxGraceMarksToBeGiven > 0 && sub.totalExternalMarks > 0) {
          let passingMarks = this.calculateMarksFromPercentage(sub.totalExternalMarks, Number(examSettings.lowestPercentageOfPassing))
          let remainingMarksToPass = passingMarks - sub.totalObtainedExternalMarks
          // totalGraceMarksRequired += remainingMarksToPass
          if (remainingMarksToPass > 0) {

            let nicmarInstitutes = [
              "d26eac93-150f-446d-8708-8ce794361957",
              "25738fb8-0205-4b7e-ace2-96f8f940bb8d",
              "2e1691e6-f0bb-446d-b080-e0173d44af4c",
              "860153bb-3a42-4101-b3fb-8f08f684db90",
              "8e38d185-7cb0-42ba-bb19-04fdfacf819d",
              "f32c10d6-f25d-44b5-9fcb-e35e33e2aec2",
              "6604982c-cc08-4e00-81fb-a375a10b72e4",
              "f977ee47-4b0f-4af5-b68c-b2685236e4d9"
            ]
            if (totalGraceMarksRequired <= totalTempGraceMarks) {

              if (nicmarInstitutes.includes(this.selectedInstitute)) {
                // NICMAR
                if (remainingMarksToPass <= maxGraceMarksToBeGiven) {
                  sub['graceMarks'] = remainingMarksToPass
                  sub['graceMarksSymbol'] = examSettings.ordinance.graceMarksSymbol.symbols.all
                  sub.totalObtainedExternalMarks += remainingMarksToPass

                  sub.totalObtainedMarks = sub.totalObtainedExternalMarks + sub.totalObtainedInternalMarks
                  maxGraceMarksToBeGiven -= remainingMarksToPass
                }
              }
            }
          }
        }
      })
      return studentSubjects
    },
    calculateMarksFromPercentage(number, percentage) {
      return (percentage / 100) * number;
    },
    checkSemIsOdd() {
      let flag = true
      let index = null;
      this.lastSemDetails = []
      if (this.batchData.length > 0) {
        this.batchData.map((item) => {
          if (Array.isArray(item.batch) && item.batch.length > 0) {
            item.batch.map((batch) => {
              if (batch.courseYear === this.selectedCourseYear) {
                if (Array.isArray(batch.semesters) && batch.semesters.length > 0) {
                  batch.semesters.map((sem, i) => {

                    if (sem.semId === this.selectedSemester.semId) {
                      index = i
                      this.lastSemDetails = batch.semesters
                    }

                  })
                }
              }

            })
          }
        })
      }

      let arr1 = [1, 3, 5, 7, 9, 11]
      if (index !== null && arr1.includes(index)) {
        flag = false
      }
      return flag

    },
    containsOnlyNumbers(input) {
      return /^[0-9]+$/.test(input);
    },
    checkShortMarks(sub) {
      let obj = this.examSettings.ordinance.maxGraceMarksGivenPer
      let internal = this.examSettings.minimumCiePercentage ? this.examSettings.minimumCiePercentage : 40
      let external = this.examSettings.minimumSeePercentage ? this.examSettings.minimumSeePercentage : 40
      let finalObj = {
        subjectId: sub.subjectId,
        shortInternal: 0,
        shortExternal: 0,
        totalShort: 0,
        totalMarks: sub.totalMarks,
        canApplyOrdinance: true

      }
      let passingMarkInternal = 0
      let passingMarkExternal = 0
      let appliedMAxMarks = obj[sub.totalMarks]
      if (sub.totalInternalMarks !== 0) {
        passingMarkInternal = (sub.totalInternalMarks * internal / 100).toFixed(2)
      }
      if (sub.totalExternalMarks !== 0) {
        passingMarkExternal = (sub.totalExternalMarks * external / 100).toFixed(2)
      }
      if (passingMarkInternal > sub.totalObtainedInternalMarks) {
        finalObj.shortInternal = passingMarkInternal - sub.totalObtainedInternalMarks
      }
      if (passingMarkExternal > sub.totalObtainedExternalMarks) {
        finalObj.shortExternal = passingMarkExternal - sub.totalObtainedExternalMarks
      }
      finalObj.totalShort = finalObj.shortInternal + finalObj.shortExternal
      if (finalObj.totalShort > appliedMAxMarks) {
        finalObj.canApplyOrdinance = false
      }
      return finalObj
    },
    allocateGraceMarksOddSem(subjectMarks) {
      const studentSubjects = [...subjectMarks]
      let totalSemMarks = 0
      let totalObtainedMarks = 0
      let percetMarks = 0
      let failedSubjectsCount = 0
      let arrForfailedMarks = []
      if (Array.isArray(studentSubjects) && studentSubjects.length > 0) {
        studentSubjects.map((sub) => {
          totalSemMarks = totalSemMarks + Number(sub.totalMarks)
          if (sub.obtainedGrade === "F") {
            failedSubjectsCount++;
            let objOfmarksShort = this.checkShortMarks(sub)
            if (objOfmarksShort.canApplyOrdinance === true) {
              arrForfailedMarks.push(objOfmarksShort)
            }
          }
          if (this.containsOnlyNumbers(sub.totalObtainedMarks) === true) {
            totalObtainedMarks = totalObtainedMarks + Number(sub.totalObtainedMarks)
          }


        })


        percetMarks = Math.ceil(totalSemMarks / 100)
        if (percetMarks > 10) {
          percetMarks = 10
        }
        arrForfailedMarks = arrOps.sortByProperty(arrForfailedMarks, "shortExternal", 'desc')
        if (arrForfailedMarks.length > 0) {
          let marks;
          let maxMarksCanprovide = this.examSettings.ordinance.maxGraceMarksGivenPer
          arrForfailedMarks.map((obj, i) => {
            if (i === 0) {
              marks = percetMarks
            }
            if (i < 3 && marks > 0) {
              studentSubjects.map((value) => {
                if (value.subjectId === obj.subjectId) {
                  let maxMarks = maxMarksCanprovide[obj.totalMarks]

                  if (obj.shortInternal <= maxMarks && obj.shortExternal <= maxMarks) {
                    value['graceMarks'] = obj.totalShort
                    value['graceMarksSymbol'] = this.examSettings.ordinance.graceMarksSymbol.symbols.all
                    if (obj.shortInternal > 0) {
                      value.internalGraceMarks = obj.shortInternal
                      value.totalObtainedInternalMarks += obj.shortInternal
                    }
                    if (obj.shortExternal > 0) {
                      value.totalObtainedExternalMarks += obj.shortExternal
                      value.externalGraceMarks = obj.shortExternal
                    }

                  }
                  if (obj.shortInternal <= maxMarks && obj.shortExternal > maxMarks) {
                    value['graceMarks'] = obj.totalShort
                    value['graceMarksSymbol'] = this.examSettings.ordinance.graceMarksSymbol.symbols.all
                    value.totalObtainedInternalMarks += obj.shortInternal
                    value.internalGraceMarks = obj.shortInternal

                  }
                  if (obj.shortInternal > maxMarks && obj.shortExternal <= maxMarks) {
                    value['graceMarks'] = obj.totalShort
                    value['graceMarksSymbol'] = this.examSettings.ordinance.graceMarksSymbol.symbols.all
                    value.totalObtainedExternalMarks += obj.shortExternal
                    value.externalGraceMarks = obj.shortExternal
                  }
                  value.totalObtainedMarks = value.totalObtainedInternalMarks + value.totalObtainedExternalMarks
                  value.percentage = numOps.calculatePercentage(value.totalObtainedMarks, value.totalMarks)
                  marks = marks - obj.totalShort
                }
              })
            }

          })
        }
      }
      return studentSubjects
    },
    allocateGraceMarksEvenSem(subjectMarks, lastSemSub, stu) {
      let totalSemMarks = 0
      let totalObtainedMarks = 0
      let percetMarks = 0
      let failedSubjectsCountCurrent = 0
      let failedSubjectsCountLast = 0
      let arrForfailedMarks = []
      let selectedRule = this.examSettings.ordinance.specialRules.condonation[0].symbol

      let studentSubjects = [...subjectMarks]
      if (Array.isArray(studentSubjects) && studentSubjects.length > 0) {
        studentSubjects.map((sub) => {
          totalSemMarks = totalSemMarks + Number(sub.totalMarks)
          if (sub.obtainedGrade === "F") {
            failedSubjectsCountCurrent++;
          }
        })
      }
      if (Array.isArray(lastSemSub) && lastSemSub.length > 0) {
        lastSemSub.map((sub) => {
          totalSemMarks = totalSemMarks + Number(sub.totalMarks)
          if (sub.obtainedGrade === "F") {
            failedSubjectsCountLast++;
          }
        })
      }

      let failCount = Number(failedSubjectsCountLast) + Number(failedSubjectsCountCurrent)
      percetMarks = (totalSemMarks / 100).toFixed(2)
      if (percetMarks > 10) {
        percetMarks = 10
      }


      if (failCount === 1) {
        if (failedSubjectsCountLast === 1) {
          // last sem one subject fail apply condonation

          lastSemSub.map((lastSemSub) => {
            if (lastSemSub.obtainedGrade === "F") {
              let objOfmarksShort = this.checkShortMarks(lastSemSub)
              if (objOfmarksShort.totalShort <= percetMarks) {

                lastSemSub['graceMarks'] = objOfmarksShort.totalShort
                if (objOfmarksShort.shortInternal < percetMarks && objOfmarksShort.shortExternal < percetMarks) {
                  lastSemSub['graceMarksSymbol'] = selectedRule
                  lastSemSub.totalObtainedExternalMarks += objOfmarksShort.shortExternal
                  lastSemSub.totalObtainedInternalMarks += objOfmarksShort.shortInternal
                  lastSemSub.internalGraceMarks = objOfmarksShort.shortInternal
                  lastSemSub.externalGraceMarks = objOfmarksShort.shortExternal
                }
                if (objOfmarksShort.shortInternal <= percetMarks && objOfmarksShort.shortExternal > percetMarks) {
                  lastSemSub['graceMarksSymbol'] = selectedRule
                  lastSemSub.totalObtainedInternalMarks += objOfmarksShort.shortInternal
                  lastSemSub.internalGraceMarks = objOfmarksShort.shortInternal
                }
                if (objOfmarksShort.shortInternal > percetMarks && objOfmarksShort.shortExternal <= percetMarks) {
                  lastSemSub['graceMarksSymbol'] = selectedRule
                  lastSemSub.totalObtainedExternalMarks += objOfmarksShort.shortExternal
                  lastSemSub.externalGraceMarks = objOfmarksShort.shortExternal

                }
                lastSemSub.totalObtainedMarks = lastSemSub.totalObtainedInternalMarks + lastSemSub.totalObtainedExternalMarks
                lastSemSub.percentage = numOps.calculatePercentage(lastSemSub.totalObtainedMarks, lastSemSub.totalMarks)

              }


            }
          })
          let objForLastSemStu = {
            uId: stu.uId,
            department: stu.department,
            courseYear: stu.courseYear,
            instituteId: stu.instituteId,
            semId: this.lastSemDetails[0].semId ? this.lastSemDetails[0].semId : '',
            semesterSubjectMarks: lastSemSub
          }
          // const student = stu;
          let totalCreditPoints = 0
          let totalCredits = 0
          const recalculatedGrades = this.calculateGrades(objForLastSemStu.semesterSubjectMarks, 2)
          let passedSubjectCount = 0
          recalculatedGrades.subjectMarks.map((subject, i) => {
            if (subject.obtainedGrade !== ('F' || 'NP')) {
              passedSubjectCount++
            }
            totalCreditPoints += subject.creditPoints
            totalCredits += subject.obtainedCredits
          })
          let sgpa = this.calculateSGPA(passedSubjectCount, recalculatedGrades.subjectMarks.length, totalCreditPoints, totalCredits)
          objForLastSemStu['finalSgpa'] = sgpa
          this.lastSemResultUpdateStudent.push(objForLastSemStu)
        } else {
          this.flagForCurrentSemResult = true

          // current sem one subject fail check for both ordinance and condonation

          subjectMarks.map((lastSemSub) => {
            if (lastSemSub.obtainedGrade === "F") {
              let objOfmarksShort = this.checkShortMarks(lastSemSub)
              if (objOfmarksShort.totalShort <= percetMarks) {
                if (objOfmarksShort.canApplyOrdinance === true) {
                  studentSubjects = this.allocateGraceMarksOddSem(subjectMarks)

                }
                if (objOfmarksShort.canApplyOrdinance === false) {

                  lastSemSub['graceMarks'] = objOfmarksShort.totalShort
                  if (objOfmarksShort.shortInternal < percetMarks && objOfmarksShort.shortExternal < percetMarks) {
                    lastSemSub['graceMarksSymbol'] = selectedRule
                    lastSemSub.totalObtainedExternalMarks += objOfmarksShort.shortExternal
                    lastSemSub.totalObtainedInternalMarks += objOfmarksShort.shortInternal
                    lastSemSub.internalGraceMarks = objOfmarksShort.shortInternal
                    lastSemSub.externalGraceMarks = objOfmarksShort.shortExternal
                  }
                  if (objOfmarksShort.shortInternal <= percetMarks && objOfmarksShort.shortExternal > percetMarks) {
                    lastSemSub['graceMarksSymbol'] = selectedRule
                    lastSemSub.totalObtainedInternalMarks += objOfmarksShort.shortInternal
                    lastSemSub.internalGraceMarks = objOfmarksShort.shortInternal
                  }
                  if (objOfmarksShort.shortInternal > percetMarks && objOfmarksShort.shortExternal <= percetMarks) {
                    lastSemSub['graceMarksSymbol'] = selectedRule
                    lastSemSub.totalObtainedExternalMarks += objOfmarksShort.shortExternal
                    lastSemSub.externalGraceMarks = objOfmarksShort.shortExternal
                  }
                  lastSemSub.totalObtainedMarks = lastSemSub.totalObtainedInternalMarks + lastSemSub.totalObtainedExternalMarks
                  lastSemSub.percentage = numOps.calculatePercentage(lastSemSub.totalObtainedMarks, lastSemSub.totalMarks)

                }

              }
            }
          })
          studentSubjects = [...subjectMarks]
        }

      } else {
        //more than one subject fail 
        this.flagForCurrentSemResult = true

        let arr = this.allocateGraceMarksOddSem(studentSubjects)
        studentSubjects = [...arr]

      }
      return studentSubjects
    },
    async getBatches() {
      try {
        this.batchData = await this.nbaRepositoryInstance.getBatchesOfInstitute(
          {
            lmsInstituteId: this.selectedInstitute,
          }
        )
        let arr = this.batchData?.filter((e) => e.department === this.selectedDepartment)
        this.batchData = [...arr]
      } catch (err) {
        console.log('err', err);
      }
    },
    async updateLastSemUser() {
      try {
        await this.semesterUserRepositoryInstance.updateSemesterUserResultDetails(
          {
            userDetailsArray: this.lastSemResultUpdateStudent,
            systemPreference: this.examSettings.systemPreference,
          }
        );
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style src="./activityResults.css"></style>

<!--  {
      "totalInternalMarks": 50,
      "percentage": 87,
      "totalObtainedInternalMarks": 44,
      "totalExternalMarks": 50,
      "totalObtainedExternalMarks": 43,
      "totalObtainedMarks": 87,
      "totalMarks": 100,
      "subjectId": "e788a972-82d5-4fd5-9b87-106677551252",
      "examMarks": [
        {
          "examId": "4b7bf135-3cba-403b-b348-f92a09660032",
          "marks": 43
        },
        {
          "examId": "39ef7d64-bb76-4ad3-9187-93c83b9f5f91",
          "marks": 44
        }
      ],
      "obtainedCredits": 3,
      "obtainedGrade": "O",
      "gradePoints": 10,
      "creditPoints": 30
    } -->