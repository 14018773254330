<template src="./activityInstitutesBasicDetails.html"> </template>
<script>
import inputContainer from '../../Components/inputContainer'
import {
  instituteTypes,
  ownershipStatuses
} from '../../Constants/Utils/Statics'
import InstituteRepository from '../../Repository/Institute'
import arrayOps from '../../Services/Utils/arrayOps'
import showStatus from '../../NetworkManager/showStatus'

import auth from '../../Services/Firebase/auth'
import NetworkManager from '../../NetworkManager'
import InstituteAPIs from '../../ApiNames/Institute'

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

var firestorage = null

export default {
  name: 'activityInstitutesBasicDetails',
  props: ['creating'],
  components: {
    inputContainer,
  },
  data() {
    return {
      gstNumber: '',
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', '|',
            'bulletedList', 'numberedList',
            'outdent', 'indent', '|',
            'undo', 'redo',
          ],
          shouldNotGroupWhenFull: true
        }
      },
      emailEditorConfig: {
        toolbar: {
          items: [
            'heading', '|',
            'bold', 'italic', '|',
            'bulletedList', 'numberedList',
            'outdent', 'indent', '|',
            'undo', 'redo',
          ],
          shouldNotGroupWhenFull: true
        },
      },
      instituteShortCode: '',
      senderMail: '',
      isStudyMaterialDownloadable: false,
      senderMailPassword: '',
      displayName: '',
      subjectDescription: '',
      emailBody: '',
      razorPayNumber: '',
      razorpayAccessToken: '',
      razorpaySecrete: '',
      descriptionForPayment: '',
      loading: false,
      userData: {},
      instituteName: '',
      instituteAddress: '',
      TnC: '',
      universityName: '',
      universityAddress: '',
      phoneNumbers: [],
      emails: [],
      establishmentYear: '',
      instituteType: '',
      website: '',
      ownershipStatus: '',
      instituteTypes: instituteTypes,
      ownershipStatuses: ownershipStatuses,
      selectedInstitute: '',
      selectedSemester: '',
      addingPhoneNumber: false,
      addingEmail: false,
      tempEmail: '',
      tempPhoneNumber: '',
      adminLogo: [],
      facultyLogo: [],
      studentLogo: [],
      adminLogoName: '',
      studentLogoName: '',
      facultyLogoName: '',
      uploadTask: '',
      uploadTaskSign:'',
      signatureLogo:[],
      universityLogo:[],
      signatureLogoName: '',
      sendStudentEmail: false,
      sendFacultyEmail: false,
    }
  },

  created() {
    // firebase stuff
    const firebase = auth.getFirebaseObject()
    firestorage = firebase.storage()
    this.selectedInstitute = this.$store.getters[
      'instituteData/get_selectedInstitute'
    ]
    this.loading = true
    console.log(this.creating)
    this.instituteRepositoryInstance = new InstituteRepository(this)

    this.$store.commit(
      'liveData/set_selectedActivityName',
      'Institute Management'
    )
    this.userData = this.$store.getters['user/get_userData']
    this.loading = false
    this.fetchData()

    if (this.creating) {
    } else {
      this.selectedInstitute = this.$store.getters[
        'instituteData/get_selectedInstitute'
      ]
      console.log(this.selectedInstitute)

      this.fetchData()
      this.fetchBrandingDetails(this.selectedInstitute)
    }
  },

  watch: {
    uploadTask: function () {
      this.uploadTask.on(
        'state_changed',
        sp => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log({ downloadURL })
            this.updateAdminBrandingLogo(this.selectedInstitute, downloadURL)
          })
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log({ downloadURL })
            this.updateUniversityLogo(this.selectedInstitute, downloadURL)
          })
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log({ downloadURL })
            this.updateFacultyBrandingLogo(this.selectedInstitute, downloadURL)
          })
          this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log( "StudentBrandingLogo",downloadURL )
            this.updateStudentBrandingLogo(this.selectedInstitute, downloadURL)
          })
          // this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          //   console.log("download url", downloadURL)
          //   this.updateSignatureLogo(this.selectedInstitute, downloadURL)
          // })
        }
      )
    },
    uploadTaskSign: function () {
      this.uploadTaskSign.on(
        'state_changed',
        sp => {
          console.log(Math.floor((sp.bytesTransferred / sp.totalBytes) * 100))
        },
        null,
        () => {
          this.uploadTaskSign.snapshot.ref.getDownloadURL().then(downloadURL => {
            console.log("download url", downloadURL)
            this.updateSignatureLogo(this.selectedInstitute, downloadURL)
          })
        }
      )
    }
  },

  methods: {
    async onAdminLogoSelect(file) {
      await this.uploadAdminLogo(file)
    },

    async onFacultyLogoSelect(file) {
      await this.uploadFacultyLogo(file)
    },
    async onStudentLogoSelect(file) {
      await this.uploadStudentLogo(file)
    },
    async onSignatureLogoSelect(file) {
      await this.uploadSignatureLogo(file)
    },
    async onUniversityLogoSelect(file) {
      await this.uploadUniversityLogo(file)
    },

    async fetchBrandingDetails(instituteId) {
      try {
        const res = await NetworkManager.getRequest(
          InstituteAPIs.getInstituteBranding,
          { instituteId }
        )
        this.adminLogoName = res.instituteBrandingName
      } catch (error) {
        console.log({ error })
      }
    },

    async uploadAdminLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        // if (size > 300) {
        //   console.log('File size should be less than 300KB')
        //   return
        // }
        this.uploadTask = firestorage.ref('logo/' + file.name).put(file)
        this.adminLogo = file
      } else {
        this.adminLogo = "";
      }
    },

    async uploadFacultyLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        // if (size > 300) {
        //   console.log('File size should be less than 300KB')
        //   return
        // }
        this.uploadTask = firestorage.ref('logo/' + file.name).put(file)
        this.facultyLogo = file
      } else {
        this.facultyLogo = "";
      }
    },

    async uploadStudentLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        // if (size > 300) {
        //   console.log('File size should be less than 300KB')
        //   return
        // }
        this.uploadTask = firestorage.ref('logo/' + file.name).put(file)
        this.studentLogo = file
      } else {
        this.studentLogo = "";
      }
    },
    async uploadSignatureLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        // if (size > 300) {
        //   console.log('File size should be less than 300KB')
        //   return
        // }
        this.uploadTaskSign = firestorage.ref('logo/' + file.name).put(file)
        this.signatureLogo = file
      } else {
        this.signatureLogo = "";
      }
    },

    async uploadUniversityLogo(file) {
      if (file !== undefined && file !== null && file !== '') {
        const size = parseFloat(file.size / 1024)
        // if (size > 300) {
        //   console.log('File size should be less than 300KB')
        //   return
        // }
        const data = await firestorage.ref('logo/' + file.name).put(file)
        const url = await data.ref.getDownloadURL()
        this.universityLogo = file
        this.updateUniversityLogo(this.selectedInstitute, url)
      } else {
        this.universityLogo = [];
      }
    },

    async updateUniversityLogo(instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateUniversityLogo,
          {
            instituteId: instituteId,
            universityLogoLink: url
          }
        )
        console.log('>> university update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateAdminBrandingName() {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteAdminBrandingName,
          {
            instituteId: this.selectedInstitute,
            instituteBrandingName: this.adminLogoName
          }
        )
        console.log('>> admin branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateFacultyBrandingName() {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteFacultyBrandingName,
          {
            instituteId: this.selectedInstitute,
            instituteFacultyBrandingName: this.facultyLogoName
          }
        )
        console.log('>>  faculty branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateStudentBrandingName() {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteStudentBrandingName,
          {
            instituteId: this.selectedInstitute,
            instituteStudentBrandingName: this.studentLogoName
          }
        )
        console.log('>> student branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },
    async updateSignatureBrandingName() {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteSignatureBrandingName,
          {
            instituteId: this.selectedInstitute,
            updateInstituteSignatureBrandingName: this.signatureLogoName
          }
        )
        console.log('>> signature branding update name', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateAdminBrandingLogo(instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteAdminLogo,
          {
            instituteId: instituteId,
            instituteLogoLink: url
          }
        )
        console.log('>> admin branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateFacultyBrandingLogo(instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteFacultyLogo,
          {
            instituteId: instituteId,
            instituteFacultyLogoLink: url
          }
        )
        console.log('>> faculty branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async updateStudentBrandingLogo(instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteStudentLogo,
          {
            instituteId: instituteId,
            instituteStudentLogoLink: url
          }
        )
        console.log('>> student branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },
    async updateSignatureLogo(instituteId, url) {
      try {
        const res = await NetworkManager.putRequest(
          InstituteAPIs.updateInstituteSignatureLogo,
          {
            instituteId: instituteId,
            instituteSignatureLogoLink: url
          }
        )
        console.log('>> signature branding update logo', res.data)
      } catch (error) {
        console.log({ error })
      }
    },

    async fetchData() {
      this.loading = true
      const objToPush = {
        uId: this.userData.uId,
        instituteId: this.selectedInstitute
      }
      try {
        this.institute = await this.instituteRepositoryInstance.getInstituteInformation(
          objToPush
        )
        console.log('this.institute.universityLogoLink', this.institute.instituteLogoLink);
        this.instituteName = this.institute.instituteName
        this.instituteShortCode = this.institute.instituteShortCode
        this.instituteAddress = this.institute.instituteAddress
        this.universityName = this.institute.affiliatingUniversityName
        this.universityAddress = this.institute.affiliatingUniversityAddress
        this.phoneNumbers = this.institute.institutePhoneNumber.slice()
        this.emails = this.institute.instituteEmail.slice()
        this.establishmentYear = this.institute.establishmentYear
        this.instituteType = this.institute.institutionType
        this.ownershipStatus = this.institute.ownershipStatus
        this.adminLogo = this.institute.instituteLogoLink
        this.universityLogo = this.institute.universityLogoLink
        this.adminLogoName = this.institute.instituteBrandingName
        this.facultyLogo = this.institute.instituteFacultyLogoLink
        this.facultyLogoName = this.institute.instituteFacultyBrandingName
        this.studentLogo = this.institute.instituteStudentLogoLink
        this.studentLogoName = this.institute.instituteStudentBrandingName
        this.signatureLogo = this.institute.instituteSignatureLogoLink
        this.signatureLogoName = this.institute.instituteSignatureBrandingName
        this.razorPayNumber = this.institute.razorPayNumber
        this.razorpayAccessToken = this.institute.razorpayAccessToken
        this.razorpaySecrete = this.institute.razorpaySecrete
        this.descriptionForPayment = this.institute.descriptionForPayment
        this.isStudyMaterialDownloadable = this.institute.isStudyMaterialDownloadable
        this.senderMail = this.institute.senderMail
        this.senderMailPassword = this.institute.senderMailPassword
        this.displayName = this.institute.displayName
        this.gstNumber = this.institute.gstNumber
        this.TnC = this.institute.TnC
        this.emailBody = this.institute.emailBody
        this.subjectDescription = this.institute.subjectDescription
        this.sendStudentEmail = this.institute.sendStudentEmail ? this.institute.sendStudentEmail : false
        this.sendFacultyEmail = this.institute.sendFacultyEmail ? this.institute.sendFacultyEmail : false
        this.$store.commit("liveData/set_instituteStudentLogoLink", {
          instituteStudentLogoLink: this.institute.instituteStudentLogoLink
        });
        this.$store.commit("liveData/set_instituteStudentBrandingName", {
          instituteStudentBrandingName: this.institute.instituteStudentBrandingName
        });
        //
        this.$store.commit("liveData/set_subjectDescription", {
          subjectDescription: this.institute.subjectDescription
        });
        this.$store.commit("liveData/set_displayName", {
          displayName: this.institute.displayName
        });
        this.$store.commit("liveData/set_senderMailPassword", {
          senderMailPassword: this.institute.senderMailPassword
        });
        this.$store.commit("liveData/set_senderMail", {
          senderMail: this.institute.senderMail
        });
        this.$store.commit("liveData/set_emailBody", {
          emailBody: this.institute.emailBody
        });
        this.$store.commit("liveData/set_signatureLogo", {
          instituteSignatureLogoLink: this.institute.instituteSignatureLogoLink
        });
        this.$store.commit("liveData/set_signatureLogoName", {
          instituteSignatureBrandingName: this.institute.instituteSignatureBrandingName
        });
      } catch (err) {
        console.log(err)
      }
      console.log(`⭐ this.institute:`, this.institute)
      this.loading = false
    },

    async updateDetails() {
      this.loading = true
      if ((this.instituteName !== this.institute.instituteName && this.instituteName !== undefined && this.instituteName !== null && this.instituteName !== "") || (this.instituteShortCode !== this.institute.instituteShortCode && this.instituteShortCode !== undefined && this.instituteShortCode !== null && this.instituteShortCode !== "") || (this.isStudyMaterialDownloadable !== this.institute.isStudyMaterialDownloadable && this.isStudyMaterialDownloadable !== undefined && this.isStudyMaterialDownloadable !== null && this.isStudyMaterialDownloadable !== "")) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteName: this.instituteName,
          instituteShortCode: this.instituteShortCode,
          isStudyMaterialDownloadable: this.isStudyMaterialDownloadable,
        }
        try {
          this.instituteName = await this.instituteRepositoryInstance.updateInstituteName(
            objToPush
          )
          this.institute.instituteName = this.instituteName
        } catch (err) {
          showStatus('Could not update Institute Name.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.instituteAddress !== this.institute.instituteAddress) && this.instituteAddress !== undefined && this.instituteAddress !== null && this.instituteAddress !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteAddress: this.instituteAddress
        }
        try {
          this.instituteAddress = await this.instituteRepositoryInstance.updateInstituteAddress(
            objToPush
          )
          this.institute.instituteAddress = this.instituteAddress
        } catch (err) {
          // this.instituteAddress = this.institute.instituteAddress
          showStatus(
            'Could not update Institute Address.',
            1000,
            'error',
            this
          )
          console.log(err)
        }
      }
      if ((this.universityName !== this.institute.affiliatingUniversityName) && this.universityName !== undefined && this.universityName !== null && this.universityName !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          universityName: this.universityName
        }
        try {
          this.universityName = await this.instituteRepositoryInstance.updateAffliatingUniversityName(
            objToPush
          )
          this.institute.affiliatingUniversityName = this.universityName
        } catch (err) {
          // this.universityName = this.institute.affiliatingUniversityName
          showStatus('Could not update University Name.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.universityAddress !== this.institute.affiliatingUniversityAddress) && this.universityAddress !== undefined && this.universityAddress !== null && this.universityAddress !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          universityAddress: this.universityAddress
        }
        try {
          this.universityAddress = await this.instituteRepositoryInstance.updateAffliatingUniversityAddress(
            objToPush
          )
          this.institute.affiliatingUniversityAddress = this.universityAddress
        } catch (err) {
          // this.universityAddress = this.institute.affiliatingUniversityAddress
          showStatus(
            'Could not update University Address.',
            1000,
            'error',
            this
          )
          console.log(err)
        }
      }
      if (
        !arrayOps.compareArrays(
          this.phoneNumbers,
          this.institute.institutePhoneNumber
        )
      ) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          institutePhoneNumber: this.phoneNumbers
        }
        try {
          this.phoneNumbers = await this.instituteRepositoryInstance.updateInstitutePhoneNumber(
            objToPush
          )
          this.institute.institutePhoneNumber = this.phoneNumbers
        } catch (err) {
          // this.phoneNumbers = this.institute.institutePhoneNumber
          showStatus('Could not update phone numbers.', 1000, 'error', this)
          console.log(err)
        }
      }
      console.log(this.emails, this.institute.instituteEmail)
      if (!arrayOps.compareArrays(this.emails, this.institute.instituteEmail)) {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteEmail: this.emails,
        }
        try {
          this.emails = await this.instituteRepositoryInstance.updateInstituteEmail(
            objToPush
          )
          this.institute.instituteEmail = this.emails
        } catch (err) {
          // this.emails = this.institute.instituteEmail
          showStatus('Could not update emails.', 1000, 'error', this)
          console.log(err)
        }
      }
      if (this.establishmentYear !== this.institute.establishmentYear && this.establishmentYear !== undefined && this.establishmentYear !== null && this.establishmentYear !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          establishmentYear: this.establishmentYear
        }
        try {
          this.establishmentYear = await this.instituteRepositoryInstance.updateEstablishmentYear(
            objToPush
          )
          this.institute.establishmentYear = this.establishmentYear
        } catch (err) {
          // this.establishmentYear = this.institute.establishmentYear
          showStatus(
            'Could not update establishment year.',
            1000,
            'error',
            this
          )
          console.log(err)
        }
      }
      if ((this.instituteType !== this.institute.institutionType) && this.instituteType !== undefined && this.instituteType !== null && this.instituteType !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteType: this.instituteType
        }
        try {
          this.instituteType = await this.instituteRepositoryInstance.updateInstituteType(
            objToPush
          )
          this.institute.institutionType = this.instituteType
        } catch (err) {
          // this.instituteType = this.institute.institutionType
          showStatus('Could not update institute type.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.ownershipStatus !== this.institute.ownershipStatus) && this.ownershipStatus !== undefined && this.ownershipStatus !== null && this.ownershipStatus !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          ownershipStatus: this.ownershipStatus
        }
        try {
          this.ownershipStatus = await this.instituteRepositoryInstance.updateOwnershipStatus(
            objToPush
          )
          this.institute.ownershipStatus = this.ownershipStatus
        } catch (err) {
          // this.ownershipStatus = this.institute.ownershipStatus
          showStatus('Could not update ownership status.', 1000, 'error', this)
          console.log(err)
        }
      }
      if ((this.website !== this.institute.instituteWebsite) && this.website !== undefined && this.website !== null && this.website !== "") {
        const objToPush = {
          instituteId: this.selectedInstitute,
          instituteWebsite: this.website
        }
        try {
          this.website = await this.instituteRepositoryInstance.updateInstituteWebsite(
            objToPush
          )
          this.institute.instituteWebsite = this.website
        } catch (err) {
          // this.website = this.institute.instituteWebsite
          showStatus('Could not update website.', 1000, 'error', this)
          console.log(err)
        }
      }

      // if (this.sendStudentEmail || this.sendFacultyEmail || !this.sendStudentEmail || !this.sendFacultyEmail) {
      //   const objToPush = {
      //     instituteId: this.selectedInstitute,
      //     sendStudentEmail: this.sendStudentEmail,
      //     sendFacultyEmail: this.sendFacultyEmail
      //   }
      //   try {
      //     this.instituteName = await this.instituteRepositoryInstance.updateInstituteDetails(
      //       objToPush
      //     )
      //     this.institute.instituteName = this.instituteName
      //   } catch (err) {
      //     showStatus('Could not update Institute Details.', 1000, 'error', this)
      //     console.log(err)
      //   }
      // }

      let objToPush = {}

      objToPush = {
        instituteId: this.selectedInstitute,
        sendStudentEmail: this.sendStudentEmail,
        sendFacultyEmail: this.sendFacultyEmail
      }
      try {
        this.instituteName = await this.instituteRepositoryInstance.updateInstituteDetails(
          objToPush
        )
        this.institute.instituteName = this.instituteName
      } catch (err) {
        showStatus('Could not update Institute Details.', 1000, 'error', this)
        console.log(err)
      }

      objToPush = {
        instituteId: this.selectedInstitute,
        razorPayNumber: this.razorPayNumber,
        razorpayAccessToken: this.razorpayAccessToken,
        razorpaySecrete: this.razorpaySecrete,
        descriptionForPayment: this.descriptionForPayment,
        senderMail: this.senderMail,
        senderMailPassword: this.senderMailPassword,
        displayName: this.displayName,
        subjectDescription: this.subjectDescription,
        gstNumber: this.gstNumber,
        emailBody: this.emailBody,
        TnC: this.TnC,
      }
      try {
        this.website = await this.instituteRepositoryInstance.updateInstitutePaymentDetails(
          objToPush
        )
        this.institute.instituteWebsite = this.website
      } catch (err) {
        showStatus('Could not update payment details.', 1000, 'error', this)
        console.log(err)
      }

      if (this.adminLogoName.length > 0) {
        await this.updateAdminBrandingName()
      }
      if (this.facultyLogoName.length > 0) {
        await this.updateFacultyBrandingName()
      }
      if (this.studentLogoName.length > 0) {
        await this.updateStudentBrandingName()
      }
      if (this.signatureLogoName.length > 0) {
        await this.updateSignatureBrandingName()
      }
      if (this.adminLogo.length > 0) {
        await this.uploadAdminLogo()
      }
      if (this.facultyLogo.length > 0) {
        await this.uploadFacultyLogo()
      }
      if (this.studentLogo.length > 0) {
        await this.uploadStudentLogo()
      }
      if (this.signatureLogo.length > 0) {
        await this.uploadSignatureLogo()
      }
      showStatus('All Changes Updated Successfully.', 1000, 'success', this)
      this.loading = false
      this.fetchData()

    },

    revertChanges() {
      // this.website = this.institute.instituteWebsite
      // this.ownershipStatus = this.institute.ownershipStatus
      // this.instituteType = this.institute.institutionType
      // this.establishmentYear = this.institute.establishmentYear
      // this.emails = this.institute.instituteEmail
      // this.phoneNumbers = this.institute.institutePhoneNumber
      // this.universityAddress = this.institute.affiliatingUniversityAddress
      // this.universityName = this.institute.affiliatingUniversityName
      // this.instituteAddress = this.institute.instituteAddress
      // this.instituteName = this.institute.instituteName
      // this.instituteShortCode = this.institute.instituteShortCode
      this.fetchData()

    },
    createInstitute() { },
    removeFromArray(item, arr) {
      arrayOps.removeFromArray(arr, arr.indexOf(item))
    },
    addEmail() {
      this.emails.push(this.tempEmail)
      this.tempEmail = ''
      this.addingEmail = false
    },
    addPhoneNumber() {
      this.phoneNumbers.push(this.tempPhoneNumber)
      this.tempPhoneNumber = ''
      this.addingPhoneNumber = false
    },
    gotoactivityInstitutesManagment() {
      this.$router.push('/activityInstitutesManagment')
    },

  }
}
</script>
<style scoped src="./activityInstitutesBasicDetails.css">
</style>
