<template src='./activityExams1.html'></template>
<script>
import ExamRepository from "../../../Repository/Exam";
import SemesterRepository from "../../../Repository/Semester";
import inputContainer from "../../../Components/inputContainer";
import apiV3 from "../../../NetworkManager/apiV3";
import SubjectsRepository from "../../../Repository/Subject";
import showStatus from "../../../NetworkManager/showStatus";
import nbaRepository from "../../../Repository/NBA";
import UserRepository from "../../../Repository/User";
import AccreditationTag from "../../../Components/styleGuide/AccreditationTag";
import SemesterUserRepository from "../../../Repository/SemesterUser";
import ExamSettingsRepository from "../../../Repository/ExamSettings";
import InstituteRepository from "../../../Repository/Institute";
import NewExamRepository from "../../../Repository/NewExam";
import {
  timeStringConverter,
  formatToSlashSepearated,
  getDay,
} from "../../../Services/Utils/DateTimeFormatter";

import numOps from "../../../Services/Utils/numOps";
import axios from "axios";

export default {
  name: "activityExams1",
  components: {
    inputContainer,
    AccreditationTag,
  },
  data() {
    return {
      assignedCourseYearForSubAdmin: [],
      filteredExam: [],
      assignedDepartment: [],
      filteredSubjectId: "",
      filteredSubject: "",
      subjectList: [],
      allSubjectsForSemAndIns: [],
      loadingTable: false,
      search3: "",
      search2: "",
      search: "",
      draftExam: [],
      ongoingExam: [],
      finishedExam: [],
      subjectNamesArr: [],
      search: "",
      headersExamDraft: [
        {
          text: "",
          value: "isForNba",
          width: "5%",
        },
        {
          text: "Title",
          align: "start",
          width: "100px",
          value: "name",
        },
        {
          text: "Type of Exam",
          width: "100px",
          align: "start",
          value: "type",
        },
        {
          text: "Start date",
          width: "150px",
          align: "start",
          value: "startDate",
        },
        {
          text: "End date",
          width: "150px",
          align: "start",
          value: "endDate",
        },
        {
          text: "Department/Course year",
          width: "150px",
          align: "start",
          value: "dept",
        },
        {
          text: "Subjects",
          width: "200px",
          align: "start",
          value: "subjectName",
        },

        {
          text: "Action",
          width: "100px",
          value: "action",
        },
      ],
      headersExam: [
        {
          text: "",
          value: "examStatus",
          width: "30px",
          sortable: false,
        },
        {
          text: "",
          value: "isForNba",
          width: "70px",
          sortable: false,
        },
        {
          text: "Name",
          align: "start",
          width: "100px",
          value: "title",
        },
        {
          text: "Creation",
          width: "100px",
          value: "examCreationProgress",
        },
        // {
        //   text: "Exam Form",
        //   align: "start",
        //   width: "60px",
        //   value: "stepsDetails.examForm",
        //   sortable: false,
        // },
        // {
        //   text: "Invigilation",
        //   align: "start",
        //   width: "60px",
        //   value: "stepsDetails.rooms",
        //   sortable: false,
        // },
        // {
        //   text: "Timetable",
        //   align: "start",
        //   width: "60px",
        //   value: "timetable",
        //   sortable: false,
        // },
        // {
        //   text: "Start date",
        //   width: "150px",
        //   align: "start",
        //   value: "startDate",
        // },
        // {
        //   text: "End date",
        //   width: "150px",
        //   align: "start",
        //   value: "endDate",
        // },
        // {
        //   text: "Department/Course year",
        //   width: "150px",
        //   align: "start",
        //   value: "dept",
        // },
        {
          text: "Subjects",
          width: "200px",
          align: "start",
          value: "subjectName",
        },
        // {
        //   text: "Invigilator Dashboard/Hall Ticket",
        //   width: "100px",
        //   align: "start",
        //   value: "invigilatorDetails",
        // },
        {
          text: "Action",
          width: "100px",
          value: "action",
        },
      ],
      userData: null,
      exams: [],
      loading: true,
      semestersList: [],
      selectedSemesterName: "",
      selectedSemester: "",
      semesters: [],
      currentDate: "",
      subjectData: [],
      examsLoaded: false,
      deleteExamDialog: false,
      examToDelete: {},
      toggleExamDelete: false,
      examName: "",
      examForNba: false,
      examId: "",
      isForNBA: false,
      objToPush: {},
      reExams: [],
      search4: "",
      reExamAttempts: ["All", "2nd Attempt", "3rd Attempt", "4th Attempt"],
      selectedAttempt: "All",
      reExamDialog: false,
      considerForReExam: {},
      reExamLoader: false,
      filteredReExams: [],
      allSubjectData: [],
      sumOfObtainedMarksInAllExams: 0,
      passingMarks: 0,
      totalMarksOfAllExams: 0,
      publishResultDialog: false,
      selectedDepartmentToPublishResult: "",
      selectedCourseYearToPublishResult: "",
      allCourseYears: [],
      allDepartments: [],
      allSubjects: [],
      semesterStudents: [],
      filteredExamsForResult: [],
      examSettings: {},
      allStudentData: [],
      publishResultLoader: false,
      allSemesterDetails: [],
      disablePublishResultButton: true,
      studentResultTableHeader: [
        {
          text: "Student Name",
          value: "studentName",
        },
        {
          text: "Percentage",
          value: "percentage",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Attempt",
          value: "attempt",
        },
        {
          text: "No. of backlogs",
          value: "noOfBacklogs",
        },
      ],
      viewResultDialog: false,
      selectedDepartmentForResult: "",
      selectedCourseYearForResult: "",
      viewResultLoader: false,
      disableViewResultButton: true,
      studentDataLoader: false,
      studentResultData: [],
      hallTicketFormatPresent: false,
      examFormFormatPresent: false,
      currentSubjectToShow: {},
      showSubjectDetailsDialog: false,
      fullNameOfFaculties: [],
      selectedColumns: [],
      showExamProgressDialog: false,
      currentExamProgressDetails: {},
      currentExamProgress: 0,
      showExamTimeTableDialog: false,
      examTimetableHeaders: [
        {
          text: "Subject Name",
          value: "subjectName",
        },
        {
          text: "Subject Code",
          value: "subjectCode",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Day",
          value: "day",
        },
        {
          text: "Time",
          value: "startTime",
        },
      ],
      examTimetableItems: [],
      currentExamForNba: false,
      selectedExam: {},
      instituteName: "",
      instituteLogo: "",
      examTimetableDownloadLoader: false,
      examNameSearch: "",
      filters: [
        "date",
        "time",
        "subject",
        "date",
        "time",
        "subject",
        "date",
        "time",
        "subject",
        "date",
        "time",
        "subject",
        "date",
        "time",
        "subject",
      ],
      startDateFilterMenu: false,
      endDateFilterMenu: false,
      startDateFilter: "",
      endDateFilter: "",
      examFormStatuses: ["All", "Published", "Unpublished"],
      typesOfExam: ["All", "CIE(Internal)", "SEE(External)"],
      examStatuses: ["All", "Draft", "Published", "Ongoing"],
      hallTicketStatuses: ["All", "Published", "Unpublished"],
      resultStatuses: ["All", "Published", "Unpublished"],
      examAttempts: ["1st", "2nd", "3nd", "4th", "More than 4"],
      examFormStatusFilter: "",
      departmentFilter: "",
      courseYearFilter: "",
      subjectFilter: "",
      examStatusFilter: "",
      typeOfExamFilter: "",
      startDateFilter: "",
      endDateFilter: "",
      endDateFilter: "",
      hallTicketStatusFilter: "",
      resultStatusFilter: "",
      examAttemptFilter: "",
      departmentFilter: "",
      courseYearSubjects: [],
      subjectFilter: {},
      filteredExamsToShow: [],
      filteredExamsOperations: [],
      selectedDepartment: "",
      selectedCourseYear: "",
      finalExamsToConsiderForResult: [],
      questionExcelFile: [],
      disableAction: false
    };
  },
  async created() {
    this.assignedDepartment =
      this.$store.getters[
        "liveData/get_accessibleDepartment"
      ].accessibleDepartment;
    this.$store.commit("liveData/set_selectedActivityName", "Manage Exams");
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    this.assignedCourseYearForSubAdmin =
      this.$store.getters["liveData/get_accessibleCourseYearForSubAdmin"]
        ?.accessibleCourseYearForSubAdmin || [];
    const sem = this.$store.getters["instituteData/get_selectedExamSemester"];
    const selectedDepartment =
      this.$store.getters["liveData/get_examDepartment"];
    const selectedCourseYear =
      this.$store.getters["liveData/get_examCourseYear"];
    // const sem = this.$store.getters["instituteData/get_selectedExamSemester"];
    if (sem) {
      this.selectedSemester = sem;
      this.selectedSemesterName = sem.semName;
    }
    if (selectedDepartment) {
      this.selectedDepartment = selectedDepartment;
    }
    if (selectedCourseYear) {
      this.selectedCourseYear = selectedCourseYear;
    }
    this.userData = this.$store.getters["user/get_userData"];
    this.subjectsRepositoryInstance = new SubjectsRepository(this);
    this.examRepositoryInstance = new ExamRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.semesterRepositoryInstance = new SemesterRepository(this);
    this.nbaRepositoryInstance = new nbaRepository(this);
    this.semesterUserRepositoryInstance = new SemesterUserRepository(this);
    this.examSettingsRepositoryInstance = new ExamSettingsRepository(this);
    this.userRepositoryInstance = new UserRepository(this);
    this.InstituteRepositoryInstance = new InstituteRepository(this);

    this.exams = [];
    this.loading = false;
    await this.getInstituteInformation();
    this.getUsers();
    this.fetchData();
    this.getExamSettings();
    this.currentDate = new Date();
    this.selectedColumns = this.headersExam;
    //  = timestamp.toISOString().split("T")[0];
  },
  methods: {
    gotoExamAccessControl() {
      this.$router.push("/activityExamAccessControl");
    },
    goToSemesterResults() {
      this.$router.push("/activitySemesterResults");
    },
    closeViewResult() {
      this.viewResultDialog = false;
      this.selectedDepartmentForResult = "";
      this.selectedCourseYearForResult = "";
      this.studentResultData = [];
    },
    clearAllFilters() {
      this.filters = [];
    },
    async getExamSettings() {
      const result =
        await this.examSettingsRepositoryInstance.getInstituteExamSetting({
          instituteId: this.selectedInstitute,
        });
      this.examSettings = result.result[0];
    },
    cancelFilter(filter) {
      console.log("filter", filter);
    },
    // NICMAR RE-EXAM EXCEL UPLOAD FOR INTERNAL USE ONLY
    // handleNicmar() {
    //   if(this.questionExcelFile !== null) {
    //     var formData = new FormData();
    //     formData.append("excel", this.questionExcelFile);
    //     formData.append("instituteId", this.selectedInstitute);
    //     formData.append("semId", this.selectedSemester.semId);
    //     formData.append("department", this.selectedDepartment);
    //     formData.append("courseYear", this.selectedCourseYear);
    //     axios
    //       .post(
    //         process.env.VUE_APP_SERVER_URL +
    //           process.env.VUE_APP_API_PORT +
    //           "/excel/exam/parseNicmar",
    //         formData,
    //         {
    //           headers: {
    //             "Content-Type": "multipart/form-data",
    //           },
    //         }
    //       )
    //       .then((response) => {
    //         console.log('response', response);
    //       })
    //       .catch((error) => {
    //         console.log('error', error);
    //       });
    //   }
    // },
    clearTypeOfExam() {
      this.typeOfExamFilter = "";
    },
    filterExamsRoot() {
      const tempArr = [];

      this.filteredExamsOperations.map((exam) => {
        if (
          exam.type === this.typeOfExamFilter &&
          exam.criteria.department === this.departmentFilter &&
          exam.criteria.courseYear === this.courseYearFilter &&
          exam.applicableSubjects.includes(this.subjectFilter.subjectId)
        ) {
          tempArr.push(exam);
        }
      });
      // this.filteredExamsToShow = tempArr
    },
    clearCySubTy() {
      this.courseYearFilter = "";
      this.subjectFilter = {};
      this.typeOfExamFilter = "";
    },
    // filterExamsBasedOnType() {
    //   const tempArr = []
    //   this.filteredExams.map((exam) => {
    //     if(exam.type === this.typeOfExamFilter) {
    //       tempArr.push(exam)
    //     }
    //   })
    //   if(tempArr.length > 0) {
    //     this.filteredExams = tempArr
    //   }
    // },
    // filterExamsBasedOnSelections() {
    //   let tempArr = []
    //   if (this.subjectFilter.hasOwnProperty('subjectId')) {
    //     this.filteredExamsToShow.map((exam) => {
    //       if (exam.criteria.department === this.departmentFilter && exam.criteria.courseYear === this.courseYearFilter && exam.applicableSubjects.includes(this.subjectFilter.subjectId)) {
    //         tempArr.push(exam)
    //       }
    //     })
    //   }
    // },
    filterDataBySelect() {
      if (this.filteredSubject !== "") {
        this.examsLoaded = false;

        this.filteredExam = [];
        this.ongoingExam = [];
        this.draftExam = [];
        this.finishedExam = [];

        this.exams.map((exam) => {
          if (exam.subjectNames.includes(this.filteredSubject)) {
            this.filteredExam.push(exam);
          }
        });

        this.filteredExam.map((exam) => {
          if (
            exam.examStatus === "Published" ||
            exam.examStatus === "Ongoing"
          ) {
            this.ongoingExam.push(exam);
          } else if (exam.examStatus === "Draft") {
            this.draftExam.push(exam);
          } else if (
            this.currentDate > exam.endDate &&
            exam.examStatus === "Published"
          ) {
            this.finishedExam.push(exam);
          } else if (exam.reExam) {
            this.reExams.push(exam);
          }
        });
        if (this.reExams.length > 0) {
          this.reExams.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
        if (this.finishedExam.length > 0) {
          this.finishedExam.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
        if (this.ongoingExam.length > 0) {
          this.ongoingExam.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
        if (this.draftExam) {
          this.draftExam.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
        this.filterExamAttempts();
        this.examsLoaded = true;
      }
    },

    async filterData() {
      this.filteredSubject = "";
      try {
        let objForSubject = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        };
        this.allSubjectsForSemAndIns =
          await this.subjectsRepositoryInstance.getSubjectsOfAInstituteAndSem(
            objForSubject
          );
        this.allSubjectsForSemAndIns.map((sub) => {
          this.subjectList.push(sub.subjectName);
        });
      } catch (error) {
        console.error(error);
      }
    },
    filterExamAttempts() {
      let index = this.reExamAttempts.indexOf(this.selectedAttempt);

      if (index === 0) {
        this.filteredReExams = this.reExams;
      } else {
        this.filteredReExams = this.reExams.filter(
          (exam) => exam.attemptNumber === index + 1
        );
      }
      if (this.filteredReExams.length > 0) {
        this.filteredReExams.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      }
    },
    gotoInvigilatorDashboard(item, pageNumber) {
      this.$router.push({
        name: "activityInvigilatorDashboard",
        params: {
          PropExamInfo: item,
          prop_pageNumber: pageNumber,
        },
      });
    },
    dashboardOpen() {
      this.dialogDashboard = true;
    },
    async getInstituteInformation() {
      const data =
        await this.InstituteRepositoryInstance.getInstituteInformation({
          instituteId: this.selectedInstitute,
        });
      this.instituteName = data.instituteName;
      this.instituteLogo = data.instituteLogoLink;
      if (data.examFormFormat) {
        this.examFormFormatPresent = true;
      }
      if (data.hallTicketFormat) {
        this.hallTicketFormatPresent = true;
      }
    },
    async fetchData() {
      this.loadingTable = true;
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
        };
        this.semesters = await this.semesterRepositoryInstance.getSemesterNames(
          objToPush
        );
        this.semesters.reverse();
        this.semestersList = this.semesters.map((semester) => semester.semName);
        if (this.semestersList.length > 0) {
          if (!this.selectedSemester) {
            this.selectedSemesterName = this.semestersList[0];
            this.selectedSemester = this.semesters[0];
          }
        }
        // this.subjectNamesById = await this.subjectsRepositoryInstance.getSubjects(objToPush);

        this.$store.commit(
          "instituteData/set_selectedSemester",
          this.selectedSemester
        );
        this.$store.commit(
          "instituteData/set_selectedExamSemester",
          this.selectedSemester
        );
      } catch (err) {
        console.error(err);
        this.semesters = [];
        this.semestersList = [];
      }

      await this.getExams();
      await this.getSemesterDetails();
      await this.filterData();
      this.loadingTable = false;
    },
    reExamDialogFunc(exam) {
      this.reExamDialog = true;
      this.considerForReExam = exam;
    },
    async gotoReExam() {
      this.reExamLoader = true;
      let tempExam = {};
      tempExam = this.exams.filter(
        (item) =>
          item.examId === this.considerForReExam.examId &&
          this.considerForReExam.attemptNumber === item.attemptNumber
      )[0];
      let tempSubjectDetails = [];
      let tempQuestionDetails = [];
      tempExam.subjectDetails.map((subject) => {
        tempSubjectDetails.push({
          applicableStudents: subject.applicableStudents
            ? subject.applicableStudents
            : [],
          considerForPromotion: subject.considerForPromotion
            ? subject.considerForPromotion
            : false,
          duration: subject.duration,
          editSubjectDetails: subject.editSubjectDetails,
          facultyNames: subject.facultyNames ? subject.facultyNames : [],
          passingMarks: subject.passingMarks,
          selectedDate: null,
          selectedTime: null,
          subjectId: subject.subjectId,
          subjectName: subject.subjectName,
          totalMarks: subject.totalMarks,
          totalStudents: subject.totalStudents,
        });
      });
      let failedStudentSubjectCount = 0;
      tempExam.questionDetails.map((subject) => {
        const subjectToEval = tempExam.subjectDetails.filter(
          (sub) => sub.subjectId === subject.subjectId
        )[0];
        let passingMarks =
          (Number(subjectToEval.totalMarks) *
            Number(this.examSettings.overallPercentage)) /
          100;
        const failedStudentData = subject.studentData
          .filter(
            (student) => Number(student.obtainedMarks) < Number(passingMarks)
          )
          .map((e) => e.studentId);
        const tempSubject = tempSubjectDetails.filter(
          (item) => subject.subjectId === item.subjectId
        )[0];
        if (failedStudentData.length > 0) {
          failedStudentSubjectCount + 1;
          tempSubject.totalStudents = failedStudentData;
        } else {
          tempSubject.totalStudents = [];
        }
        if (!subject.uploadQuestions) {
          tempQuestionDetails.push({
            assignedBlooms: subject.assignedBlooms,
            assignedCo: subject.assignedCo,
            editQuestionDetails: subject.editQuestionDetails,
            overallThreshold: subject.overallThreshold,
            studentData: [],
            subjectId: subject.subjectId,
            subjectName: subject.subjectName,
            uploadMarksType: subject.uploadMarksType,
            uploadQuestions: subject.uploadQuestions,
          });
        } else {
          tempQuestionDetails.push({
            editQuestionDetails: subject.editQuestionDetails,
            marksHeader: subject.marksHeader,
            overallThreshold: subject.overallThreshold,
            questions: [],
            studentData: [],
            subjectId: subject.subjectId,
            subjectName: subject.subjectName,
            totalQuestions: 0,
            uploadMarksType: subject.uploadMarksType,
            uploadQuestions: subject.uploadQuestions,
          });
        }
      });
      let objToPush = {
        instituteId: tempExam.instituteId,
        semId: tempExam.semId,
        name: tempExam.name,
        description: "",
        type: tempExam.type,
        examId: tempExam.examId,
        startDate: null,
        endDate: null,
        criteria: tempExam.criteria,
        isForNBA: tempExam.isForNBA,
        applicableSubjects: tempExam.applicableSubjects,
        examStatus: "Draft",
        subjectDetails: tempSubjectDetails,
        questionDetails: tempQuestionDetails,
        reExam: true,
        attemptNumber: tempExam.attemptNumber + 1,
      };
      if (tempExam.isForNBA) {
        objToPush.nbaAttainment = tempExam.nbaAttainment;
      }
      try {
        await apiV3.postRequest("/manageExam/saveAsDraft", objToPush);
        showStatus(
          "Exam regenerated and saved as a draft.",
          5000,
          "success",
          this
        );
        this.fetchData();
      } catch (error) {
        console.error(error);
      }
      this.reExamLoader = false;
      this.reExamDialog = false;
    },
    checkForPromotion(sub) {
      if (sub.considerForPromotion) {
        return true;
      } else {
        return false;
      }
    },
    async getExams() {
      this.examsLoaded = false;
      this.ongoingExam = [];
      this.draftExam = [];
      this.finishedExam = [];
      this.reExams = [];
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: this.selectedDepartment,
          courseYear: this.selectedCourseYear,
        };
        let response = await this.newExamRepositoryInstance.getExams(objToPush);
        // let response = await apiV3.getRequest(
        //   "/manageExam/getAllExamDetails",
        //   objToPush
        // );
        this.exams = response;
        this.filteredExamsToShow = this.exams.filter(
          (exam) => exam.examType === "Exam" || exam?.addToCourseFile === true
        );
        this.filteredExamsOperations = this.exams;
        let subAdminAssignedDepartmentWiseLecture = [];
        if (this.assignedDepartment.length > 0) {
          this.exams?.map((data) => {
            if (this.assignedDepartment.includes(data.criteria.department)) {
              subAdminAssignedDepartmentWiseLecture.push(data);
            }
          });
          this.exams = subAdminAssignedDepartmentWiseLecture;
        }
        let allowedCourseYearForSelectedDepartment = [];
        if (this.assignedCourseYearForSubAdmin.length > 0) {
          this.assignedCourseYearForSubAdmin.map((cy) => {
            let [course, department1] = cy.split("_");
            this.exams.map((exam) => {
              if (
                exam.criteria.department === department1 &&
                exam.criteria.courseYear === course
              ) {
                allowedCourseYearForSelectedDepartment.push(exam);
              }
            });
          });
          this.exams = allowedCourseYearForSelectedDepartment;
        }
        let subjectIdArr = [];
        this.exams.map((item) => {
          item.applicableSubjects?.map((id) => {
            if (subjectIdArr.indexOf(id) === -1) {
              subjectIdArr.push(id);
            }
            // subjectIdArr.push(id);
          });
        });
        const obj = {
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          subjectIds: [...new Set(subjectIdArr)],
        };

        this.subjectNamesArr =
          await this.subjectsRepositoryInstance.getSubjects(obj);
        this.exams.map((item) => {
          let names = [];
          item.applicableSubjects.map(async (id) => {
            this.subjectNamesArr.map((data) => {
              if (id === data.subjectId) {
                let facultyNames = [];
                if (data.faculties) {
                  data.faculties.map((facUId) => {
                    let tempObj = this.fullNameOfFaculties.filter(
                      (item1) => item1.uId === facUId
                    )[0];
                    if (tempObj) {
                      facultyNames.push({
                        uId: facUId,
                        name: tempObj.fullName,
                      });
                    }
                  });
                  data.facultyNames = facultyNames;
                }
                names.push(data);
              }
            });
          });
          item.subjectNames = names;
        });

        // this.exams.map((item) => {
        //   item.timetable = [];
        //   let stepCount = 0;
        //   let totalSteps = 0;
        //   if (item.isForNBA) {
        //     totalSteps = 5;
        //   } else {
        //     totalSteps = 3;
        //   }
        //   item.stepsDetails = {
        //     examForm: false,
        //     rooms: false,
        //     questions: false,
        //     studentMarks: false,
        //     cos: false,
        //     blooms: false,
        //     grouping: false,
        //   };
        //   item.examCreationProgress = 0;
        //   item.InvigilatorDashboard = false;
        //   item.subjectDetails.map((subject) => {
        //     if (subject.roomAllocation) {
        //       if (subject.roomAllocation.length > 0) {
        //         item.InvigilatorDashboard = true;
        //       }
        //     }
        //   });
        //   if (
        //     (item.examStatus === "Published" &&
        //       this.currentDate <= new Date(item.endDate) &&
        //       this.currentDate >= new Date(item.startDate)) ||
        //     this.currentDate <= new Date(item.startDate)
        //   ) {
        //     item.examStatus = "Ongoing";
        //   }
        //   if (
        //     this.currentDate > new Date(item.endDate) &&
        //     item.examStatus === "Published"
        //   ) {
        //     item.examStatus = "Finished";
        //   }
        //   if (item.examFormPublished) {
        //     // stepCount++;
        //     item.stepsDetails.examForm = true;
        //   }

        //   if (item.subjectDetails) {
        //     const subjectCount = item.subjectDetails.length;
        //     let tempInvigilationCount = 0;
        //     let tempGroupingCount = 0;

        //     for (let i = 0; i < subjectCount; i++) {
        //       let timetableObj = {};
        //       const subject = item.subjectDetails[i];
        //       if (subject.roomStatus) {
        //         tempInvigilationCount++;
        //       }
        //       if (subject.considerForPromotion) {
        //         tempGroupingCount++;
        //       }
        //       timetableObj.startTime = subject.selectedTime
        //         ? timeStringConverter(subject.selectedTime)
        //         : "-";
        //       timetableObj.date = subject.selectedDate
        //         ? formatToSlashSepearated(subject.selectedDate)
        //         : "-";
        //       timetableObj.day = subject.selectedDate
        //         ? getDay(formatToSlashSepearated(subject.selectedDate))
        //         : "-";
        //       timetableObj.subjectName = subject.subjectName;
        //       timetableObj.subjectCode = subject.subjectCode;
        //       item.timetable.push(timetableObj);
        //     }
        //     if (subjectCount === tempInvigilationCount) {
        //       // stepCount++;
        //       item.stepsDetails.rooms = true;
        //     }
        //     if (subjectCount === tempGroupingCount) {
        //       stepCount++;
        //       item.stepsDetails.grouping = true;
        //     }
        //   }
        //   if (item.questionDetails) {
        //     let questionUploadCount = 0;
        //     let marksUploadCount = 0;
        //     let tempCoMappingCount = 0;
        //     let tempBloomsMappingCount = 0;
        //     for (let i = 0; i < item.questionDetails.length; i++) {
        //       const element = item.questionDetails[i];
        //       if (element.uploadQuestions !== undefined) {
        //         if (element.uploadQuestions === false) {
        //           questionUploadCount++;
        //           if (item.isForNBA) {
        //             if (element.assignedCo.length > 0) {
        //               tempCoMappingCount++;
        //             }
        //             if (element.assignedBlooms.length > 0) {
        //               tempBloomsMappingCount++;
        //             }
        //           }
        //         } else {
        //           if (element.uploadQuestions === true) {
        //             if (element.questions.length > 0) {
        //               const isCoMappedForQuestion = element.questions.every(
        //                 this.checkCoMapping
        //               );
        //               const isBloomsMappedForQuestion = element.questions.every(
        //                 this.checkCoMapping
        //               );

        //               if (isCoMappedForQuestion === element.questions.length) {
        //                 tempCoMappingCount++;
        //               }
        //               if (
        //                 isBloomsMappedForQuestion === element.questions.length
        //               ) {
        //                 tempBloomsMappingCount++;
        //               }
        //             }
        //           }
        //         }
        //       }
        //       if (element.uploadMarksType) {
        //         marksUploadCount++;
        //       }
        //     }
        //     if (marksUploadCount === item.questionDetails.length) {
        //       stepCount++;
        //       item.stepsDetails.studentMarks = true;
        //     }
        //     if (questionUploadCount === item.questionDetails.length) {
        //       stepCount++;
        //       item.stepsDetails.questions = true;
        //     }

        //     if (item.isForNBA) {
        //       if (tempCoMappingCount === item.questionDetails.length) {
        //         stepCount++;
        //         item.stepsDetails.cos = true;
        //       }
        //       if (tempBloomsMappingCount === item.questionDetails.length) {
        //         stepCount++;
        //         item.stepsDetails.blooms = true;
        //       }
        //     }
        //   }
        //   let percentage = this.calculatePercentage(stepCount, totalSteps);
        //   item.examCreationProgress = percentage;
        // });

        // this.exams.map((exam) => {
        //   exam.InvigilatorDashboard = false;
        //   exam.subjectDetails.map((subject) => {
        //     if (subject.roomAllocation) {
        //       if (subject.roomAllocation.length > 0) {
        //         exam.InvigilatorDashboard = true;
        //       }
        //     }
        //   });
        //   if (
        //     (exam.examStatus === "Published" &&
        //       this.currentDate <= new Date(exam.endDate) &&
        //       this.currentDate >= new Date(exam.startDate)) ||
        //     this.currentDate <= new Date(exam.startDate)
        //   ) {
        //     this.ongoingExam.push(exam);
        //   } else if (
        //     exam.reExam &&
        //     (exam.examStatus === "Published" || exam.examStatus === "Draft")
        //   ) {
        //     this.reExams.push(exam);
        //   } else if (exam.examStatus === "Draft") {
        //     this.draftExam.push(exam);
        //   } else if (
        //     this.currentDate > new Date(exam.endDate) &&
        //     exam.examStatus === "Published"
        //   ) {
        //     this.finishedExam.push(exam);
        //   } else {
        //     console.log("exam else", exam);
        //   }
        // });

        if (this.exams.length > 0) {
          this.exams.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
        }
        this.filterExamAttempts();

        this.examsLoaded = true;
      } catch (err) {
        console.error(err);
        this.exams = [];
        this.examsLoaded = true;
      }
    },
    checkCoMapping(que) {
      return que.COArray.length > 0 ? true : false;
    },
    checkBloomsMapping() {
      return que.bloomArray.length > 0 ? true : false;
    },
    showExamTimetable(item) {
      this.showExamTimeTableDialog = true;
      this.examTimetableItems = item.timetable;
      this.selectedExam = item;
    },
    showExamCreationProgress(progress, details, isForNBA) {
      this.currentExamProgressDetails = details;
      this.currentExamProgress = progress;
      this.currentExamForNba = isForNBA;
      this.showExamProgressDialog = true;
    },
    calculatePercentage(partialValue, totalValue) {
      return this.roundOffUptoTwoDecimals((100 * partialValue) / totalValue);
    },
    async downloadExamTimetable(tableItems, tableHeaders) {
      this.examTimetableDownloadLoader = true;
      let examTimeTableDetails = {
        examName: this.selectedExam.name,
        instituteName: this.instituteName,
        instituteLogo: this.instituteLogo,
        department: this.selectedExam.criteria.department,
        courseYear: this.selectedExam.criteria.courseYear,
      };
      let objToPush = {
        examTimeTableDetails,
        tableItems,
        tableHeaders,
      };
      const result = await apiV3.postRequest(
        "/manageExam/generateExamTimetable",
        objToPush
      );
      if (result.data.data) {
        this.downloadFileFromBuffer(
          result.data.data,
          `${this.selectedExam.name}_${this.selectedExam.criteria.department}_${this.selectedExam.criteria.courseYear}_ExamTimetable`
        );
      }
      this.examTimetableDownloadLoader = false;
    },
    downloadFileFromBuffer(resultByte, fileName) {
      var bytes = new Uint8Array(resultByte); // pass your byte response to this constructor

      var blob = new Blob([bytes], { type: "application/pdf" }); // change resultByte to bytes

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.pdf`;
      link.click();
      this.examFormLoader = false;
    },
    async getUsers() {
      const data =
        await this.semesterUserRepositoryInstance.getFacultiesOfAInstitute({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        });
      const facultyUIds = data.map((item) => item.uId);
      this.fullNameOfFaculties =
        await this.userRepositoryInstance.getFullNameOfUsers({
          uIds: facultyUIds,
        });
    },
    handleSubjectClick(item) {
      this.currentSubjectToShow = item;
      this.showSubjectDetailsDialog = true;
    },
    // addMinutesToTime(time, minutesToAdd) {
    //   // Split the time into hours and minutes
    //   const [hours, minutes] = time.split(":").map(Number);

    //   // Create a Date object for the initial time
    //   const initialTime = new Date(0, 0, 0, hours, minutes);

    //   // Add the specified minutes
    //   initialTime.setMinutes(initialTime.getMinutes() + minutesToAdd);

    //   // Extract the new hours and minutes
    //   const newHours = initialTime.getHours();
    //   const newMinutes = initialTime.getMinutes();

    //   // Format the result time as a string
    //   const resultTime = `${String(newHours).padStart(2, "0")}:${String(
    //     newMinutes
    //   ).padStart(2, "0")}`;

    //   return resultTime;
    // },
    gotoExamSettings(item, pageNumber) {
      if (!this.examFormFormatPresent) {
        this.$router.push({
          name: "activityExamSettings",
          params: { prop_pageNumber: pageNumber },
        });
      } else {
        this.gotoEditExam(item, 1);
      }
    },
    selectSemester() {
      this.selectedDepartment = ''
      this.selectedCourseYear = ''
      this.filteredExamsToShow = []
      this.selectedSemester = this.semesters.find(
        (semester) => semester.semName === this.selectedSemesterName
      );
      this.$store.dispatch(
        "instituteData/set_selectedSemester",
        this.selectedSemester
      );
      this.$store.dispatch(
        "instituteData/set_selectedExamSemester",
        this.selectedSemester
      );
      // this.getExams();
      this.getSemesterDetails();
    },
    selectDepartment() {
      this.selectedCourseYear = ''
      this.filteredExamsToShow = []
      this.$store.commit(
        "liveData/set_examDepartment",
        this.selectedDepartment
      );
    },
    selectCourseYear() {
      this.filteredExamsToShow = []
      this.$store.commit(
        "liveData/set_examCourseYear",
        this.selectedCourseYear
      );
      this.getExams();
    },
    gotoCreateExam() {
      this.$router.push("/activityManageExamsRevamped");
      this.$store.commit("liveData/set_examId", null);
    },
    gotoEditExam(item, pageNumber) {
      this.$store.commit("liveData/set_examId", item.examId);
      this.$router.push({
        name: "activityManageExamsRevamped",
      });
    },
    gotoExamAnalysis(item) {
      this.$store.commit("liveData/set_selectedExam", item);
      this.$router.push("/activityExamAnalysis");
    },
    gotoExamDashboard(item) {
      this.$store.commit("liveData/set_selectedExam", item);
      this.$router.push("/activityExamDashboard");
    },
    gotoExamResults(item) {
      this.$store.commit("liveData/set_selectedExam", item);
      this.$router.push("/activityExamUploadResult");
    },
    getSubjectName(subjectId) {
      let name = "";
      this.subjectData.map((subject) => {
        subjectId === subject.subjectId ? (name = subject.subjectName) : null;
      });
      return name;
    },
    deleteExamDialogFunc(exam) {
      this.examToDelete = {};
      this.deleteExamDialog = true;
      this.examToDelete = {
        examId: exam.examId,
        questionDetails: exam.questionDetails,
        subjectDetails: exam.subjectDetails,
        _id: exam._id,
        attemptNumber: exam.attemptNumber,
        instituteId: exam.instituteId,
        semId: exam.semId,
      };
      this.examCourseYear = exam.criteria.courseYear;
      this.examForNba = exam.isForNBA;
      this.examName = exam.name;
    },
    async deleteExam() {
      this.toggleExamDelete = true;
      this.objToPush = {
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.selectedSemester.semId,
        courseYear: this.examCourseYear,
        examId: this.examToDelete.examId,
        type: this.examToDelete.type,
      };
      try {
        // await apiV3.postRequest("/manageExam/delete", this.examToDelete);
        // showStatus("Exam Deleted Successfully.", 5000, "success", this);
        const status = await this.newExamRepositoryInstance.deleteExam({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          examId: this.examToDelete.examId,
        });
        this.deleteExamDialog = false;
        // if (this.examForNba) {
        //   this.deleteFromLms();
        // }
        await this.getExams();
        this.toggleExamDelete = false;
      } catch (error) {
        this.toggleExamDelete = false;
        this.deleteExamDialog = false;
        showStatus(
          "Cannot delete exam. Please try again later.",
          5000,
          "error",
          this
        );
      }
    },
    async deleteFromLms() {
      try {
        await this.nbaRepositoryInstance.deleteExamLms(this.objToPush);
      } catch (error) {
        console.error("error", error);
      }
    },
    handlePublishResultDialog() {
      this.publishResultDialog = true;
    },
    async getSemesterDetails() {
      this.allCourseYears = [];
      this.allDepartments = [];
      this.allSemesterDetails =
        await this.semesterRepositoryInstance.getSemestersBySemId({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
        });
      for (let index = 0; index < this.allSemesterDetails.length; index++) {
        const element = this.allSemesterDetails[index];

        this.allCourseYears.push(element.courseYear);
        this.allDepartments.push(element.department);
      }
    },
    async handleCourseYear() {
      this.publishResultLoader = true;
      this.disablePublishResultButton = true;
      // await this.getCourseYearSubjects();
      await this.getSemesterUsers(
        this.selectedDepartmentToPublishResult,
        this.selectedCourseYearToPublishResult
      );
      this.disablePublishResultButton = false;
      this.publishResultLoader = false;
      // this.calculateResultsOfStudents();
    },
    async getSemesterUsers(department, courseYear) {
      this.semesterStudents =
        await this.semesterUserRepositoryInstance.getStudentsOfACourseYear({
          instituteId: this.selectedInstitute,
          semId: this.selectedSemester.semId,
          department: department,
          courseYear: courseYear,
        });
    },
    async getCourseYearSubjects() {
      this.courseYearSubjects =
        await this.subjectsRepositoryInstance.getSubjectsOfACourseYearInASemester(
          {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.departmentFilter,
            courseYear: this.courseYearFilter,
          }
        );
      this.subjectFilter = {};
      this.typeOfExamFilter = "";
    },
    checkSubjectMarks() {
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        let objToPush = {};
        objToPush["totalCredits"] = element.credits;
        objToPush["subjectId"] = element.subjectId;
        objToPush["subjectName"] = element.subjectName;
        if (this.examSettings.selectedCieSeePreference === "no") {
          objToPush["totalMarks"] = element.totalMarks;
        } else {
          objToPush["totalCieMarks"] = element.totalCieMarks;
          objToPush["totalSeeMarks"] = element.totalSeeMarks;
        }
        this.allSubjects.push(objToPush);
      }
    },
    filterExams() {
      const data = this.allSubjectsForSemAndIns.filter(
        (item) =>
          item.department === this.selectedDepartmentToPublishResult &&
          item.courseYear === this.selectedCourseYearToPublishResult
      );
      let uniqueIdsSet = new Set();

      // Iterate over each object in the array
      data.forEach((item) => {
        // Iterate over each groupedExams id in the current object
        item.groupedExams.forEach((id) => {
          // Add the id to the uniqueIdsSet
          uniqueIdsSet.add(id);
        });
      });

      // Convert the set to an array (if needed)
      let uniqueIdsArray = Array.from(uniqueIdsSet);
      this.finalExamsToConsiderForResult = this.filteredExamsToShow.filter(
        (exam) => uniqueIdsArray.includes(exam.examId)
      );
      this.calculateStudentsResult(data);
    },
    calculateStudentsResult(subjects) {
      let obj = {
        graceMarksSymbol: "",
        obtainedCredits: 1,
        obtainedGrade: "A",
        obtainedGradePoints: 9,
        obtainedMarks: 82,
        subjectGradePoints: 9,
        subjectId: "6ce90aed-cf94-480b-adf3-19a1c13e105d",
        totalCieMarks: 100,
        totalCieObtainedGraceMarks: 0,
        totalCieObtainedMarks: 82,
        totalMarks: 100,
        totalObtainedGraceMarks: 0,
        totalSeeMarks: 0,
        totalSeeObtainedGraceMarks: 0,
        totalSeeObtainedMarks: 0,
      };
      for (let si = 0; si < this.semesterStudents.length; si++) {
        const currentStudent = this.semesterStudents[si];

        let subjectMarks = [];
        for (let subIn = 0; subIn < subjects.length; subIn++) {
          const currentSubject = subjects[subIn];
          if (currentStudent.subjects.includes(currentSubject.subjectId)) {
            // calculate if the subject has grouped exams
            if (currentSubject.groupedExams.length > 0) {
              const result = this.calculateMarksOfASubject(
                currentStudent.uId,
                currentSubject.subjectId
              );
              if (result.totalMarks > 0) {
                subjectMarks.push(result);
              }
            }
          }
        }
        if (subjectMarks.length > 0) {
          if (this.examSettings.systemPreference === "cbs") {
            if (this.examSettings.gradingSystem === "absolute") {
              let totalCreditPoints = 0;
              let totalCredits = 0;
              let passedSubjectCount = 0;
              let subjectDetailsArray = [];
              subjectMarks.map((subject) => {
                let percentage = numOps.calculatePercentage(
                  subject.totalObtainedMarks,
                  subject.totalMarks
                );
                let internalPercentage = numOps.calculatePercentage(
                  subject.totalObtainedInternalMarks,
                  subject.totalInternalMarks
                );
                let externalPercentage = numOps.calculatePercentage(
                  subject.totalObtainedExternalMarks,
                  subject.totalExternalMarks
                );

                let subjectCredits = subjects.filter(
                  (sub) => sub.subjectId === subject.subjectId
                )[0].credits;
                if (this.examSettings.selectedCieSeePreference === "yes") {
                  if (
                    internalPercentage >=
                      this.examSettings.minimumCiePercentage &&
                    externalPercentage >= this.examSettings.minimumSeePercentage
                  ) {
                    if (
                      percentage >= this.examSettings.lowestPercentageOfPassing
                    ) {
                      const { gradeLetter, gradePoint } = this.assignGrade(
                        subject.totalObtainedMarks
                      );
                      if (gradeLetter !== ("" || "F")) {
                        passedSubjectCount++;
                      }
                      subject["obtainedCredits"] = subjectCredits;
                      subject["obtainedGrade"] = gradeLetter;
                      subject["gradePoints"] = gradePoint;
                      subject["creditPoints"] = subjectCredits * gradePoint;
                    } else {
                      subject["obtainedCredits"] = 0;
                      subject["obtainedGrade"] = "F";
                      subject["gradePoints"] = 0;
                      subject["creditPoints"] = 0;
                    }
                  } else {
                    subject["obtainedCredits"] = 0;
                    subject["obtainedGrade"] = "F";
                    subject["gradePoints"] = 0;
                    subject["creditPoints"] = 0;
                  }
                } else {
                  if (
                    percentage >= this.examSettings.lowestPercentageOfPassing
                  ) {
                    const { gradeLetter, gradePoint } = this.assignGrade(
                      subject.totalObtainedMarks
                    );
                    if (gradeLetter !== ("" || "F")) {
                      passedSubjectCount++;
                    }
                    subject["obtainedGrade"] = gradeLetter;
                    subject["obtainedCredits"] = subjectCredits;
                    subject["gradePoints"] = gradePoint;
                    subject["creditPoints"] = subjectCredits * gradePoint;
                  } else {
                    subject["obtainedCredits"] = 0;
                    subject["obtainedGrade"] = "F";
                    subject["gradePoints"] = 0;
                    subject["creditPoints"] = 0;
                  }
                }

                totalCreditPoints += subject.creditPoints;
                totalCredits += subject.obtainedCredits;
                subjectDetailsArray.push(subject);
              });
              currentStudent.semesterSubjectMarks = subjectDetailsArray;
              let sgpa = this.calculateSGPA(
                passedSubjectCount,
                currentStudent.subjects.length,
                totalCreditPoints,
                totalCredits
              );
              currentStudent["finalSgpa"] = sgpa;
            }
          }
        }
      }
    },
    assignGrade(marks) {
      let gradeLetter = "";
      let gradePoint = 0;
      let tempGrade = this.examSettings.gradeDetails.filter(
        (e) =>
          marks <= Number(e.gradeRange.from) && marks > Number(e.gradeRange.to)
      )[0];
      if (tempGrade) {
        gradeLetter = tempGrade.gradeName;
        gradePoint = Number(tempGrade.gradePoints);
      }
      return { gradeLetter, gradePoint };
    },
    calculateMarksOfASubject(studentId, subjectId) {
      let totalMarks = 0;
      let totalObtainedMarks = 0;
      let totalInternalMarks = 0;
      let totalExternalMarks = 0;
      let totalObtainedInternalMarks = 0;
      let totalObtainedExternalMarks = 0;
      let examMarks = [];

      for (
        let index = 0;
        index < this.finalExamsToConsiderForResult.length;
        index++
      ) {
        const currentExam = this.finalExamsToConsiderForResult[index];

        currentExam.questionDetails.map((subject) => {
          if (subject.subjectId === subjectId) {
            let currentStudent = subject.examMarks.filter(
              (stud) => stud.uId === studentId
            )[0];
            if (currentStudent) {
              let objToPush = {
                examId: currentExam.examId,
                marks: currentStudent.totalMarksObtained,
              };

              totalMarks += subject.totalMarks;
              totalObtainedMarks += Number(currentStudent.totalMarksObtained);

              if (currentExam.examFormat === "Formative") {
                totalInternalMarks += subject.totalMarks;
                totalObtainedInternalMarks += Number(
                  currentStudent.totalMarksObtained
                );
              } else {
                totalExternalMarks += subject.totalMarks;
                totalObtainedExternalMarks += Number(
                  currentStudent.totalMarksObtained
                );
              }
              examMarks.push(objToPush);
            }
          }
        });
      }

      // let percentage = numOps.calculatePercentage(totalObtainedMarks, totalMarks)
      // let internalPercentage = numOps.calculatePercentage(totalObtainedInternalMarks, totalInternalMarks)
      // let externalPercentage = numOps.calculatePercentage(totalObtainedExternalMarks, totalExternalMarks)
      return {
        totalInternalMarks,
        totalObtainedInternalMarks,
        totalExternalMarks,
        totalObtainedExternalMarks,
        totalObtainedMarks,
        totalMarks,
        subjectId,
        examMarks,
      };
    },
    calculateIndividualExamResult(exam, currentSubject, currentStudent) {
      let totalMarks = 0;
      let totalObtainedMarks = 0;
      let totalCieMarks = 0;
      let totalCieObtainedMarks = 0;
      let totalSeeMarks = 0;
      let totalSeeObtainedMarks = 0;
      if (exam.examId) {
        if (
          Array.isArray(exam.questionDetails) &&
          exam.questionDetails.length > 0 &&
          exam.examStatus === "Published"
        ) {
          if (exam.applicableSubjects.includes(currentSubject.subjectId)) {
            exam.questionDetails.map((subject) => {
              if (subject.subjectId === currentSubject.subjectId) {
                let student;
                const [subjectDetail] = exam.subjectDetails.filter(
                  (e) => e.subjectId === currentSubject.subjectId
                );
                if (subject.studentData) {
                  student = subject.studentData.filter(
                    (e) => e.studentId === currentStudent.uId
                  )[0];
                  if (student) {
                    if (
                      student.obtainedMarks !== "" &&
                      student.obtainedMarks !== "NA" &&
                      student.obtainedMarks
                    ) {
                      totalObtainedMarks += Number(student.obtainedMarks);

                      if (exam.type === "SEE(External)") {
                        totalSeeMarks += Number(subjectDetail.totalMarks);
                        totalSeeObtainedMarks += Number(student.obtainedMarks);
                      } else if (exam.type === "CIE(Internal)") {
                        totalCieMarks += Number(subjectDetail.totalMarks);
                        totalCieObtainedMarks += Number(student.obtainedMarks);
                      }
                    }
                  }

                  if (subjectDetail) {
                    if (subjectDetail.totalMarks) {
                      totalMarks += Number(subjectDetail.totalMarks);
                    }
                  }
                }
              }
            });
          }
        }
      } else {
        if (currentSubject.subjectId === exam.subjectId) {
          const submittedAssignment = this.allSubmittedAssignments.filter(
            (item) => item.assignmentId === exam.assignmentId
          )[0];
          totalMarks += Number(exam.totalObtainableMarks);
          if (submittedAssignment && submittedAssignment.marksObtained) {
            totalObtainedMarks += Number(submittedAssignment.marksObtained);

            totalCieMarks += Number(subjectDetail.totalMarks);
            totalCieObtainedMarks += Number(subjectDetail.obtainedMarks);
          }
        }
      }
      return {
        totalObtainedMarks,
        totalMarks,
        currentSubjectId: currentSubject.subjectId,
        totalSeeMarks,
        totalSeeObtainedMarks,
        totalCieMarks,
        totalCieObtainedMarks,
      };
    },
    roundOffUptoTwoDecimals(value) {
      return Math.round(value * 100) / 100;
    },
    roundOffUptoOneDecimals(value) {
      return Math.round(value * 10) / 10;
    },
    calculateResultsOfStudents() {
      this.allStudentData = [];
      this.allSubjectData = [];
      this.passingMarks = 0;
      this.sumOfObtainedMarksInAllExams = 0;
      this.totalMarksOfAllExams = 0;
      this.allStudentData = [];
      for (let j = 0; j < this.filteredExamsForResult.length; j++) {
        const exam = this.filteredExamsForResult[j];
        for (let k = 0; k < exam.subjectDetails.length; k++) {
          const element = exam.subjectDetails[k];

          for (let l = 0; l < this.semesterStudents.length; l++) {
            if (this.semesterStudents[l].subjects.includes(element.subjectId)) {
              if (element.totalStudents.length > 0) {
                if (
                  element.totalStudents.includes(this.semesterStudents[l].uId)
                ) {
                  let result = this.calculateIndividualExamResult(
                    exam,
                    element,
                    this.semesterStudents[l]
                  );
                  let objToPush = {};
                  if (this.semesterStudents[l].attemptNumber > 0) {
                    this.semesterStudents[l].semesterSubjectMarks.map(
                      (item) => {
                        if (item.subjectId === element.subjectId) {
                          item.totalMarks = item.isPassed
                            ? item.totalMarks
                            : result.totalMarks;
                          item.obtainedMarks = item.isPassed
                            ? Number(item.obtainedMarks)
                            : Number(result.totalObtainedMarks);
                          item.totalCieMarks =
                            item.isPassed && item.totalCieMarks
                              ? Number(item.totalCieMarks)
                              : Number(result.totalCieMarks);
                          item.totalCieObtainedMarks =
                            item.isPassed && item.totalCieObtainedMarks
                              ? Number(item.totalCieObtainedMarks)
                              : Number(result.totalCieObtainedMarks);
                          item.totalSeeMarks =
                            item.isPassed && item.totalSeeMarks
                              ? Number(item.totalSeeMarks)
                              : Number(result.totalSeeMarks);
                          item.totalSeeObtainedMarks =
                            item.isPassed && item.totalSeeObtainedMarks
                              ? Number(item.totalSeeObtainedMarks)
                              : Number(result.totalSeeObtainedMarks);
                        }
                      }
                    );
                  } else {
                    objToPush = {
                      subjectId: element.subjectId,
                      totalMarks: result.totalMarks ? result.totalMarks : 0,
                      obtainedMarks: result.totalObtainedMarks
                        ? result.totalObtainedMarks
                        : 0,
                      totalCieMarks: result.totalCieMarks
                        ? result.totalCieMarks
                        : 0,
                      totalCieObtainedMarks: result.totalCieObtainedMarks
                        ? result.totalCieObtainedMarks
                        : 0,
                      totalSeeMarks: result.totalSeeMarks
                        ? result.totalSeeMarks
                        : 0,
                      totalSeeObtainedMarks: result.totalSeeObtainedMarks
                        ? result.totalSeeObtainedMarks
                        : 0,
                    };
                    this.semesterStudents[l].semesterSubjectMarks.push(
                      objToPush
                    );
                  }
                }
              }
            }
          }
        }
      }
      if (this.examSettings.systemPreference === "percentage") {
        if (this.examSettings.selectedCieSeePreference === "no") {
          // for (let index = 0; index < this.semesterStudents.length; index++) {
          //   const currentStudent = this.semesterStudents[index];
          //   let considerStudent = false;
          //   let studentObj = {
          //     semId: currentStudent.semId,
          //     courseYear: currentStudent.courseYear,
          //     department: currentStudent.department,
          //     semesterSubjectMarks: currentStudent.semesterSubjectMarks,
          //     uId: currentStudent.uId,
          //     attemptNumber: currentStudent.attemptNumber,
          //     finalPercentage: currentStudent.finalPercentage,
          //     passStatus: currentStudent.passStatus,
          //   };
          //   let tempSubjectTotalMarks = 0;
          //   let tempSubjectTotalObtainedMarks = 0;
          //   let tempPer = 0;
          //   // let semesterSubjectMarks = [];
          //   for (let index = 0; index < this.allSubjects.length; index++) {
          //     const currentSubject = this.allSubjects[index];
          //     let objToPush = {};
          //     let tempAllExamMarks = 0;
          //     let tempAllExamObtainedMarks = 0;

          //     for (let index = 0; index < this.filteredExamsForResult.length; index++) {
          //       const exam = this.filteredExamsForResult[index];
          //       // if (exam.applicableSubjects.includes(currentSubject.subjectId)) {
          //       let result = this.calculateIndividualExamResult(
          //         exam,
          //         currentSubject,
          //         currentStudent
          //       );

          //       // exam.subjectDetails.map((element) => {
          //       //   if (element.subjectId === currentSubject.subjectId) {
          //       //     if (element.totalStudents.length > 0) {
          //       //       if (element.totalStudents.includes(currentStudent.uId)) {
          //       //         considerStudent = true;
          //       //       }
          //       //     }
          //       //   }
          //       // });
          //       for (
          //         let index = 0;
          //         index < exam.subjectDetails.length;
          //         index++
          //       ) {
          //         const element = exam.subjectDetails[index];
          //         if (element.subjectId === currentSubject.subjectId) {
          //           if (element.totalStudents.length > 0) {
          //             if (element.totalStudents.includes(currentStudent.uId)) {
          //               considerStudent = true;
          //             }
          //           }
          //         }
          //       }
          // if (result.totalObtainedMarks > 0) {
          //   tempSubjectTotalMarks += result.totalMarks;
          //   tempSubjectTotalObtainedMarks += result.totalObtainedMarks;

          //   tempAllExamMarks += result.totalMarks;
          //   tempAllExamObtainedMarks += result.totalObtainedMarks;
          // }
          //       // }
          //     }
          //     if (considerStudent) {
          //       // if (currentStudent.semesterSubjectMarks["isPassed"] === false) {
          //       //   currentStudent.semesterSubjectMarks["subjectId"] = currentSubject.subjectId;
          //       //   currentStudent.semesterSubjectMarks["obtainedMarks"] = tempAllExamObtainedMarks;
          //       //   currentStudent.semesterSubjectMarks["totalMarks"] = tempAllExamMarks;
          //       // } else {
          //       //   objToPush["subjectId"] = currentSubject.subjectId;
          //       //   objToPush["obtainedMarks"] = tempAllExamObtainedMarks;
          //       //   objToPush["totalMarks"] = tempAllExamMarks;
          //       //   currentStudent.semesterSubjectMarks.push(objToPush);
          //       // }
          // let passMarks =
          //   (tempAllExamMarks * this.examSettings.overallPercentage) /
          //   100;

          //       // console.log('currentStudent.semesterSubjectMarks', currentStudent.semesterSubjectMarks);
          //       if (currentStudent.semesterSubjectMarks.length > 0) {
          //         currentStudent.semesterSubjectMarks.map((subject) => {
          //           // if (!subject.isPassed) {
          //           if (subject.subjectId === currentSubject.subjectId) {
          //             subject.obtainedMarks = subject.isPassed
          //               ? subject.obtainedMarks
          //               : tempAllExamObtainedMarks;
          //             subject.totalMarks = subject.isPassed
          //               ? subject.obtainedMarks
          //               : tempAllExamMarks;
          //           }
          //           // } else {

          //           // }
          //         });
          //       }
          //       let subjectToCheck = currentStudent.semesterSubjectMarks.filter(
          //         (item) => item.subjectId === currentSubject.subjectId
          //       )[0];
          //       if (passMarks > 0) {
          //         if (subjectToCheck.obtainedMarks < passMarks) {
          //           subjectToCheck["isPassed"] = false;
          //         } else if (subjectToCheck.obtainedMarks >= passMarks) {
          //           subjectToCheck["isPassed"] = true;
          //         }
          //       }
          //       // console.log('currentStudent.semesterSubjectMarks', currentStudent.semesterSubjectMarks);
          //     }
          //   }
          //   studentObj.semesterSubjectMarks =
          //     currentStudent.semesterSubjectMarks;

          //   if (considerStudent) {
          //     let percentage = 0;
          //     tempPer =
          //       (tempSubjectTotalObtainedMarks / tempSubjectTotalMarks) * 100;
          //     if (isNaN(tempPer)) {
          //       percentage = 0;
          //     } else {
          //       percentage = tempPer;
          //     }
          //     studentObj.finalPercentage =
          //       this.roundOffUpto2Decimals(percentage);

          //     let result = currentStudent.semesterSubjectMarks.every(
          //       this.checkSubjectPassing
          //     );
          //     if (result) {
          //       studentObj.passStatus = true;
          //     } else {
          //       studentObj.passStatus = false;
          //     }
          //     studentObj.attemptNumber = currentStudent.attemptNumber + 1;
          //     studentObj.uId = currentStudent.uId;
          //     this.allStudentData.push(studentObj);
          //   }
          // }

          this.semesterStudents.map((obj) => {
            if (obj.subjects.length > 0) {
              obj.totalSubjectsMarks = 0;
              obj.tempSubjectTotalObtainedMarks = 0;
              // obj.subjectsPassed = 0;
              let percentage = 0;

              //combine same subject objects
              let subjectSums = {};
              obj?.semesterSubjectMarks.map((e) => {
                const {
                  subjectId,
                  obtainedMarks,
                  totalMarks,
                  totalCieMarks,
                  totalCieObtainedMarks,
                  totalSeeMarks,
                  totalSeeObtainedMarks,
                } = e;
                if (subjectSums.hasOwnProperty(subjectId)) {
                  subjectSums[subjectId].obtainedMarks += Number(obtainedMarks);
                  subjectSums[subjectId].totalMarks += Number(totalMarks);
                  subjectSums[subjectId].totalCieMarks += Number(totalCieMarks);
                  subjectSums[subjectId].totalCieObtainedMarks += Number(
                    totalCieObtainedMarks
                  );
                  subjectSums[subjectId].totalSeeMarks += Number(totalSeeMarks);
                  subjectSums[subjectId].totalSeeObtainedMarks += Number(
                    totalSeeObtainedMarks
                  );
                } else {
                  subjectSums[subjectId] = {
                    obtainedMarks,
                    totalMarks,
                    totalCieMarks,
                    totalCieObtainedMarks,
                    totalSeeMarks,
                    totalSeeObtainedMarks,
                  };
                }
              });
              const result = Object.keys(subjectSums).map((subjectId) => ({
                subjectId,
                obtainedMarks: subjectSums[subjectId].obtainedMarks,
                totalMarks: subjectSums[subjectId].totalMarks,
                totalCieMarks: subjectSums[subjectId].totalCieMarks,
                totalCieObtainedMarks:
                  subjectSums[subjectId].totalCieObtainedMarks,
                totalSeeMarks: subjectSums[subjectId].totalSeeMarks,
                totalSeeObtainedMarks:
                  subjectSums[subjectId].totalSeeObtainedMarks,
              }));

              result.map((item) => {
                let passMarks =
                  (item.totalMarks * this.examSettings.overallPercentage) / 100;
                if (passMarks > 0) {
                  item.isPassed =
                    item.obtainedMarks >= passMarks ? true : false;
                }
                obj.totalSubjectsMarks += Number(item.totalMarks);
                obj.tempSubjectTotalObtainedMarks += Number(item.obtainedMarks);
                obj.totalCieMarks += Number(item.totalCieMarks);
                obj.totalCieObtainedMarks += Number(item.totalCieObtainedMarks);
                obj.totalSeeMarks += Number(item.totalSeeMarks);
                obj.totalSeeObtainedMarks += Number(item.totalSeeObtainedMarks);
              });
              obj.semesterSubjectMarks = result;
              percentage =
                (obj.tempSubjectTotalObtainedMarks / obj.totalSubjectsMarks) *
                100;
              if (isNaN(percentage)) {
                obj.finalPercentage = 0;
              } else {
                obj.finalPercentage = this.roundOffUptoTwoDecimals(percentage);
              }
              let subjectsPassStatus = obj.semesterSubjectMarks.every(
                this.checkSubjectPassing
              );
              if (subjectsPassStatus) {
                obj.attemptNumber = obj.attemptNumber + 1;
                obj.passStatus = true;
              } else {
                obj.attemptNumber = obj.attemptNumber + 1;
                obj.passStatus = false;
              }
            } else {
              obj.attemptNumber = obj.attemptNumber + 1;
              obj.passStatus = false;
            }
          });
        }
      } else if (this.examSettings.systemPreference === "cbs") {
        if (this.examSettings.selectedCieSeePreference === "no") {
          if (this.examSettings.gradingSystem === "absolute") {
            this.semesterStudents.map((obj) => {
              if (obj.subjects.length > 0) {
                obj.finalSgpa = 0;
                const result = this.calculateSubjectSums(
                  obj.semesterSubjectMarks
                );
                // obj?.semesterSubjectMarks.map((e) => {
                //   const {
                //     subjectId,
                //     obtainedMarks,
                //     totalMarks,
                //     totalCieMarks,
                //     totalCieObtainedMarks,
                //     totalSeeMarks,
                //     totalSeeObtainedMarks,
                //   } = e;
                //   if (subjectSums.hasOwnProperty(subjectId)) {
                //     subjectSums[subjectId].obtainedMarks +=
                //       Number(obtainedMarks);
                //     subjectSums[subjectId].totalMarks += Number(totalMarks);
                //     subjectSums[subjectId].totalCieMarks +=
                //       Number(totalCieMarks);
                //     subjectSums[subjectId].totalCieObtainedMarks += Number(
                //       totalCieObtainedMarks
                //     );
                //     subjectSums[subjectId].totalSeeMarks +=
                //       Number(totalSeeMarks);
                //     subjectSums[subjectId].totalSeeObtainedMarks += Number(
                //       totalSeeObtainedMarks
                //     );
                //   } else {
                //     subjectSums[subjectId] = {
                //       obtainedMarks,
                //       totalMarks,
                //       totalCieMarks,
                //       totalCieObtainedMarks,this.allSubjects
                //       totalSeeMarks,
                //       totalSeeObtainedMarks,
                //     };
                //   }
                // });
                // const result = Object.keys(subjectSums).map((subjectId) => ({
                //   subjectId,
                //   obtainedMarks: subjectSums[subjectId].obtainedMarks,
                //   totalMarks: subjectSums[subjectId].totalMarks,
                //   totalCieMarks: subjectSums[subjectId].totalCieMarks,
                //   totalCieObtainedMarks:
                //     subjectSums[subjectId].totalCieObtainedMarks,
                //   totalSeeMarks: subjectSums[subjectId].totalSeeMarks,
                //   totalSeeObtainedMarks:
                //     subjectSums[subjectId].totalSeeObtainedMarks,
                // }));
                let totalGradePointsSum = 0;
                result.map((item) => {
                  let subjectPercentage =
                    (item.obtainedMarks / item.totalMarks) * 100;
                  item.percentage =
                    this.roundOffUptoTwoDecimals(subjectPercentage);
                  const grade = this.examSettings.gradeDetails.filter(
                    (item) =>
                      Number(subjectPercentage) <=
                        Number(item.gradeRange.from) &&
                      Number(subjectPercentage) >= Number(item.gradeRange.to)
                  )[0];

                  const subject = this.allSubjects.filter(
                    (sub) => sub.subjectId === item.subjectId
                  )[0];
                  if (grade) {
                    item.subjectGradePoints = Number(grade.gradePoints);
                    item.obtainedGrade = grade.gradeName;
                    item.obtainedCredits = subject.totalCredits;
                  } else {
                    item.subjectGradePoints = 0;
                    item.obtainedGrade = "F";
                    item.obtainedCredits = subject.totalCredits;
                  }
                  item.obtainedGradePoints =
                    subject.totalCredits * item.subjectGradePoints;

                  totalGradePointsSum += item.obtainedGradePoints;
                });
                obj.semesterSubjectMarks = result;
                let subjectGradePointsSum = obj.semesterSubjectMarks.reduce(
                  function (previousVal, currentVal) {
                    return previousVal + currentVal.obtainedCredits;
                  },
                  0
                );
                obj.finalSgpa = this.roundOffUptoOneDecimals(
                  totalGradePointsSum / subjectGradePointsSum
                );
              } else {
                // obj.attemptNumber = obj.attemptNumber + 1;
                // obj.passStatus = false;
                console.log("No subjects are assigned.");
              }
            });
          }
        } else {
          if (this.examSettings.gradingSystem === "relative") {
            let subjectPercentages = {};
            this.semesterStudents.map((obj) => {
              if (obj.subjects.length > 0) {
                const result = this.calculateSubjectSums(
                  obj.semesterSubjectMarks
                );

                result.map((item) => {
                  let finalPercentage = this.roundOffUptoTwoDecimals(
                    (item.obtainedMarks / item.totalMarks) * 100
                  );

                  if (subjectPercentages.hasOwnProperty(item.subjectId)) {
                    subjectPercentages[item.subjectId].push(finalPercentage);
                  } else {
                    subjectPercentages[item.subjectId] = [finalPercentage];
                  }
                });
                obj.semesterSubjectMarks = result;
              }
            });

            // let subjectGradeDetails = {};
            // for (const [key, value] of Object.entries(subjectPercentages)) {
            //   let upperLimit = Math.max(...value);
            //   let maxNumberOfGrades = 7;
            //   let rangeDiff = this.roundOffUptoTwoDecimals(
            //     (upperLimit -
            //       Number(this.examSettings.lowestPercentageOfPassing)) /
            //       maxNumberOfGrades
            //   );

            //   // calculate grade range based on max marks for each subject
            //   for (let index = 0; index < maxNumberOfGrades; index++) {
            //     let lowerLimit = this.roundOffUptoTwoDecimals(
            //       upperLimit - rangeDiff
            //     );

            //     let tempGradeRange = {
            //       from: lowerLimit,
            //       to: upperLimit,
            //     };
            //     this.examSettings.gradeDetails[index].gradeRange =
            //       tempGradeRange;
            //     upperLimit = this.roundOffUptoTwoDecimals(lowerLimit - 0.01);
            //   }

            //   subjectGradeDetails[key] = this.examSettings.gradeDetails; // subjectId: subjectGradeRanges
            // }
            let totalNumberOfGrades = 7;
            const subjectGradeDetails = this.calculateSubjectWiseGradeRange(
              subjectPercentages,
              totalNumberOfGrades
            );
            for (let stInd = 0; stInd < this.semesterStudents.length; stInd++) {
              const currentStudent = this.semesterStudents[stInd];
              let totalCreditPoints = 0;
              let passedSubjectCount = 0;
              for (
                let index = 0;
                index < currentStudent.semesterSubjectMarks.length;
                index++
              ) {
                const element = currentStudent.semesterSubjectMarks[index];

                const subjectDetail = this.allSubjects.filter(
                  (sub) => sub.subjectId === element.subjectId
                )[0];

                let combinedPercentage = this.roundOffUptoTwoDecimals(
                  (element.obtainedMarks / element.totalMarks) * 100
                );

                let ciePercentage;
                let seePercentage;

                let tempCiePer = this.roundOffUptoTwoDecimals(
                  (element.totalCieObtainedMarks / element.totalCieMarks) * 100
                );
                isNaN(tempCiePer)
                  ? (ciePercentage = 0)
                  : (ciePercentage = tempCiePer);

                let tempSeePer = this.roundOffUptoTwoDecimals(
                  (element.totalSeeObtainedMarks / element.totalSeeMarks) * 100
                );

                isNaN(tempSeePer)
                  ? (seePercentage = 0)
                  : (seePercentage = tempSeePer);
                if (
                  ciePercentage >= this.examSettings.minimumCiePercentage &&
                  seePercentage >= this.examSettings.minimumSeePercentage
                ) {
                  if (
                    combinedPercentage >=
                    this.examSettings.lowestPercentageOfPassing
                  ) {
                    const grade = subjectGradeDetails[element.subjectId].filter(
                      (e) =>
                        combinedPercentage >= e.gradeRange.from &&
                        combinedPercentage <= e.gradeRange.to
                    )[0];
                    if (grade) {
                      passedSubjectCount++;
                      element.subjectGradePoints = Number(grade.gradePoints);
                      element.obtainedGrade = grade.gradeName;
                      element.obtainedCredits = subjectDetail.totalCredits;
                    } else {
                      element.subjectGradePoints = 0;
                      element.obtainedGrade = "F";
                      element.obtainedCredits = 0;
                    }
                  } else {
                    element.subjectGradePoints = 0;
                    element.obtainedGrade = "F";
                    element.obtainedCredits = 0;
                  }
                } else {
                  element.subjectGradePoints = 0;
                  element.obtainedGrade = "F";
                  element.obtainedCredits = 0;
                }
                element.obtainedGradePoints =
                  subjectDetail.totalCredits * element.subjectGradePoints;
                totalCreditPoints += element.obtainedGradePoints;
              }
              let totalCredits = currentStudent.semesterSubjectMarks.reduce(
                function (previousVal, currentVal) {
                  return previousVal + currentVal.obtainedCredits;
                },
                0
              );
              const finalSgpa = this.calculateSGPA(
                passedSubjectCount,
                currentStudent.semesterSubjectMarks.length,
                totalCreditPoints,
                totalCredits
              );
              currentStudent.finalSgpa = finalSgpa;
            }
            // console.log("this.semesterStudents", this.semesterStudents);
          }
        }
        // console.log("this.semesterStudents", this.semesterStudents);
        // this.assignGradesToTheStudents()
      }
    },
    calculateSubjectWiseGradeRange(subjectPercentages, totalNumberOfGrades) {
      let subjectGradeDetails = {};
      for (const [key, value] of Object.entries(subjectPercentages)) {
        let upperLimit = Math.max(...value);
        let rangeDiff = this.roundOffUptoTwoDecimals(
          (upperLimit - Number(this.examSettings.lowestPercentageOfPassing)) /
            totalNumberOfGrades
        );

        let tempGradeDetails = [];
        // calculate grade range based on max obtained marks by a student for each subject
        for (let index = 0; index < totalNumberOfGrades; index++) {
          let lowerLimit = this.roundOffUptoTwoDecimals(upperLimit - rangeDiff);

          let tempGradeRange = {
            from: lowerLimit,
            to: upperLimit,
          };
          let tempGradeName = this.examSettings.gradeDetails[index].gradeName;
          let tempGradePoints =
            this.examSettings.gradeDetails[index].gradePoints;
          let tempGradeRemark =
            this.examSettings.gradeDetails[index].gradeRemark;
          tempGradeDetails.push({
            gradeName: tempGradeName,
            gradePoints: tempGradePoints,
            gradeRemark: tempGradeRemark,
            gradeRange: tempGradeRange,
          });
          upperLimit = this.roundOffUptoTwoDecimals(lowerLimit - 0.01);
        }
        subjectGradeDetails[key] = tempGradeDetails; // subjectId: subjectGradeRanges
        subjectGradeDetails[key][totalNumberOfGrades - 1].gradeRange.from =
          Number(this.examSettings.lowestPercentageOfPassing);
      }
      return subjectGradeDetails;
    },
    checkSubjectPassing(subject) {
      if (subject.isPassed) {
        return true;
      } else {
        return false;
      }
    },
    calculateSGPA(
      passedSubjectCount,
      totalSubjectCount,
      totalCreditPoints,
      totalCredits
    ) {
      let finalSgpa = 0;
      if (passedSubjectCount === totalSubjectCount) {
        let tempSgpa = totalCreditPoints / totalCredits;
        if (isNaN(tempSgpa)) {
          finalSgpa = 0;
        } else {
          finalSgpa = this.roundOffUptoTwoDecimals(tempSgpa);
        }
      } else {
        finalSgpa = 0;
      }
      return finalSgpa;
    },
    calculateSubjectSums(element) {
      let subjectSums = {};
      element.map((e) => {
        const {
          subjectId,
          obtainedMarks,
          totalMarks,
          totalCieMarks,
          totalCieObtainedMarks,
          totalSeeMarks,
          totalSeeObtainedMarks,
        } = e;
        if (subjectSums.hasOwnProperty(subjectId)) {
          subjectSums[subjectId].obtainedMarks += Number(obtainedMarks);
          subjectSums[subjectId].totalMarks += Number(totalMarks);
          subjectSums[subjectId].totalCieMarks += Number(totalCieMarks);
          subjectSums[subjectId].totalCieObtainedMarks += Number(
            totalCieObtainedMarks
          );
          subjectSums[subjectId].totalSeeMarks += Number(totalSeeMarks);
          subjectSums[subjectId].totalSeeObtainedMarks += Number(
            totalSeeObtainedMarks
          );
        } else {
          subjectSums[subjectId] = {
            obtainedMarks,
            totalMarks,
            totalCieMarks,
            totalCieObtainedMarks,
            totalSeeMarks,
            totalSeeObtainedMarks,
          };
        }
      });
      const result = Object.keys(subjectSums).map((subjectId) => ({
        subjectId,
        obtainedMarks: subjectSums[subjectId].obtainedMarks,
        totalMarks: subjectSums[subjectId].totalMarks,
        totalCieMarks: subjectSums[subjectId].totalCieMarks,
        totalCieObtainedMarks: subjectSums[subjectId].totalCieObtainedMarks,
        totalSeeMarks: subjectSums[subjectId].totalSeeMarks,
        totalSeeObtainedMarks: subjectSums[subjectId].totalSeeObtainedMarks,
      }));
      return result;
    },
    closeResultDialog() {
      this.selectedCourseYearToPublishResult = "";
      this.selectedDepartmentToPublishResult = "";
      this.publishResultDialog = false;
    },
    async publishResult() {
      this.publishResultLoader = true;
      this.filterExams();
      let filteredSemesters = this.allSemesterDetails.filter(
        (sem) =>
          sem.instituteId === this.selectedInstitute &&
          sem.semId === this.selectedSemester.semId &&
          sem.department === this.selectedDepartmentToPublishResult &&
          sem.courseYear === this.selectedCourseYearToPublishResult
      );
      try {
        await this.semesterUserRepositoryInstance.updateSemesterUserResultDetails(
          {
            userDetailsArray: this.semesterStudents,
            systemPreference: this.examSettings.systemPreference,
          }
        );
        await this.semesterRepositoryInstance.updateSemesterCourseYearResultDetails(
          {
            semesters: filteredSemesters,
          }
        );
        this.publishResultDialog = false;
        showStatus("Result published successfully.", 5000, "success", this);
      } catch (error) {
        console.error("error", error);

        showStatus(
          "Error publishing Result. Please try again later.",
          5000,
          "success",
          this
        );
      }
      this.publishResultLoader = false;
    },
  },
};
</script>
<style scoped src='./activityExams1.css'></style>