<template src="./assessmentPlan.html"></template>

<script>
import SubjectRepository from "../../../../Repository/Subject";
import NbaRepository from "../../../../Repository/NBA";
import NewExamRepository from "../../../../Repository/NewExam";
import showStatus from "../../../../NetworkManager/showStatus";
export default {
  name: "assessmentPlan",
  components: {},
  props: ["subjectId", "semId", "courseYear", "selectedDepartment"],
  async created() {

    this.nbaRepositoryInstance = new NbaRepository(this);
    this.newExamRepositoryInstance = new NewExamRepository(this);
    this.subjectRepositoryInstance = new SubjectRepository(this);
    this.selectedInstitute =
      this.$store.getters["instituteData/get_selectedInstitute"];
    // this.syllabusInstance = new SyallbusRepository(
    //   this
    // );
    await this.fetchSubject();
    await this.fetchExams();
    await this.fetchCos();
  },
  data() {
    return {
      backgroundColor: "#E7E7FFCF",
      modeOfConduction: ['Written Exam',
        'Open Book Test',
        'Online Exam',
        'Viva Voce',
        'Oral Presentation ',
        'Group Discussion',
        'Practical Exam',
        'Fieldwork Evaluation',
        'Project Based Exam',
        'Class Test',
        'Quiz',
        'Assignment',
        'Tutorial',
        'Case Study Evaluation',
        'End term exam',
        'Role plays',
        'Simulation',
        'Term work'],
      calenderIcon: 'mdi-calendar-month-outline',
      headers: [
        { text: "Sr No", value: "srNo", width: "5%", sortable: false },
        {
          text: "Assessment Tag",
          value: "assessmentTag",
          width: "9%",
          sortable: false,
        },
        { text: "Name", value: "title", width: "9%", sortable: false, align: "center", },
        { text: "Date", value: "examDate", width: "10%", sortable: false, align: "center", },
        { text: "Cos", value: "overallCOs", width: "9%", sortable: false, align: "center", },
        { text: "Threshold", value: "threshold", width: "9%", sortable: false, align: "center", },
        { text: "Result Date", value: "resultDate", width: "9%", sortable: false, align: "center", },
        {
          text: "Total Marks",
          value: "totalMarks",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Reduce Marks To",
          value: "reducedMarks",
          width: "9%",
          sortable: false,
          align: "center",
        },
        { text: "Remarks", value: "remarks", width: "9%", sortable: false, align: "center", },
        {
          text: "Consider for result cals",
          value: "isForResultCal",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Consider for attainment",
          value: "isForAttainment",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Add to course file",
          value: "addToCourseFile",
          width: "9%",
          sortable: false,
          align: "center",
        },
      ],
      headersForFetch: [
        { text: "Sr No", value: "srNo", width: "5%", sortable: false },
        { text: "Assessment Tag", value: "assessmentTag", width: "9%", sortable: false },
        { text: "Title", value: "title", width: "20%", sortable: false },
        { text: "Date", value: "examDate", width: "14%", sortable: false, align: "center", },
        { text: "Mode Of Conduction", value: "modeOfConduction", width: "9%", sortable: false, align: "center", },
        { text: "Cos", value: "overallCOs", width: "9%", sortable: false, align: "center", },
        { text: "Threshold", value: "threshold", width: "9%", sortable: false, align: "center", },
        {
          text: "Total Marks",
          value: "totalMarks",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Reduce Marks",
          value: "reducedMarks",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Remark",
          value: "remark",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Consider for attainment",
          value: "isForAttainment",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Consider for Course File",
          value: "addToCourseFile",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Edit",
          value: "edit",
          width: "9%",
          sortable: false,
          align: "center",
        },
        {
          text: "Delete",
          value: "delete",
          width: "9%",
          sortable: false,
          align: "center",
        },
      ],
      formativeAssessmentsItems: [],
      fetchFormativeAssessmentsItems: [],
      selectedFetchFormativeAssessmentsItems: [],
      summativeAssessmentsItems: [],
      fetchSummativeAssessmentsItems: [],
      selectedFetchSummativeAssessmentsItems: [],
      nbaData: [],
      courseOutcomeData: [],
      saveLoader: false,
      totalCieMarks: '',
      totalSeeMarks: '',
      assessmentTab: ["Passing Rules", "Assement Plan"],
      tabs: 1,
      createOrFetchTab: 1,
      subject: "",
      formativeSearch: "",
      formativeSearch2: "",
      summativeSearch: "",
      summativeSearch2: "",
      formativeExamIds: [],
      summativeExamIds: [],
      saveLoader2: false
    };
  },
  methods: {
    removeExam(i, type) {
      if (type === 'Summative') {
        this.fetchSummativeAssessmentsItems.splice(i, 1)
      }
      if (type === 'Formative') {
        this.fetchFormativeAssessmentsItems.splice(i, 1)

      }
    },
    editItem(item, i, type) {
      item.isForEdit = !item.isForEdit
      if (type === 'Summative') {
        this.$set(this.fetchSummativeAssessmentsItems, i, item)
      }
      if (type === 'Formative') {
        this.$set(this.fetchFormativeAssessmentsItems, i, item)
      }
    },
    valueChanged(item, i, type) {
      // console.log('Chnages', item);
      item.isChanged = true
      if (type === 'Summative') {
        this.$set(this.fetchSummativeAssessmentsItems, i, item)

      }
      if (type === 'Formative') {

        this.$set(this.fetchFormativeAssessmentsItems, i, item)
      }

    },
    async fetchSubject() {
      const objToPush = {
        instituteId: this.selectedInstitute,
        semId: this.semId,
        subjectId: this.subjectId,
      };
      try {
        const response =
          await this.subjectRepositoryInstance.getDetailsOfASubject(objToPush);
        this.subject = response;
      }
      catch (error) {
        console.error(error)
      }
    },
    async saveExamIdsInSubject() {
      this.saveLoader2 = true;
      let examIds = [...this.formativeExamIds, ...this.summativeExamIds];
      // this.selectedFetchFormativeAssessmentsItems.forEach((exam)=>examIds.push(exam.examId));
      // this.selectedFetchSummativeAssessmentsItems.forEach((exam)=>examIds.push(exam.examId));
      try {
        let objToSend = {
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
          instituteSubjectId: this.subject.instituteSubjectId,
          examsForCourseFile: examIds
        }
        const response = await this.subjectRepositoryInstance.bulkCreateSubject(
          [objToSend]
        );
        if (response.status === 200) {
          showStatus("Successfully saved assessment plan", 2000, 'success', this);
        }
      } catch (error) {
        showStatus("Could not connect to server.", 2000, "error", this);
        console.error(error)
      }
      this.saveLoader2 = false;
    },
    async saveSubjectDetails() {
      this.saveLoader = true;
      try {
        let objToSend = {
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId,
          instituteSubjectId: this.subject.instituteSubjectId,
          totalCieMarks: this.subject.totalCieMarks,
          totalSeeMarks: this.subject.totalSeeMarks,
          examsForCourseFile: []
        }
        const response = await this.subjectRepositoryInstance.bulkCreateSubject(
          [objToSend]
        );

        await this.saveExamDetails();
      } catch (err) {
        showStatus("Could not connect to server.", 2000, "error", this);
        this.saveLoader = false;
        console.error(err);
      }

    },
    handleTotalMarks(item) {
      item.reducedMarks = item.totalMarks
    },
    async fetchExams() {
      try {
        const objToPush = {
          instituteId: this.selectedInstitute,
          semId: this.semId,
          department: this.selectedDepartment,
          courseYear: this.courseYear,
          subjectId: this.subjectId
        };

        let response = await this.newExamRepositoryInstance.getExamsForCourseFile(objToPush)
        // console.log('response', response);
        // let oldExamsReponse = await this.newExamRepositoryInstance.getExamsFromSubjectIds({subjectsIds : [this.subjectId]})
        if (response.status.status === 200) {
          //  response
          this.formativeAssessmentsItems = [];
          this.fetchFormativeAssessmentsItems = [];
          this.formativeAssessmentsItems = [];
          this.fetchSummativeAssessmentsItems = [];
          let formativeCount = 0
          let summetiveCount = 0
          response.exams.forEach((exam) => {
            exam.isChanged = false
            exam.isForEdit = false
            exam.isNew = false
            exam.isForAttainment = exam.settings.isForAttainment ? true : false
            exam.addToCourseFile = exam.addToCourseFile ? exam.addToCourseFile : false
            // console.log('exam.title', exam.subjectDetails);
            if (exam.examFormat === 'Formative') {
              formativeCount++;
              exam.assessmentTag = `Formative_Assessment_${formativeCount}`
              if (exam.subjectDetails) {
                exam = { ...exam, ...exam.subjectDetails }
                delete exam.subjectDetails
              }
              // this.formativeExamIds.push(exam.examId)
              this.fetchFormativeAssessmentsItems.push(exam)
              // console.log('this.fetchFormativeAssessmentsItems', this.fetchFormativeAssessmentsItems);
            }
            else if (exam.examFormat === 'Summative') {
              summetiveCount++;
              exam.assessmentTag = `Summative_Assessment_${summetiveCount}`
              if (exam.subjectDetails) {
                exam = { ...exam, ...exam.subjectDetails }
                delete exam.subjectDetails
              }
              // this.summativeExamIds.push(exam.examId)
              this.fetchSummativeAssessmentsItems.push(exam)
            }

          })
          // this.selectedFetchFormativeAssessmentsItems = this.fetchFormativeAssessmentsItems.filter((exam)=>this.subject.examsForCourseFile.includes(exam.examId))
          // this.selectedFetchFormativeAssessmentsItems.forEach(exam => {
          //     this.formativeExamIds.push(exam.examId);
          // });
          // this.selectedFetchSummativeAssessmentsItems = this.fetchSummativeAssessmentsItems.filter((exam)=>this.subject.examsForCourseFile.includes(exam.examId))
          // this.selectedFetchSummativeAssessmentsItems.forEach(exam => {
          //     this.summativeExamIds.push(exam.examId);
          // });
          // if(this.selectedFetchFormativeAssessmentsItems.length === 0 && this.selectedFetchSummativeAssessmentsItems.length===0){
          //   this.createOrFetchTab = 0;
          // }
        }
      }
      catch (error) {
        console.error("error: ", error)
      }
    },
    addFormativeExam() {
      let currentIndex = this.formativeAssessmentsItems.length + 1
      this.formativeAssessmentsItems.push({
        assessmentTag: 'Formative_Assessment_' + currentIndex
      });
    },
    deleteFormativeExams(index) {
      this.selectedFetchFormativeAssessmentsItems.splice(index, 1);
      this.formativeExamIds.splice(index, 1);
    },
    deleteSummativeExams(index) {
      this.selectedFetchSummativeAssessmentsItems.splice(index, 1);
      this.summativeExamIds.splice(index, 1);
    },
    addFetchFormativeExam() {
      // let currentIndex = this.fetchFormativeAssessmentsItems.length + 1;
      let objToPush = {}
      objToPush.isNew = true
      objToPush.subjectId = this.subjectId
      objToPush.examFormat = 'Formative'
      objToPush.instituteId = this.selectedInstitute
      objToPush.semId = this.semId
      objToPush.courseYear = this.courseYear
      objToPush.department = this.selectedDepartment
      objToPush.title = '';
      objToPush.assessmentTag = `Formative_Assessment_${this.fetchFormativeAssessmentsItems.length + 1}`;
      objToPush.threshold = 0;
      objToPush.totalMarks = 0;
      objToPush.reducedMarks = 0;
      objToPush.examDate = '';
      objToPush.resultDate = '';
      objToPush.overallCOs = [];
      objToPush.addToCourseFile = true;
      objToPush.examFormat = 'Formative';
      // objToPush.totalSeeMarks = Number(this.totalSeeMarks)
      // tempTotalSeeMarks =+ Number(item.reducedMarks);
      // console.log('objToPush', objToPush);
      this.fetchFormativeAssessmentsItems.push(objToPush);
    },
    addFetchSummativeExam() {
      // let currentIndex = this.fetchFormativeAssessmentsItems.length + 1;
      let objToPush = {}
      objToPush.isNew = true
      objToPush.subjectId = this.subjectId
      objToPush.instituteId = this.selectedInstitute
      objToPush.semId = this.semId
      objToPush.courseYear = this.courseYear
      objToPush.department = this.selectedDepartment
      objToPush.examFormat = 'Summative'

      objToPush.title = '';
      objToPush.assessmentTag = `Summative_Assessment_${this.fetchFormativeAssessmentsItems.length + 1}`;
      objToPush.threshold = 0;
      objToPush.totalMarks = 0;
      objToPush.reducedMarks = 0;
      objToPush.examDate = '';
      objToPush.resultDate = '';
      objToPush.overallCOs = [];
      objToPush.addToCourseFile = true;
      objToPush.examFormat = 'Summative';
      // objToPush.totalSeeMarks = Number(this.totalSeeMarks)
      // tempTotalSeeMarks =+ Number(item.reducedMarks);
      // console.log('objToPush', objToPush);
      this.fetchSummativeAssessmentsItems.push(objToPush);
      // this.selectedFetchSummativeAssessmentsItems.push({});
    },
    getAvailableFormativeExams(item, rowIndex) {
      const selectedExamIds = this.formativeExamIds?.slice(0, rowIndex);
      return this.fetchFormativeAssessmentsItems.filter(exam => !selectedExamIds?.includes(exam.examId));
    },
    getAvailableSummativeExams(item, rowIndex) {
      const selectedExamIds = this.summativeExamids?.slice(0, rowIndex);
      return this.fetchSummativeAssessmentsItems.filter(exam => !selectedExamIds?.includes(exam.examId));
    },
    updateSelectedFormativeExam(item) {
      const selectedExam = this.fetchFormativeAssessmentsItems.filter((exam) => exam.examId === item.examId)[0]
      if (selectedExam) {
        Object.assign(item, selectedExam);
      }
      this.formativeExamIds.push(item.examId);
    },
    updateSelectedSummativeExam(item) {
      const selectedExam = this.fetchSummativeAssessmentsItems.filter((exam) => exam.examId === item.examId)[0]
      if (selectedExam) {
        Object.assign(item, selectedExam);
      }
      this.summativeExamIds.push(item.examId);
    },
    addSummativeExam() {
      let currentIndex = this.summativeAssessmentsItems.length + 1
      this.summativeAssessmentsItems.push({
        assessmentTag: 'Summative_Assessment_' + currentIndex
      });
    },
    sortByNumericValueInAscendingOrder(array, fieldToSortBy) {
      array.sort((a, b) => a[fieldToSortBy] - b[fieldToSortBy]);
      return array;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async fetchCos() {

      this.nbaData = await this.nbaRepositoryInstance.getCos({
        lmsInstituteId: this.selectedInstitute,
        semesterId: this.semId,
        subjectId: this.subjectId,
      });

      if (Array.isArray(this.nbaData.data)) {
        this.nbaData.data.forEach((nba, i) => {
          this.courseOutcomeData.push({
            Id: nba._id,
            coNumber: nba.CONumber,
            coName: nba.COName,
            coText: `CO ${nba.CONumber} - ${nba.COName}`,
          });
          this.sortByNumericValueInAscendingOrder(
            this.courseOutcomeData,
            "coNumber"
          );
        });
      }
    },
    async saveAssessmentPlan() {
      let arrayToSend = [];

      let tempTotalCieMarks = 0;
      this.formativeAssessmentsItems.forEach((item) => {
        let objToPush = {};
        objToPush.subjectId = this.subjectId
        objToPush.instituteId = this.selectedInstitute
        objToPush.semId = this.semId
        objToPush.courseYear = this.courseYear
        objToPush.department = this.selectedDepartment
        objToPush.title = item.title;
        objToPush.assessmentTag = item.assessmentTag;
        objToPush.threshold = item.threshold;
        objToPush.totalMarks = item.totalMarks;
        objToPush.reducedMarks = item.reducedMarks;
        objToPush.examDate = item.examDate;
        objToPush.resultDate = item.resultDate;
        objToPush.overallCOs = item.overallCOs;
        objToPush.addToCourseFile = item.addToCourseFile;
        objToPush.isForAttainment = item.settings ? item.settings.isForAttainment : false;
        objToPush.examFormat = 'Formative';
        objToPush.totalCieMarks = Number(this.totalCieMarks)
        tempTotalCieMarks += Number(item.reducedMarks);
        arrayToSend.push(objToPush);
      })
      if (tempTotalCieMarks !== this.subject.totalCieMarks) {
        showStatus("CIE toal marks and reduced marks not equal", 2000, 'warning', this);
        this.saveLoader = false;
        return
      }

      let tempTotalSeeMarks = 0;
      this.summativeAssessmentsItems.forEach((item) => {
        let objToPush = {};
        objToPush.subjectId = this.subjectId
        objToPush.instituteId = this.selectedInstitute
        objToPush.semId = this.semId
        objToPush.courseYear = this.courseYear
        objToPush.department = this.selectedDepartment
        objToPush.title = item.title;
        objToPush.assessmentTag = item.assessmentTag;
        objToPush.threshold = item.threshold;
        objToPush.totalMarks = item.totalMarks;
        objToPush.reducedMarks = item.reducedMarks;
        objToPush.examDate = item.examDate;
        objToPush.resultDate = item.resultDate;
        objToPush.overallCOs = item.overallCOs;
        objToPush.addToCourseFile = item.addToCourseFile;
        objToPush.isForAttainment = item.settings ? item.settings.isForAttainment : false;
        objToPush.examFormat = 'Summative';
        objToPush.totalSeeMarks = Number(this.totalSeeMarks)
        tempTotalSeeMarks = + Number(item.reducedMarks);
        arrayToSend.push(objToPush);
      })
      if (tempTotalSeeMarks !== this.subject.totalSeeMarks) {
        showStatus("SEE toal marks and reduced  marks not equal", 2000, 'warning', this);
        this.saveLoader = false;
        return
      }
      // this.examFormat = data.examFormat === 'Formative(Internal) exam'  ? 'Formative' : 'Summative'

      try {
        // let response = await this.newExamRepositoryInstance.createExamFromCourseFile(arrayToSend)
        // let response = await this.newExamRepositoryInstance.createOrUpdateExamAndSubjectExam(arrayToSend)
        if (response.status.status === 200) {
          // this.$store.commit("liveData/set_examId", this.exam?.examId);
          showStatus("Successfully saved assessment plan", 2000, 'success', this);

        } else {
          showStatus("Something went wrong", 2000, 'error', this);
        }
      } catch (error) {
        console.error(error);
      }
      this.saveLoader = false;
    },
    async saveExamDetails() {
      try {
        let finalArr = [...this.fetchSummativeAssessmentsItems, ...this.fetchFormativeAssessmentsItems]
        let arrToPush = []
        if (finalArr.length > 0) {
          finalArr.map((exam) => {
            if (exam.isChanged === true || exam.isNew === true) {
              if (exam.totalMarks !== undefined && exam.totalMarks !== 0) {
                exam.subjectIds = [this.subjectId]
              }
              // console.log('exam.totalMarks', exam.totalMarks);
              let obj = {
                instituteId: exam.instituteId,
                semId: exam.semId,
                department: exam.department,
                courseYear: exam.courseYear,
                examId: exam.isNew === true ? undefined : exam.examId,
                assessmentTag: exam.assessmentTag,
                title: exam.title,
                examFormat: exam.examFormat,
                subjectIds: [...new Set(exam.subjectIds)],
                settings: {
                  ...exam.settings,
                  isForAttainment: exam.isForAttainment ? exam.isForAttainment : false,
                },
                addToCourseFile: exam.addToCourseFile ? exam.addToCourseFile : false,
                subejctDetailsToUpadte: [{
                  instituteId: exam.instituteId,
                  threshold: Number(exam.threshold),
                  totalMarks: Number(exam.totalMarks),
                  reducedMarks: Number(exam.reducedMarks),
                  examDate: exam.examDate,
                  resultDate: exam.resultDate,
                  overallCOs: exam.overallCOs,
                  subjectId: this.subjectId,
                  remark: exam.remark,
                  modeOfConduction: exam.modeOfConduction,
                }]
              }
              if (exam.totalMarks === undefined || exam.totalMarks === 0) {
                obj.subejctDetailsToUpadte = []
              }

              arrToPush.push(obj)
            }
          })
        }
        // console.log('arrToPush', arrToPush);
        if (arrToPush.length > 0) {

          let response = await this.newExamRepositoryInstance.createOrUpdateExamAndSubjectExam(arrToPush)
        }
        await this.fetchExams()

      } catch (error) {
        console.error('error', error);
      }
    }
  }
};
</script>

<style src="./assessmentPlan.css" scoped></style>