<template src="./examsBasicDetails.html"></template>

<script>
import showStatus from '../../../NetworkManager/showStatus'
import NewExamRepository from "../../../Repository/NewExam";
import { createExcelThroughJsonForMultipleSheets, generateJsonFromExcel, generateFromJson } from '../../../utils/excel';

import { v4 } from "uuid"
export default {
    name : 'examsBasicDetails',
    data (){
        return{
            progressIndicator1: 0,
            examName: '',
            descriptionOfExam: '',
            backgroundColor: "#E7E7FFCF",
            calenderIcon: 'mdi-calendar-month-outline',
            rules: {
            required: (value) => !!value || "This field is required",
            totalMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.',
            passingMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.' 
            },
            examFormat: null,
            startDateModel: false,
            startDate:null,
            endDateModel: false,
            endDate:null,
            attainmentCheckbox : false,
            examFormCheckbox : false,
            hallTicketCheckbox : false,
            invigilationCheckbox : false,
            exam:{},
            examToEdit : null,
            examId: null,
            uploadBasicDetailsViaExcel : false,
            basicDetailsExcelFile:null,
            schema : {},
            examTabDependecies: {},
        }
    },
    async created(){
        this.newExamRepositoryInstance = new NewExamRepository(this);
        this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
        this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
        this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
        this.examTabDependecies =
      this.$store.getters["liveData/get_examTabDependencies"];
        this.examId =  this.$store.getters["liveData/get_examId"];
        if(this.examId !== null){
            await this.getExam()
            this.fillExamData();
        }
        // if (this.examToEdit) {
        //     console.log('only then do this')
        //     this.fetchExamData()
        // }
        // console.log("exam to edit", this.examToEdit)

    },
    activated() {
      let newValue = this.$store.state.liveData.examTabDependencies;
      let flag = false;
      Object.entries(newValue).forEach(([key, value]) => {
        if (value['basicDetailsTab'] === true) {
          flag = true;
          value['basicDetailsTab'] = false;
        }
      });
      if (flag === true) {
        if(this.examId !== null){
            this.fillExamData();
        }
      }
    },
    methods: {
        async getExam() {
            try{
                const objToPush = {
                    instituteId: this.selectedInstitute,
                    semId: this.selectedSemester.semId,
                    department: this.selectedDepartment,
                    courseYear: this.selectedCourseYear,
                    examId: this.examId,
                }
                let res = await this.newExamRepositoryInstance.getExam(objToPush)
                this.exam = res.exam;
                // this.exam = res
            }catch (error) {
                console.error("error",error)
            }
        },
        async fillExamData(){
            this.examName = this.exam.title
            this.descriptionOfExam = this.exam.description
            this.examFormat = this.exam.examFormat
            this.startDate = this.exam.publishDate?.split("T0")[0]
            this.endDate = this.exam.dueDate?.split("T0")[0]
            this.attainmentCheckbox = this.exam.settings.isForAttainment
            this.examFormCheckbox = this.exam.settings.isExamFormIncluded
            this.hallTicketCheckbox = this.exam.settings.isHallTicketIncluded
            this.invigilationCheckbox = this.exam.settings.isInvigilationIncluded
            this.updateExamName()
            this.updateExamFormat()
            this.updateExamFormCheckbox()
            this.updateHallTicketCheckbox()
            this.calculateProgress()
        },
        async validateBasicDetails() {
            if (this.examType === '' || this.examName === '' || this.startDate === null || this.endDate === null ) {
                showStatus("Please Fill Required Fields First", 9000, "warning", this);
            } else if (new Date(this.endDate) < new Date(this.startDate)) {
                showStatus("End Date must be later than Start Date", 9000, "warning", this);
            }  else {
                await this.saveBasicDetails()
            }
        },

        async saveBasicDetails() {
        // let examTypeName = 'Exam'
        // if (this.examType === 'Assignment') examTypeName = 'Non rubric'
        // else if (this.examType === 'Online') examTypeName = 'Online Exam'
        // else if (this.examType === 'Theory') examTypeName = 'Offline Exam'
        const examBasicDetailsObject = {
            instituteId: this.selectedInstitute,
            semId: this.selectedSemester.semId,
            department: this.selectedDepartment,
            courseYear: this.selectedCourseYear,
            // subjectIds: this.subjectIds,
            title: this.examName,
            examType: 'Exam',
            publishDate: this.startDate,
            dueDate: this.endDate,
            settings: {},
            isDeleted: false,
            examFormat : this.examFormat,
            examAttemptNumber : 1
        }
        if (this.exam?.examId) {
            examBasicDetailsObject.examId = this.exam?.examId
        }
        if (this.descriptionOfExam !== '') {
            examBasicDetailsObject.description = this.descriptionOfExam
        }
        if (this.attainmentCheckbox) {
            examBasicDetailsObject.settings.isForAttainment = this.attainmentCheckbox;
        }
        if (this.examFormCheckbox) {
            examBasicDetailsObject.settings.isExamFormIncluded = this.examFormCheckbox;
        }
        if (this.hallTicketCheckbox) {
            examBasicDetailsObject.settings.isHallTicketIncluded = this.hallTicketCheckbox;
        }
        if (this.invigilationCheckbox) {
            examBasicDetailsObject.settings.isInvigilationIncluded = this.invigilationCheckbox;
        }
        try {
            let response = await this.newExamRepositoryInstance.createExam(examBasicDetailsObject)
            this.exam = response.exam;
            if (response.status.status === 200) {
                this.$store.commit("liveData/set_examId", this.exam?.examId);
                showStatus("Successfully saved basic details of the exam.", 2000, 'success', this);
                this.updateExamName()
                this.updateExamFormat()
                this.updateExamFormCheckbox()
                this.updateHallTicketCheckbox()
            } else {
              showStatus("Something went wrong", 2000, 'error', this);
            }
        } catch (error) {
            console.error(error);
        }
        for (let key in this.examTabDependecies["basicDetailsTab"]) {
        this.examTabDependecies["basicDetailsTab"][key] = true;
        }
        },
        calculateProgress() {
            this.progressIndicator1 = 0;
            if (this.examName !== '') this.progressIndicator1 += 11.11
            if (this.examType !== '') this.progressIndicator1 += 11.11
            if (this.descriptionOfExam !== '') this.progressIndicator1 += 11.11
            if (this.startDate !== null) this.progressIndicator1 +=11.11
            if (this.endDate !== null) this.progressIndicator1 += 11.11
            if (this.attainmentCheckbox) this.progressIndicator1 += 11.11
            if (this.examFormCheckbox) this.progressIndicator1 += 11.11
            if (this.hallTicketCheckbox) this.progressIndicator1 += 11.11
            if (this.invigilationCheckbox) this.progressIndicator1 += 11.11
            
        },
        goToNextPage(){
            this.$emit('nextButtonClick');
        },
        updateExamName() {
        this.$emit("examNameChanged", this.examName);
        },
        updateExamFormat() {
        this.$emit("examFormatChanged", this.examFormat);
        },
        updateExamFormCheckbox(){
         this.$emit("examFormCheckboxChanged", this.examFormCheckbox);
        },
        updateHallTicketCheckbox(){
         this.$emit("hallTicketCheckboxChanged", this.hallTicketCheckbox);
        },
        setSchema(){
            this.schema = {
                Sheet1: {
                'Name': {
                    validation: {
                    type: 'textLength',
                    // required: true
                    },
                    variableName: 'examName'
                },
                'Exam Type': {
                    validation: {
                    type: 'list',
                    values: ['Formative(Internal) exam', 'Summative(External)'],
                    // required: true
                    },
                    variableName: 'examFormat'
                },
                'Description': {
                    validation: {
                    type: 'textLength',
                    // required: true
                    },
                    variableName: 'descriptionOfExam'
                },
                'Start Date (MM/DD/YYYY)': {
                    validation: {
                    type: 'textLength',
                    // required: true
                    },
                    variableName: 'startDate'
                },
                'End Date (MM/DD/YYYY)': {
                    validation: {
                    type: 'textLength',
                    // required: true
                    },
                    variableName: 'endDate'
                },
                }
            }
        },
        async downloadBasicDetailsSample(){
            this.setSchema()
            this.excelLink = await generateFromJson(this.schema)
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = this.excelLink;
            link.target = '_blank'; // Open in a new tab
            // Trigger the download
            link.click();
        },
        async uploadBasicDetailsExcel(){
            this.setSchema()
            let res = await generateJsonFromExcel(this.basicDetailsExcelFile, this.schema, false)
            const {errors, data} = res;
            // this.exam = data.Sheet1[0];
            // this.fillExamData()
            this.refillDataFromExcel(data.Sheet1[0]);
            this.uploadBasicDetailsViaExcel = false;
        },
        refillDataFromExcel(data){
            this.examName = data.examName
            this.descriptionOfExam = data.descriptionOfExam
            this.examFormat = data.examFormat === 'Formative(Internal) exam'  ? 'Formative' : 'Summative'
            this.startDate =  new Date(data.startDate).toISOString().split("T0")[0];
            this.endDate =   new Date(data.endDate).toISOString().split("T0")[0];
            // this.endDate = data.endDate
            // this.attainmentCheckbox = this.exam.settings.isForAttainment
            // this.examFormCheckbox = this.exam.settings.isExamFormIncluded
            // this.hallTicketCheckbox = this.exam.settings.isHallTicketIncluded
            // this.invigilationCheckbox = this.exam.settings.isInvigilationIncluded
            this.updateExamName()
            this.updateExamFormat()
            this.calculateProgress()
        }
    }
}

</script>

<style src="./examsBasicDetails.css" scoped></style>