<template src="./examMarks.html"></template>

<script>
import showStatus from '../../../NetworkManager/showStatus'
import NewExamRepository from "../../../Repository/NewExam";
import ExamSubjectAndQuestionRepository from "../../../Repository/ExamSubjectAndQuestion"
import SubjectRepository from "../../../Repository/Subject";
import { v4 } from "uuid"
export default {
    name: 'examMarks',
    data() {
        return {
            progressIndicator1: 0,
            examName: '',
            descriptionOfExam: '',
            backgroundColor: "#E7E7FFCF",
            calenderIcon: 'mdi-calendar-month-outline',
            rules: {
                required: (value) => !!value || "This field is required",
                totalMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.',
                passingMarks: value => /^\d*$/.test(value) || 'Only numbers are allowed.'
            },
            examFormat: null,
            startDateModel: false,
            startDate: null,
            endDateModel: false,
            endDate: null,
            attainmentCheckbox: false,
            examFormCheckbox: false,
            hallTicketCheckbox: false,
            invigilationCheckbox: false,
            exam: {},
            examToEdit: null,
            examId: null,
            secondaryTabItems: ['Student Marks'],
            tabs1: 0,
            search: '',
            selectedSubjects: [],
            studentsSearch: '',
            createdQuestions: [],
            headers: [
                { text: 'Sr No.', value: 'srNo', sortable: false },
                { text: 'Subject code', value: 'subjectCode', sortable: false },
                { text: 'Subject name', value: 'subjectName', sortable: true },
                { text: 'Total marks', value: 'totalMarks', sortable: false },
                { text: 'Passing Marks', value: 'passingMarks', sortable: false },
                { text: 'Student Marks', value: 'studentMarks', sortable: false },
                { text: 'Marking Method', value: 'markingMethod', sortable: false },
                { text: 'Total Students Passed', value: 'passedStudents', sortable: false },
                { text: 'Total Students Failed', value: 'failedStudents', sortable: false },
            ],
        }
    },
    created() {
        this.newExamRepositoryInstance = new NewExamRepository(this);
        this.subjectRepositoryInstance = new SubjectRepository(this);
        this.examSubjectAndQuestionRepositoryInstance = new ExamSubjectAndQuestionRepository(this);
        this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
        this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
        this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
        this.examId = this.$store.getters["liveData/get_examId"];
        if (this.examId !== null) {
            this.fillExamData();
        }
        this.fetchData();
        // if (this.examToEdit) {
        //     console.log('only then do this')
        //     this.fetchExamData()
        // }
        // console.log("exam to edit", this.examToEdit)

    },
    activated() {
    // Your logic here
    let newValue = this.$store.state.liveData.examTabDependencies;
    let flag = false;
    Object.entries(newValue).forEach(([key, value]) => {
      if (value["studentMarksTab"] === true) {
        flag = true;
        value["studentMarksTab"] = false;
      }
    });
    if (flag === true) {
      this.fetchData();
    }
  },
    methods: {
        async fetchData() {
            await this.fetchSubjects()
            await this.getExam();
            await this.getSubjectData();
        },
        async fetchSubjects() {
            const allSubjects = await this.subjectRepositoryInstance.getSubjectsOfACourseYearInASemester({
                instituteId: this.selectedInstitute,
                semId: this.selectedSemester.semId,
                department: this.selectedDepartment,
                courseYear: this.selectedCourseYear,
            })
            this.subjects = allSubjects.map((s) => {
                const { subjectCode, subjectName, subjectId } = s
                return { subjectCode, subjectName, subjectId }
            })
            // console.log("response of the object", response)
        },
        async getExam() {
            try {
                const objToPush = {
                    instituteId: this.selectedInstitute,
                    semId: this.selectedSemester.semId,
                    department: this.selectedDepartment,
                    courseYear: this.selectedCourseYear,
                    examId: this.examId,
                }
                let res = await this.newExamRepositoryInstance.getExam(objToPush)
                this.exam = res.exam;
                // this.exam = res
            } catch (error) {
                console.error("error", error)
            }
        },
        async getSubjectData() {
            let response = await this.examSubjectAndQuestionRepositoryInstance.getExamMultipleSubjectQuestions({
                instituteId: this.selectedInstitute,
                examId: this.examId,
                subjectIds: this.exam.subjectIds
            })
            let subjectsData = response.result;

            let filteredSubjects = this.subjects
                .map((subject) => {
                    let dataFound = subjectsData.find((d) => d?.subjectId === subject?.subjectId);
                    if (dataFound) {
                        return {
                            subjectCode: subject.subjectCode,
                            subjectId: subject.subjectId,
                            subjectName: subject.subjectName,
                            duration: dataFound.duration,
                            threshold: dataFound?.threshold || "-",
                            passingMarks: dataFound?.passingMarks || "-",
                            totalMarks: dataFound?.totalMarks || "-",
                            examDate: dataFound.examDate,
                            examTime: dataFound.examTime,
                            allocatedStudents: dataFound.allocatedStudents,
                            allQuestions: dataFound.allQuestions
                        };
                    }
                    return undefined;
                })
                .filter((item) => item !== undefined);

            this.selectedSubjects = filteredSubjects;
            for(let i = 0 ; i < this.selectedSubjects.length; i++) {
                this.createdQuestions = this.selectedSubjects[i]
                this.getQuestionsOfSelectedSet(this.selectedSubjects[i])
            }
        },
        async fillExamData() {
            await this.getExam()
            this.examName = this.exam.title
            this.descriptionOfExam = this.exam.description
            this.examFormat = this.exam.examFormat
            this.startDate = this.exam.publishDate.split("T0")[0]
            this.endDate = this.exam.dueDate.split("T0")[0]
            this.attainmentCheckbox = this.exam.settings.isForAttainment
            this.examFormCheckbox = this.exam.settings.isExamFormIncluded
            this.hallTicketCheckbox = this.exam.settings.isHallTicketIncluded
            this.invigilationCheckbox = this.exam.settings.isInvigilationIncluded
        },
        // async fetchAssignmentQuestions() {
        //     if (this.exam !== null) {
        //         const assignmentQuestions = await this.examSubjectAndQuestionRepositoryInstance.getExamSubjectQuestions({
        //             instituteId: this.selectedInstitute,
        //             examId: this.examId,
        //             subjectId: this.prop_selectedSubjectId
        //         })
        //         this.createdQuestions = assignmentQuestions.result
        //         if (this.createdQuestions.overallCOs.length > 0 || this.createdQuestions.overallBlooms.length > 0) {
        //             this.row = 'radio-1'
        //             this.overallCosBloomsData = true
        //         }
        //         this.overallCos = this.createdQuestions.overallCOs
        //         this.overallBlooms = this.createdQuestions.overallBlooms
        //     }
        //     if (this.createdQuestions?.allQuestions.length > 0) {
        //         this.getAllSections()
        //         this.activeSet = {
        //             no: 1,
        //             setName: 'set 1'
        //         }
        //         this.setActiveSet(this.activeSet)
        //         this.createdSets = []
        //         this.setsObjectForQuestions = {}
        //         for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
        //             if (this.createdQuestions.allQuestions[i].questionType === 'Set') {
        //                 const questionStatement = this.createdQuestions.allQuestions[i].questionStatement;
        //                 this.createdSets.push({
        //                     no: i + 1,
        //                     setName: questionStatement
        //                 })
        //                 if (!Object.prototype.hasOwnProperty.call(this.setsObjectForQuestions, questionStatement)) {
        //                     this.setsObjectForQuestions[questionStatement] = [];
        //                 }
        //                 this.setsObjectForQuestions[questionStatement].push(...this.getQuestionsOfSelectedSet({ setName: questionStatement, forShowingQuestions: true }, null));
        //             }
        //         }
        //         this.questionsArray = this.setsObjectForQuestions['set 1']
        //     }
        // },
        // getAllSections() {
        //     this.allSections = []
        //     for (let i = 0; i < this.createdQuestions?.allQuestions.length; i++) {
        //         if (this.createdQuestions?.allQuestions[i].questionType === 'Set') {
        //             for (let j = 0; j < this.createdQuestions.allQuestions[i].question.length; j++) {
        //                 this.allSections.push({ questionId: this.createdQuestions.allQuestions[i].question[j].questionId, sectionName: this.createdQuestions.allQuestions[i].question[j].questionStatement, obtainedMarks: null, questionMarks: this.createdQuestions.allQuestions[i].question[j].questionMarks })
        //             }
        //         }
        //     }
        //     for (let i = 0; i < this.students.length; i++) {
        //         this.students[i].allSections = this.allSections
        //     }
        // },
        // setActiveSet(set) {
        //     this.setsObjectForQuestions[this.activeSet.setName] = this.questionsArray
        //     this.activeSet = set;
        //     this.questionsArray = this.setsObjectForQuestions[this.activeSet.setName]
        // },
        getQuestionsOfSelectedSet(questionObject) {
            let count = 0
            let anyQuestionFound = false
            let setQuestions = [];
            for (let i = 0; i < this.createdQuestions.allQuestions.length; i++) {
                if (this.createdQuestions.allQuestions[i].questionType === 'Set') {
                    setQuestions = this.createdQuestions.allQuestions[i].question;
                }
            }
            this.seperatedSetQuestions = []

            for (let i = 0; i < setQuestions?.length; i++) {
                anyQuestionFound = false
                count++
                this.seperatedSetQuestions.push({
                    sectionNames: setQuestions[i].questionNumber,
                    question: setQuestions[i].questionStatement,
                    questionId: setQuestions[i].questionId,
                    questionMarks: setQuestions[i].questionMarks,
                    selectedCos: setQuestions[i]?.cos,
                    cos: this.coNames,
                    selectedBlooms: setQuestions[i].blooms,
                    blooms: this.bloomsNames
                })
                if (setQuestions[i].question?.length > 0) {
                    const sectionQuestion = setQuestions[i].question
                    for (let j = 0; j < sectionQuestion?.length; j++) {
                        if (sectionQuestion[j].questionType === 'OrQuestion') {
                            anyQuestionFound = false
                            this.seperatedSetQuestions.push({
                                questionNumber: 'OR'
                            })
                        }
                        anyQuestionFound = false
                        count++
                        this.seperatedSetQuestions.push({
                            questionNumber: sectionQuestion[j]?.questionNumber,
                            question: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 'Solve any (' + sectionQuestion[j].NumberOfQuestionsToSolve + ')' : sectionQuestion[j].questionStatement,
                            tryl: sectionQuestion[j]?.NumberOfQuestionsToSolve,
                            anyCount: sectionQuestion[j]?.NumberOfQuestionsToSolve,
                            outOfCount: sectionQuestion[j]?.question?.length,
                            solveAnyCount: sectionQuestion[j]?.questionStatement === 'Solve any_out of' ? 0 : sectionQuestion[j]?.NumberOfQuestionsToSolve,
                            questionId: sectionQuestion[j]?.questionId,
                            questionMarks: sectionQuestion[j]?.questionMarks,
                            selectedCos: sectionQuestion[j]?.cos,
                            cos: this.coNames,
                            selectedBlooms: sectionQuestion[j]?.blooms,
                            blooms: this.bloomsNames
                        })
                        if (sectionQuestion[j].NumberOfQuestionsToSolve) {
                            anyQuestionFound = true
                            this.seperatedSetQuestions.push({
                                subQnNo: 'Any ' + sectionQuestion[j].NumberOfQuestionsToSolve,
                                anyCount: sectionQuestion[j].NumberOfQuestionsToSolve,
                                anyStatement: true
                            })
                        }
                        if (sectionQuestion[j]?.question?.length > 0) {
                            const subQuestion = sectionQuestion[j]?.question
                            for (let k = 0; k < subQuestion.length; k++) {
                                if (subQuestion[k].questionType === 'OrSubQuestion') {
                                    this.seperatedSetQuestions.push({
                                        subQnNo: 'OR',
                                        anyStatement: false
                                    })
                                }
                                if (anyQuestionFound) {
                                    count++
                                    this.seperatedSetQuestions.push({
                                        subQnNo: subQuestion[k].questionNumber,
                                        anySubQuestion: true,
                                        anyStatement: true,
                                        question: subQuestion[k].questionStatement,
                                        questionId: subQuestion[k]?.questionId,
                                        questionMarks: subQuestion[k].questionMarks,
                                        selectedCos: subQuestion[k].cos,
                                        cos: this.coNames,
                                        selectedBlooms: subQuestion[k].blooms,
                                        blooms: this.bloomsNames
                                    })
                                } else {
                                    anyQuestionFound = false
                                    count++
                                    this.seperatedSetQuestions.push({
                                        subQnNo: subQuestion[k].questionNumber,
                                        anyStatement: false,
                                        question: subQuestion[k].questionStatement,
                                        questionId: subQuestion[k]?.questionId,
                                        questionMarks: subQuestion[k].questionMarks,
                                        selectedCos: subQuestion[k].cos,
                                        cos: this.coNames,
                                        selectedBlooms: subQuestion[k].blooms,
                                        blooms: this.bloomsNames
                                    })
                                }
                            }
                        }
                    }
                }
            }
            questionObject.queationsCount = count
            // this.questionWiseMarksDialog = true
            // if (!set.forShowingQuestions && this.studentData?.answers) {
            //     const studentDataMap = new Map(this.studentData?.answers.map(data => [data.questionId, data]));
            //     for (const setQuestion of this.seperatedSetQuestions) {
            //         const studentDataForQuestion = studentDataMap.get(setQuestion.questionId);
            //         if (studentDataForQuestion) {
            //             setQuestion.assignMarks = studentDataForQuestion.obtainedMarks;
            //         }
            //     }
            //     this.questionWiseMarksDialog = true
            // } else return this.seperatedSetQuestions
        },
        goToAddMarks(item) {
            this.$router.push({
                name: 'examStudentMarks',
                params: {
                    // prop_department: this.prop_department,
                    // prop_course: this.prop_course,
                    prop_selectedSubject: item.subjectName,
                    prop_selectedSubjectId: item.subjectId,
                    prop_tabNumber: 7,
                    prop_allSubjects: this.selectedSubjects,
                    // prop_typeDivGrpBatch: this.prop_typeDivGrpBatch,
                    // prop_selectedDivision: this.prop_selectedDivision,
                    // prop_selectedBatch: this.prop_selectedBatch,
                    // prop_selectedGroup: this.prop_selectedGroup
                }
            })
        }
    }
}

</script>

<style src="./examMarks.css"></style>