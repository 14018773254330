const state = {
  reminderDays: 0,
  relativeInstitutes: [],
  loading: false,
  selectedActivity: 0,
  percentage: null,
  selectedActivityName: "",
  statusMessage: "Success!",
  statusDuration: 2000,
  statusType: "success",
  snackbar: false,
  editingSemester: {},
  examDepartment: '',
  examCourseYear: '',
  selectedSemester: {},
  selectedAdmission: {},
  selectedExam: {},
  selectedExamSubject: {},
  selectedExamTimetable: {},
  selectedTopic: {},
  admissionSubjects: [],
  admissionSemester: "",
  admissionInstitute: "",
  admissionDepartment: "",
  admissionCourseYear: "",
  admissionDivision: "",
  showAdmissionModule: false,
  brandingName: "",
  brandingLogo: "",
  senderMail: "",
  senderMailPassword: "",
  displayName: "",
  subjectDescription: "",
  instituteStudentBrandingName: "",
  instituteStudentLogoLink: "",
  emailBody: "",
  signatureLogo: "",
  signatureLogoName: "",
  selectedSubject: "",
  instituteType: "",
  instituteCieSee: "",
  attainmentCalculationType: "",
  allowedModule: [],
  isInstituteForNaacOrNBA: false,
  isCorporateInstitute: false,
  cieSeePreference: "",
  selectedBuilding: "",
  editingRoomInventory: null,
  instituteAddress: '',
  studentAttendance: [],
  tokenForQr: '',
  isSubAdmin: false,
  accessibleDepartment: [],
  accessibleCourseYearForSubAdmin: [],
  examId : "",
  examTabDependencies: {},
  subjectTypes: {},
  proxyData: [],
  presentStudentArr: []
};

const getters = {
  // getters for state
  get_relativeInstitutes: (state) => state.relativeInstitutes,
  get_reminderDays: (state) => state.reminderDays,
  get_loading: (state) => state.loading,
  get_selectedActivity: (state) => state.selectedActivity,
  get_percentage: (state) => state.percentage,
  get_selectedActivityName: (state) => state.selectedActivityName,
  get_statusMessage: (state) => state.statusMessage,
  get_statusDuration: (state) => state.statusDuration,
  get_statusType: (state) => state.statusType,
  get_editingSemester: (state) => state.editingSemester,
  get_examCourseYear: (state) => state.examCourseYear,
  get_examDepartment: (state) => state.examDepartment,
  get_selectedExam: (state) => state.selectedExam,
  get_selectedExamSubject: (state) => state.selectedExamSubject,
  get_selectedExamTimetable: (state) => state.selectedExamTimetable,
  get_selectedTopic: (state) => state.selectedTopic,
  get_admissionSubjects: (state) => state.admissionSubjects,
  get_admissionSemester: (state) => state.admissionSemester,
  get_admissionInstitute: (state) => state.admissionInstitute,
  get_admissionDepartment: (state) => state.admissionDepartment,
  get_admissionCourseYear: (state) => state.admissionCourseYear,
  get_admissionDivision: (state) => state.admissionDivision,
  get_showAdmissionModule: (state) => state.showAdmissionModule,
  get_brandingLogo: (state) => state.brandingLogo,
  get_brandingName: (state) => state.brandingName,
  get_instituteStudentLogoLink: (state) => state.instituteStudentLogoLink,
  get_instituteStudentBrandingName: (state) =>
    state.instituteStudentBrandingName,
  get_senderMail: (state) => state.senderMail,
  get_senderMailPassword: (state) => state.senderMailPassword,
  get_displayName: (state) => state.displayName,
  get_subjectDescription: (state) => state.subjectDescription,
  get_emailBody: (state) => state.emailBody,
  get_signatureLogo: (state) => state.signatureLogo,
  get_signatureLogoName: (state) => state.signatureLogoName,
  get_signatureLogoName: (state) => state.signatureLogoName,
  get_selectedAdmission: (state) => state.selectedAdmission,
  get_selectedSubject: (state) => state.selectedSubject,
  get_instituteType: (state) => state.instituteType,
  get_instituteCieSee: (state) => state.instituteCieSee,
  get_attainmentCalculationType: (state) => state.attainmentCalculationType,
  get_allowedModule: (state) => state.allowedModule,
  get_isInstituteForNaacOrNBA: (state) => state.isInstituteForNaacOrNBA,
  get_isCorporateInstitute: (state) => state.isCorporateInstitute,
  get_cieSeePreference: (state) => state.cieSeePreference,
  get_selectedBuilding: (state) => state.selectedBuilding,
  get_selectedEditingRoomInventory: (state) => state.editingRoomInventory,
  get_instituteAddress: (state) => state.instituteAddress,
  get_studentAttendance: state => state.studentAttendance,
  get_tokenForQr: state => state.tokenForQr,
  get_isSubAdmin: state => state.isSubAdmin,
  get_accessibleDepartment: state => state.accessibleDepartment,
  get_accessibleCourseYearForSubAdmin: state => state.accessibleCourseYearForSubAdmin,
  get_examId: (state) => state.examId,
  get_examTabDependencies : (state) => state.examTabDependencies,
  get_subjectTypes : (state) => state.subjectTypes,
  get_proxyData: state => state.proxyData,
  get_presentStudentArr: state => state.presentStudentArr,
};

const mutations = {
  // mutations for state
  set_reminderDays: (state, value) => {
    state.reminderDays = value;
  },
  set_relativeInstitutes: (state, value) => {
    state.relativeInstitutes = value;
  },
  set_selectedAdmission: (state, value) => {
    state.selectedAdmission = value;
  },
  set_loading: (state, value) => {
    state.loading = value;
  },
  set_brandingName: (state, value) => {
    state.brandingName = value.brandingName;
  },
  set_brandingLogo: (state, value) => {
    state.brandingLogo = value.brandingLogo;
  },
  set_selectedActivity: (state, value) => {
    state.selectedActivity = value;
  },
  set_percentage: (state, value) => {
    state.percentage = value;
  },
  set_selectedActivityName: (state, value) => {
    state.selectedActivityName = value;
  },
  set_statusMessage: (state, value) => {
    state.statusMessage = value;
  },
  set_statusDuration: (state, value) => {
    state.statusDuration = value;
  },
  set_statusType: (state, value) => {
    state.statusType = value;
  },
  set_snackbar: (state, value) => {
    state.snackbar = value;
  },
  set_editingSemester: (state, value) => {
    state.editingSemester = value;
  },
  set_examDepartment: (state, value) => {
    state.examDepartment = value;
  },
  set_examCourseYear: (state, value) => {
    state.examCourseYear = value;
  },
  set_selectedExam: (state, value) => {
    state.selectedExam = value;
  },
  set_selectedExamSubject: (state, value) => {
    state.selectedExamSubject = value;
  },
  set_selectedExamTimetable: (state, value) => {
    state.selectedExamTimetable = value;
  },
  set_selectedTopic: (state, value) => {
    state.selectedTopic = value;
  },
  set_admissionSubjects: (state, value) => {
    state.admissionSubjects = value;
  },
  set_admissionSemester: (state, value) => {
    state.admissionSemester = value;
  },
  set_admissionInstitute: (state, value) => {
    state.admissionInstitute = value.admissionInstitute;
  },
  set_admissionDepartment: (state, value) => {
    state.admissionDepartment = value;
  },
  set_admissionCourseYear: (state, value) => {
    state.admissionCourseYear = value;
  },
  set_admissionDivision: (state, value) => {
    state.admissionDivision = value;
  },
  set_showAdmissionModule: (state, value) => {
    state.showAdmissionModule = value;
  },
  set_senderMail: (state, value) => {
    state.senderMail = value.senderMail;
  },
  set_senderMailPassword: (state, value) => {
    state.senderMailPassword = value.senderMailPassword;
  },
  set_displayName: (state, value) => {
    state.displayName = value.displayName;
  },
  set_subjectDescription: (state, value) => {
    state.subjectDescription = value.subjectDescription;
  },
  set_instituteStudentBrandingName: (state, value) => {
    state.instituteStudentBrandingName = value.instituteStudentBrandingName;
  },
  set_instituteStudentLogoLink: (state, value) => {
    state.instituteStudentLogoLink = value.instituteStudentLogoLink;
  },
  set_emailBody: (state, value) => {
    state.emailBody = value.emailBody;
  },
  set_signatureLogo: (state, value) => {
    state.signatureLogo = value.signatureLogo;
  },
  set_signatureLogoName: (state, value) => {
    state.signatureLogoName = value.signatureLogoName;
  },
  set_selectedSubject: (state, value) => {
    state.selectedSubject = value;
  },
  set_instituteCieSee: (state, value) => {
    state.instituteCieSee = value;
  },
  set_attainmentCalculationType: (state, value) => {
    state.attainmentCalculationType = value;
  },
  set_instituteType: (state, value) => {
    state.instituteType = value;
  },
  set_isInstituteForNaacOrNBA: (state, value) => {
    state.isInstituteForNaacOrNBA = value;
  },
  set_allowedModule: (state, value) => {
    console.log("value", value.allowedModule);
    state.allowedModule = value.allowedModule;
  },
  set_isCorporateInstitute: (state, value) => {
    console.log("value", value);
    state.isCorporateInstitute = value;
  },
  set_cieSeePreference: (state, value) => {
    console.log("value1", value.cieSeePreference);
    state.cieSeePreference = value.cieSeePreference;
  },
  set_selectedBuilding: (state, value) => {
    state.selectedBuilding = value;
  },
  set_selectedEditingRoom: (state, value) => {
    state.editingRoomInventory = value;
  },
  set_instituteAddress: (state, value) => {
    state.instituteAddress = value;
  },
  set_studentAttendance: (state, value) => {
    state.studentAttendance = value
  },
  set_tokenForQr: (state, value) => {
    state.tokenForQr = value
  },
  set_isSubAdmin: (state, value) => {
    state.isSubAdmin = value
  },
  set_accessibleDepartment: (state, value) => {
    state.accessibleDepartment = value
  },
  set_accessibleCourseYearForSubAdmin: (state, value) => {
    state.accessibleCourseYearForSubAdmin = value
  },
  set_examId: (state, value) => {
    state.examId = value
  },
  set_examTabDependencies: (state, value) => {
    state.examTabDependencies = value
  },

  set_proxyData: (state, value) => {
    state.proxyData = value
  },

  set_presentStudentArr: (state, value) => {
    state.presentStudentArr = value
  },
  set_subjectTypes: (state, value) => {
    state.subjectTypes = value
  },
  //mailing details
  reset(state) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },
};

const actions = {
  // actions for state
  set_reminderDays: ({ commit, state }, value) => {
    commit("set_reminderDays", value);
    return state.message;
  },
  set_relativeInstitutes: ({ commit, state }, value) => {
    commit("set_relativeInstitutes", value);
    return state.message;
  },
  set_selectedAdmission: ({ commit, state }, value) => {
    commit("set_selectedAdmission", value);
    return state.message;
  },
  set_loading: ({ commit, state }, value) => {
    commit("set_loading", value);
    return state.message;
  },
  set_brandingLogo: ({ commit, state }, value) => {
    commit("set_brandingLogo", value);
    return state.message;
  },
  set_brandingName: ({ commit, state }, value) => {
    commit("set_brandingName", value);
    return state.message;
  },
  set_selectedActivity: ({ commit, state }, value) => {
    commit("set_selectedActivity", value);
    return state.message;
  },
  set_percentage: ({ commit, state }, value) => {
    commit("set_percentage", value);
    return state.message;
  },
  set_admissionSubjects: ({ commit, state }, value) => {
    commit("set_admissionSubjects", value);
    return state.message;
  },
  set_admissionSemester: ({ commit, state }, value) => {
    commit("set_admissionSemester", value);
    return state.message;
  },
  set_admissionInstitute: ({ commit, state }, value) => {
    commit("set_admissionInstitute", value);
    return state.message;
  },
  set_admissionDepartment: ({ commit, state }, value) => {
    commit("set_admissionDepartment", value);
    return state.message;
  },
  set_admissionCourseYear: ({ commit, state }, value) => {
    commit("set_admissionCourseYear", value);
    return state.message;
  },
  set_admissionDivision: ({ commit, state }, value) => {
    commit("set_admissionDivision", value);
    return state.message;
  },
  set_instituteStudentLogoLink: ({ commit, state }, value) => {
    commit("set_instituteStudentLogoLink", value);
    return state.message;
  },
  set_instituteStudentBrandingName: ({ commit, state }, value) => {
    commit("set_instituteStudentBrandingName", value);
    return state.message;
  },
  set_senderMail: ({ commit, state }, value) => {
    commit("set_senderMail", value);
    return state.message;
  },
  set_senderMailPassword: ({ commit, state }, value) => {
    commit("set_senderMailPassword", value);
    return state.message;
  },
  set_displayName: ({ commit, state }, value) => {
    commit("set_displayName", value);
    return state.message;
  },
  set_subjectDescription: ({ commit, state }, value) => {
    commit("set_subjectDescription", value);
    return state.message;
  },
  set_emailBody: ({ commit, state }, value) => {
    commit("set_emailBody", value);
    return state.message;
  },
  set_signatureLogo: ({ commit, state }, value) => {
    commit("set_signatureLogo", value);
    return state.message;
  },
  set_signatureLogoName: ({ commit, state }, value) => {
    commit("set_signatureLogoName", value);
    return state.message;
  },
  set_selectedSubject: ({ commit, state }, value) => {
    commit("set_sselectedSubject", value);
    return state.message;
  },
  set_instituteCieSee: ({ commit, state }, value) => {
    commit("set_instituteCieSee", value);
    return state.message;
  },
  set_attainmentCalculationType: ({ commit, state }, value) => {
    commit("set_attainmentCalculationType", value);
    return state.message;
  },
  set_instituteType: ({ commit, state }, value) => {
    commit("set_instituteType", value);
    return state.message;
  },
  set_allowedModule: ({ commit, state }, value) => {
    commit("set_allowedModule", value);
    console.log("value2", value);
    return state.message;
  },
  set_isInstituteForNaacOrNBA: ({ commit, state }, value) => {
    commit("set_isInstituteForNaacOrNBA", value);
    // console.log('value2', value);
    return state.message;
  },
  set_isCorporateInstitute: ({ commit, state }, value) => {
    commit("set_isCorporateInstitute", value);
    // console.log('value2', value);
    return state.message;
  },
  set_cieSeePreference: ({ commit, state }, value) => {
    commit("set_cieSeePreference", value);
    // console.log('value2', value);
    return state.message;
  },
  set_selectedBuilding: ({ commit, state }, value) => {
    commit("set_selectedBuilding", value);
    return state.message;
  },
  set_selectedEditingRoom: ({ commit, state }, value) => {
    commit("set_selectedEditingRoom", value);
    return state.message;
  },
  set_instituteAddress: ({ commit, state }, value) => {
    commit("set_instituteAddress", value);
    return state.message;
  },
  set_studentAttendance: ({ commit, state }, value) => {
    commit('set_studentAttendance', value)
    return state.message
  },
  set_tokenForQr: ({ commit, state }, value) => {
    commit('set_tokenForQr', value)
    return state.message
  },
  set_isSubAdmin: ({ commit, state }, value) => {
    commit('set_isSubAdmin', value)
    return state.message
  },
  set_accessibleDepartment: ({ commit, state }, value) => {
    commit('set_accessibleDepartment', value)
    return state.message
  },
  set_accessibleCourseYearForSubAdmin: ({ commit, state }, value) => {
    commit('set_accessibleCourseYearForSubAdmin', value)
    return state.message
  },
  set_proxyData: ({ commit, state }, value) => {
    commit('set_proxyData', value)
    return state.message
  },
  set_examId: ({ commit, state }, value) => {
    commit('set_examId', value)
    return state.message
  },
  set_examTabDependencies: ({ commit, state }, value) => {
    commit('set_examTabDependencies', value)
    return state.message
  },
  set_subjectTypes: ({ commit, state }, value) => {
    commit('set_subjectTypes', value)
    return state.message
  },
  set_presentStudentArr: ({ commit, state }, value) => {
    commit('set_presentStudentArr', value)
    return state.message
  },
};

export default {
  // exports the vuex object of 'institutesData' store
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

function initialState() {
  return {
    reminderDays: state.reminderDays,
    loading: false,
    selectedActivity: 1,
    percentage: null,
    statusMessage: "Success!",
    statusDuration: 2000,
    statusType: "success",
    snackbar: false,
    editingSemester: {},
    examDepartment: '',
    examCourseYear: '',
    selectedExam: {},
    selectedExamSubject: {},
    selectedExamTimetable: {},
    selectedAdmission: {},
    selectedTopic: {},
    admissionSubjects: [],
    relativeInstitutes: [],
    admissionSemester: "",
    admissionInstitute: state.admissionInstitute,
    admissionDepartment: "",
    admissionCourseYear: "",
    admissionDivision: "",
    brandingName: state.brandingName,
    brandingLogo: state.brandingLogo,
    showAdmissionModule: true,
    instituteStudentBrandingName: state.instituteStudentBrandingName,
    instituteStudentLogoLink: state.instituteStudentLogoLink,
    senderMail: state.senderMail,
    senderMailPassword: state.senderMailPassword,
    displayName: state.displayName,
    subjectDescription: state.subjectDescription,
    emailBody: state.emailBody,
    signatureLogo: state.signatureLogo,
    signatureLogoName: state.signatureLogoName,
    selectedSubject: state.selectedSubject,
    instituteType: state.instituteType,
    instituteCieSee: state.instituteCieSee,
    attainmentCalculationType: state.attainmentCalculationType,
    allowedModule: state.allowedModule,
    isInstituteForNaacOrNBA: state.isInstituteForNaacOrNBA,
    isCorporateInstitute: state.isCorporateInstitute,
    cieSeePreference: state.cieSeePreference,
    selectedBuilding: state.selectedBuilding,
    editingRoomInventory: state.editingRoomInventory,
    instituteAddress: state.instituteAddress,
    studentAttendance: [],
    tokenForQr: '',
    isSubAdmin: false,
    accessibleDepartment: [],
    accessibleCourseYearForSubAdmin: [],
    examId:'',
    examTabDependencies : {},
    subjectTypes : {},
    proxyData: [],
    presentStudentArr: [],
  };
}
