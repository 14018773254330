<template src="./activityManageExamsRevamped.html"></template>

<script>
import examsBasicDetails from "./examsBasicDetails/examsBasicDetails"
import examSubjectDetails from "./examSubjectDetails/examSubjectDetails";
import examForm from "./examForm/examForm";
import NewExamRepository from "../../Repository/NewExam";
import hallTicket from "./hallTicket/hallTicket.vue";
import examQuestionDetails from "./examQuestionDetails/examQuestionDetails";
import examMarks from "./examMarks/examMarks";
export default {
    name: "activityManageExamsRevamped",
    components: {
        examsBasicDetails,
        examSubjectDetails,
        examForm,
        hallTicket,
        examQuestionDetails,
        examMarks,
    },
    props: [
      'prop_tabNumber'
    ],
    data() {
        return {
            backgroundColor: "#E7E7FFCF",
            downArrow: "mdi-chevron-down",
            upArrow: "mdi-arrow-up",
            examTypes: ['Exam'], //'Theory', 'Online'
            examType: '',
            mainTabNames: ['Basic Details', 'Subject Details', 'Exam Form', 'Hall Ticket','Question & Answers', 'Student Marks'],
            components: ['examsBasicDetails', 'examSubjectDetails', 'examForm'],
            currentTab: 0,
            tabs:0,
            selectedInstitute: '',
            selectedSemester : '',
            selectedDepartment:'',
            selectedCourseYear:'',
            examName:null,
            examFormat:null,
            showExamForm:false,
            showExamHallTicket:false,
            examId: null,
            exam: {},
            disableSubjectComponent: false
            // showExamForm:false,
        }
    },
    async created(){
        this.selectedInstitute = this.$store.getters["instituteData/get_selectedInstitute"];
        this.selectedSemester = this.$store.getters["instituteData/get_selectedSemester"];
        this.selectedDepartment = this.$store.getters["liveData/get_examDepartment"];
        this.selectedCourseYear = this.$store.getters["liveData/get_examCourseYear"];
        this.examId = this.$store.getters["liveData/get_examId"];
        this.newExamRepositoryInstance = new NewExamRepository(this);
        // await this.getExam();
        // console.log("checked 1" + this.selectedSemester)
        // console.log("checked 2" + this.selectedCourseYear)
        // console.log("checked 3" + this.selectedDepartment)
        // console.log("checked 4" + this.prop_tabNumber)
        if (this.prop_tabNumber) this.currentTab = this.prop_tabNumber

        this.createMap();
    },
    computed: {
        currentTabComponent() {
            return this.components[this.currentTab];
        }
    },
    methods: {
    //   async getExam() {
    //   try {
    //     const objToPush = {
    //       instituteId: this.selectedInstitute,
    //       semId: this.selectedSemester.semId,
    //       department: this.selectedDepartment,
    //       courseYear: this.selectedCourseYear,
    //       examId: this.examId,
    //     };
    //     let res = await this.newExamRepositoryInstance.getExam(objToPush);
    //     this.exam = res.exam;
    //     // this.exam = res
    //   } catch (error) {
    //     console.error("error", error);
    //   }
    // },
    goToExams() {
      const objToPush = {
        prop_department: this.department,
        prop_course: this.course,
        prop_selectedSubject: this.selectedSubject,
        prop_typeDivGrpBatch: this.typeDivGrpBatch,
        prop_selectedDivision: this.selectedDivision,
        prop_selectedBatch: this.selectedBatch,
        prop_selectedGroup: this.selectedGroup,
        prop_selectedSemester: this.selectedSemester,
        prop_selectedFaculty: this.selectedFaculty
      }
    //   if (exam) {
    //     objToPush['prop_exam'] = exam
    //   }
    this.$store.commit("liveData/set_selectedExam", null);
      this.$router.push({
        name: 'activityExams1'
      })
    },
    setCurrentTab(tabIndex) {
      this.currentTab = tabIndex;
    },
    createMap(){
      let examTabDependencies = {
        basicDetailsTab : {
          subjectDetailsTab : false,
          questionAndAnswersTab : false, 
          studentMarksTab : false,
          examFormTab : false,
          hallTicketTab : false, 
        },
        subjectDetailsTab : {
          questionAndAnswersTab : false, 
          studentMarksTab : false,
          examFormTab : false,
          hallTicketTab : false,
          questionAndAnswersTab: false, 
          studentMarksTab:false,
        },
        invigilationTab : {
        },
        controlAccesTab : {
        },
        examFormTab:{
          hallTicketTab : false,
        },
        hallTicketTab: {
        },
        questionAndAnswersTab : {
        },
        studentMarksTab : {
        }
      }
      this.$store.commit("liveData/set_examTabDependencies",examTabDependencies);
    },
    handleNextButtonClick() {
      // Increment the currentTab value to move to the next tab
      this.currentTab += 1;
    },
    updateExamName(newExamName) {
      // console.log("newExamName", newExamName);
      this.examName = newExamName;
    },
    updateExamFormat(newExamFormat) {
      // console.log("newExamName", newExamFormat);
      this.examFormat = newExamFormat;
    },
    updateExamFormCheckbox(showExamForm) {
      // console.log("new exam Form", showExamForm);
      this.showExamForm = showExamForm;
    },
    updateHallTicketCheckbox(showExamHallTicket) {
      // console.log("new exam Hall Ticket", showExamHallTicket);
      this.showExamHallTicket = showExamHallTicket;
    },
  },
}
</script>

<style src="./activityManageExamsRevamped.css"></style>
